import axios from 'axios'
import { getToken } from "@/utils/auth";
import { Message } from 'element-ui'

export default function(option) {
  return new Promise((resolve, reject) => {
    const headers = option.headers || {}
    delete option.headers
    
    axios(Object.assign({
      baseURL: process.env.VUE_APP_BASE_API,
      timeout: 60 * 1000,
      headers: {
        Authorization: 'Bearer ' + getToken(),
        ...headers
      }
    }, option))
      .then(response => {
        if (response.status === 200) { // 处理原生http返回的数据
          if (option.handlerFun) {
            return resolve(option.handlerFun(response))
          } else {
            const { data, code, msg } = response.data
            if (Number(code) === 200 || Number(code) === 100) { // 处理内部定义的接口状态
              return resolve(data)
            } else {
              Message({ message: msg || '服务器开小差拉', type: 'error', duration: 5 * 1000 })
              return reject(response)
            }
          }
        } else {
          Message({ message: response.message || '服务器开小差拉', type: 'error', duration: 5 * 1000 })
          return reject(response)
        }
      })
      .catch(error => {
        if (error && error.response) {
          switch (error.response.status) {
            case 400: error.message = '请求错误'; break
            case 401: error.message = '登录过期，请重新登录'; break
            case 403: error.message = '您没有执行此操作的权限!'; break
            case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
            case 408: error.message = '请求超时'; break
            case 500: error.message = '服务器内部错误'; break
            case 501: error.message = '服务未实现'; break
            case 502: error.message = '网关错误'; break
            case 503: error.message = '服务不可用'; break
            case 504: error.message = '网关超时'; break
            case 505: error.message = 'HTTP版本不受支持'; break
            default:
              break
          }
        } else {
          error.message = '网络请求错误'
        }
        Message({ message: error.message, type: 'error', duration: 5 * 1000 })
        reject(error)
      })
  })
}
