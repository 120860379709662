export function getEarlyWarning(params) {
  return {
    url: `/vehicle/safe/getEarlyWarning`,
    method: 'get',
    params
  }
}

export function getRepairInfo(params) {
  return {
    url: `/vehicle/safe/getRepairInfo`,
    method: 'get',
    params
  }
}

export function getVehicleActualInfo(params) {
  return {
    url: `/vehicle/safe/getVehicleActualInfo`,
    method: 'get',
    params
  }
}

export function getVehicleInfo(params) {
  return {
    url: `/vehicle/safe/getVehicleInfo`,
    method: 'get',
    params
  }
}

export function listCo2NumData(data, params) {
  return {
    url: `/vehicle/safe/listCo2NumData`,
    method: 'post',
    data,
    params
  }
}

export function listHydrogenConsume(data, params) {
  return {
    url: `/vehicle/safe/listHydrogenConsume`,
    method: 'post',
    data,
    params
  }
}

export function listSuperviseData(data, params) {
  return {
    url: `/vehicle/safe/listSuperviseData`,
    method: 'post',
    data,
    params
  }
}

export function listVehicleLocation(data, params) {
  return {
    url: `/vehicle/safe/listVehicleLocation`,
    method: 'post',
    data,
    params
  }
}

export function listPlateNumber(params) {
  return {
    url: `/vehicle/safe/listPlateNumber`,
    method: 'get',
    params
  }
}
