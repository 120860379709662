<template>
  <select
    @change="valChange"
    ref="selectRef"
    class="select_box"
  >
<!--    <option value="3">-->
<!--      按日查询-->
<!--    </option>-->
    <option value="2">
      按月查询
    </option>
    <option value="1">
      按年查询
    </option>
  </select>
</template>

<script>
import isBlank from "@/utils/isBlank";

export default {
  name: 'SelectDateType',
  props: {
    value: {
      type: String,
      default: () => ''
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    value(val) {
      this.$refs.selectRef.value = val
    }
  },
  mounted() {
    if (!isBlank(this.value)) {
      this.$refs.selectRef.value = this.value
    }
  },
  methods: {
    valChange() {
      this.$emit('change', this.$refs.selectRef.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.select_box {
  background-color: #282c34;
  color: white;
  font-size: 8px;
  border-radius: 4px;
  border: 1px solid #69E5B7;
  cursor: pointer;
  &:hover {
    border-color: #99bb50;
  }
}
</style>
