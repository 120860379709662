<template>
  <div style="width: 100%;position: relative;pointer-events: visible">
    <el-cascader
        class="search_class"
        :options="regionTree"
        v-bind="$attrs"
        v-on="$listeners"
        :props="{
          label: 'fullname',
          value: 'id',
          checkStrictly: true
        }"
        clearable/>
<!--    <i class="el-icon-search search_icon"/>-->
  </div>
</template>

<script>
import regionTree from './regionTree'

export default {
  name: "SelectRegion",
  data() {
    return {
      regionTree
    }
  },
  // mounted() {
  //   this.regionTree.forEach(row => {
  //     row.level = 1
  //     row.code = row.id
  //     row.children.forEach(item => {
  //       item.level = 2;
  //       item.code = item.id;
  //       (item.children || []).forEach(item2 => {
  //         item2.level = 3
  //         item2.code = item2.id
  //       });
  //     })
  //   })
  //   console.log(JSON.stringify(regionTree))
  // }
}
</script>

<style lang="stylus" scoped>
.search_class {
  width: 100%;
}
::v-deep .search_class .el-input {
  height: 30px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #69E5B7;
  background-color: transparent !important;
}
::v-deep .search_class .el-input__inner {
  background-color: transparent !important;
  border: none;
}
.search_icon {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}
//::v-deep .search_class .el-input__suffix {
//  display: none;
//}
</style>
