<template>
  <div style="font-size: 10px;color: #EEEEEE;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
    <template v-if="carType === 'target'">
      <span >目标里程</span>
      <span>{{ mileage }}km</span>
      <img :src="require('./img/汽车.png')" style="width: 30px;height: auto;" />
    </template>
    <template v-else>
      <span>已达标里程</span>
      <span>{{ mileage }}km</span>
      <img :src="require('./img/汽车 (1).png')" style="width: 30px;height: auto;" />
    </template>
  </div>
</template>

<script>
export default {
  name: "Car",
  props: {
    carType: {
      type: String,
      default: 'target', // default
    },
    mileage: { // 里程
      type: Number,
      default: () => 0
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
