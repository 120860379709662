export function getBottleInfo(params) {
  return {
    url: `/emergency/getBottleInfo`,
    method: 'get',
    params
  }
}

export function getElectricityInfo(params) {
  return {
    url: `/emergency/getElectricityInfo/${params.vehicleFileId}`,
    method: 'get',
    params
  }
}

export function getHydrogenInfo(params) {
  return {
    url: `/emergency/getHydrogenInfo/${params.vehicleFileId}`,
    method: 'get',
    params
  }
}

export function getOtherInfo(params) {
  return {
    url: `/emergency/getOtherInfo/${params.vehicleFileId}`,
    method: 'get',
    params
  }
}

export function getRepairPercent(params) {
  return {
    url: `/emergency/getRepairPercent/${params.vehicleFileId}`,
    method: 'get',
    params
  }
}

export function listBottleDetailInfo(params) {
  return {
    url: `/emergency/listBottleDetailInfo/${params.bottleId}`,
    method: 'get',
    params
  }
}

export function listElectricity(params) {
  return {
    url: `/emergency/listElectricity/${params.vehicleFileId}`,
    method: 'get',
    params
  }
}

export function listRepairHandleType(params) {
  return {
    url: `/emergency/listRepairHandleType/${params.vehicleFileId}`,
    method: 'get',
    params
  }
}

export function listRepairPart(params) {
  return {
    url: `/emergency/listRepairPart/${params.vehicleFileId}`,
    method: 'get',
    params
  }
}
