export function getVehicleTypeTotal(params) {
  return {
    url: `/vehicle/getVehicleTypeTotal`,
    method: 'get',
    params
  }
}

export function countCompany(params) {
  return {
    url: `/vehicle/countCompany`,
    method: 'get',
    params
  }
}

export function getVehicleTotalData(params) {
  return {
    url: `/vehicle/getVehicleTotalData`,
    method: 'get',
    params
  }
}

export function listVehicle(params) {
  return {
    url: `/vehicle/listVehicle`,
    method: 'get',
    params
  }
}
export function getVehicle(params) {
  return {
    url: `/vehicle/getVehicle`,
    method: 'get',
    params
  }
}

export function listVehicleAddData(data, params) {
  return {
    url: `/vehicle/listVehicleAddData`,
    method: 'post',
    data,
    params
  }
}
