<template>
  <div style="overflow-y: hidden;width: 100%;height: 100%;">
    <div v-if="show" class="padding_box">
      <top-header @click.native="fullScan" style="color: #70EFBD" title="应急服务数据" />
      <div style="position: fixed;left: 400px;top: 60px;color: #6DE9B9;">当前车辆：{{ etBottleInfoData.plateNumber }}</div>
      <div style="position: fixed;right: 380px;top: 60px;color: #6DE9B9;">{{ nowDate }}</div>
      <div class="content_box">
        <!-- 左侧 -->
        <div style="height: 100%;width: calc(25% - 20px);margin-right: 20px;box-sizing: border-box;">
<!--          <div style="height: 30px;position: relative;box-sizing: border-box;width: calc(100% - 20px);margin-left: 20px;border-radius: 20px;border: 1px solid #69E5B7">-->
<!--            <input @keyup.enter="etBottleInfo(true)" placeholder="请输入气瓶编号" class="input_search" v-model="searchVal" />-->
<!--            <i @click="etBottleInfo(true)" class="el-icon-search search_icon"/>-->
<!--          </div>-->
          <qc-select
              popper-append-to-body
              filterable
              style="width: calc(100% - 30px);margin-left: 20px;margin-bottom: 10px;"
              @change="(val) => {
                 if (val) {
                   Object.assign(etBottleInfoData, bottleInfoList.find(row => (row.plateNumber + ' ' + row.bottleNumber) === val));
                   init();
                 }
              }"
              remote
              :remote-method="etBottleInfo"
              :options="bottleInfoList"
              placeholder="请输入车牌号"
              v-model="searchVal"/>

          <div style="height: 132px;margin-top: 10px;background-color: #1B3231;display: flex;align-items: center;padding: 15px;box-sizing: border-box;width: calc(100% - 20px);margin-left: 20px;border-radius: 5px;border: 1px solid #5ECEA9">
            <img :src="require('./img/储氢.svg')" style="width: 100px;height: auto;" />
            <div>
              <div style="font-size: 10px;color: #15D3E9">气瓶温度</div>
              <div style="font-size: 22px;color: #F8AE10">{{ etBottleInfoData.temperature }}°C</div>
              <div style="font-size: 10px;color: #15D3E9">气瓶压力</div>
              <div style="font-size: 22px;color: #F8AE10">{{ etBottleInfoData.pressure }}mpa</div>
            </div>
          </div>
          <div style="height: 26px;margin-top: 10px;display: flex;flex-flow: row wrap;box-sizing: border-box;width: calc(100% - 20px);margin-left: 20px;">
            <div style="margin-bottom: 4px;display: flex;align-items: center;margin-right: 3px;">
              <div style="position: relative;">
                <div style="z-index: 0;width: 15px;height: 2px;background-color: #D14D3F;"/>
                <div style="box-sizing: border-box;background-color: white;z-index: 2;position: absolute;left: 4px;top: -3px;border-radius: 50%;width: 8px;height: 8px;border: 2px solid #D14D3F;"/>
              </div>
              <span style="color: white;font-size: 8px;margin-left: 1px;">入堆风温</span>
            </div>
            <div style="display: flex;align-items: center;margin-right: 3px;">
              <div style="position: relative;">
                <div style="z-index: 0;width: 15px;height: 2px;background-color: #F3A84C;"/>
                <div style="box-sizing: border-box;background-color: white;z-index: 2;position: absolute;left: 4px;top: -3px;border-radius: 50%;width: 8px;height: 8px;border: 2px solid #F3A84C;"/>
              </div>
              <span style="color: white;font-size: 8px;margin-left: 1px;">入堆风压</span>
            </div>
            <div style="display: flex;align-items: center;margin-right: 3px;">
              <div style="position: relative;">
                <div style="z-index: 0;width: 15px;height: 2px;background-color: #EADA5C;"/>
                <div style="box-sizing: border-box;background-color: white;z-index: 2;position: absolute;left: 4px;top: -3px;border-radius: 50%;width: 8px;height: 8px;border: 2px solid #EADA5C;"/>
              </div>
              <span style="color: white;font-size: 8px;margin-left: 1px;">入堆水压</span>
            </div>
            <div style="display: flex;align-items: center;margin-right: 3px;">
              <div style="position: relative;">
                <div style="z-index: 0;width: 15px;height: 2px;background-color: #61A258;"/>
                <div style="box-sizing: border-box;background-color: white;z-index: 2;position: absolute;left: 4px;top: -3px;border-radius: 50%;width: 8px;height: 8px;border: 2px solid #61A258;"/>
              </div>
              <span style="color: white;font-size: 8px;margin-left: 1px;">入堆水温</span>
            </div>
            <div style="display: flex;align-items: center;margin-right: 3px;">
              <div style="position: relative;">
                <div style="z-index: 0;width: 15px;height: 2px;background-color: #4BABC0;"/>
                <div style="box-sizing: border-box;background-color: white;z-index: 2;position: absolute;left: 4px;top: -3px;border-radius: 50%;width: 8px;height: 8px;border: 2px solid #4BABC0;"/>
              </div>
              <span style="color: white;font-size: 8px;margin-left: 1px;">入堆氧压</span>
            </div>
            <div style="display: flex;align-items: center;margin-right: 3px;">
              <div style="position: relative;">
                <div style="z-index: 0;width: 15px;height: 2px;background-color: #4CACC0;"/>
                <div style="box-sizing: border-box;background-color: white;z-index: 2;position: absolute;left: 4px;top: -3px;border-radius: 50%;width: 8px;height: 8px;border: 2px solid #4CACC0;"/>
              </div>
              <span style="color: white;font-size: 8px;margin-left: 1px;">出堆风温</span>
            </div>
            <div style="display: flex;align-items: center;margin-right: 3px;">
              <div style="position: relative;">
                <div style="z-index: 0;width: 15px;height: 2px;background-color: #4292E8;"/>
                <div style="box-sizing: border-box;background-color: white;z-index: 2;position: absolute;left: 4px;top: -3px;border-radius: 50%;width: 8px;height: 8px;border: 2px solid #4292E8;"/>
              </div>
              <span style="color: white;font-size: 8px;margin-left: 1px;">出堆风压</span>
            </div>
            <div style="display: flex;align-items: center;margin-right: 3px;">
              <div style="position: relative;">
                <div style="z-index: 0;width: 15px;height: 2px;background-color: #54B5E2;"/>
                <div style="box-sizing: border-box;background-color: white;z-index: 2;position: absolute;left: 4px;top: -3px;border-radius: 50%;width: 8px;height: 8px;border: 2px solid #54B5E2;"/>
              </div>
              <span style="color: white;font-size: 8px;margin-left: 1px;">出堆水压</span>
            </div>
            <div style="display: flex;align-items: center;margin-right: 3px;">
              <div style="position: relative;">
                <div style="z-index: 0;width: 15px;height: 2px;background-color: #3A4D9C;"/>
                <div style="box-sizing: border-box;background-color: white;z-index: 2;position: absolute;left: 4px;top: -3px;border-radius: 50%;width: 8px;height: 8px;border: 2px solid #3A4D9C;"/>
              </div>
              <span style="color: white;font-size: 8px;margin-left: 1px;">出堆水温</span>
            </div>
            <div style="display: flex;align-items: center;margin-right: 3px;">
              <div style="position: relative;">
                <div style="z-index: 0;width: 15px;height: 2px;background-color: #593FA4;"/>
                <div style="box-sizing: border-box;background-color: white;z-index: 2;position: absolute;left: 4px;top: -3px;border-radius: 50%;width: 8px;height: 8px;border: 2px solid #593FA4;"/>
              </div>
              <span style="color: white;font-size: 8px;margin-left: 1px;">出堆氧压</span>
            </div>
          </div>
          <div style="width: 100%;height: calc(100% - 132px - 26px - 30px - 20px);">
            <!-- 图表 -->
            <div style="position: relative;height: calc(33.333% - 10px);margin-top: 10px;overflow-y: hidden;box-sizing: border-box;padding-left: 20px;">
              <div :style="{
                     opacity: leftDateList.length === 0 ? 0 : 1
                   }"
                   style="width: 100%;height: calc(100%);" ref="pressureEcharts"/>
              <div v-if="leftDateList.length === 0"
                   style="width: 100%;height: 100%;color: #808695;position: absolute;left: 0;top:0;z-index: 10;display: flex;align-items: center;justify-content: center">
                暂无数据
              </div>
            </div>
            <!-- 柱状图 -->
            <div style="height: calc(33% - 20px);margin-top: 10px;overflow-y: hidden;width: 100%;box-sizing: border-box;padding-left: 20px;">
              <div style="font-size: 16px;color: white">入堆</div>
              <div style="width: 100%;height: calc(100% - 26px);margin-top: 10px;" ref="enteringThePileEcharts"/>
            </div>
            <div style="height: calc(33% - 20px);margin-top: 10px;overflow-y: hidden;width: 100%;box-sizing: border-box;padding-left: 20px;">
              <div style="font-size: 16px;color: white">出堆</div>
              <div style="width: 100%;height: calc(100% - 26px);margin-top: 10px;" ref="outThePileEcharts"/>
            </div>
          </div>
        </div>
        <!-- 中间 -->
        <div style="width: 50%;min-height: 40vh;box-sizing: border-box;position: relative;">
          <div style="z-index: 100;width: 100%;display: flex;align-items: flex-start;position: absolute;left: 0;top: 0;">
            <div style="width: 100px">
              <div style="color: white;font-size: 12px;font-weight: bold;">故障率</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getRepairPercentData.repairPercent }}%</div>
              <div style="margin-top: 30px;color: white;font-size: 12px;font-weight: bold;">理赔率</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getRepairPercentData.payOfClaimPercent }}%</div>
              <div style="margin-top: 30px;color: white;font-size: 12px;font-weight: bold;">售后响应及时率</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getRepairPercentData.timelyPercent }}%</div>
              <div style="margin-top: 30px;color: white;font-size: 12px;font-weight: bold;">故障处理及时率</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getRepairPercentData.repairHandlePercent }}%</div>
              <div style="margin-top: 30px;color: white;font-size: 12px;font-weight: bold;">事故处理跟踪率</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getRepairPercentData.accidentHandlePercent }}%</div>
              <div style="margin-top: 30px;color: white;font-size: 12px;font-weight: bold;">维修完成率</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getRepairPercentData.maintenancePercent }}%</div>
              <div style="margin-top: 30px;color: white;font-size: 12px;font-weight: bold;">售后服务总次数</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getRepairPercentData.serviceNum }}</div>
            </div>
            <div style="width: calc(100% - 200px);display: flex;align-items: center;justify-content: center;">
              <div style="padding: 15px;box-sizing: border-box;width: 45%;border-radius: 5px;border: 1px solid #69E5B7">
                <div style="width: 100%;display: flex;align-items: center;margin-bottom: 10px;">
                  <div style="width: 3px;height: 18px;background-color: #5ECF90;margin-right: 4px;"/>
                  故障部件分布
                </div>
                <div style="width: 100%;height: 195px;" ref="faultEcharts"/>
              </div>
              <div style="margin-left: 20px;padding: 15px;box-sizing: border-box;width: 45%;border-radius: 5px;border: 1px solid #69E5B7">
                <div style="width: 100%;display: flex;align-items: center;margin-bottom: 10px;">
                  <div style="width: 3px;height: 18px;background-color: #5ECF90;margin-right: 4px;"/>
                  处理方式分布
                </div>
                <div style="width: 100%;height: 195px;" ref="processingMethod"/>
              </div>
            </div>
            <div style="width: 100px">
              <div style="color: white;font-size: 12px;font-weight: bold;">电导率</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getOtherInfoData.conductivity }}s/m</div>
              <div style="margin-top: 30px;color: white;font-size: 12px;font-weight: bold;">水泵输入电压</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getOtherInfoData.waterPumpInVoltage }}V</div>
              <div style="margin-top: 30px;color: white;font-size: 12px;font-weight: bold;">水泵转速</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getOtherInfoData.waterPumpSpeed }}KM</div>
              <div style="margin-top: 30px;color: white;font-size: 12px;font-weight: bold;">空压机输入电压</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getOtherInfoData.pressInVoltage }}V</div>
              <div style="margin-top: 30px;color: white;font-size: 12px;font-weight: bold;">空压机转速</div>
              <div style="font-weight: bolder;font-size: 25px;color: #66D886">{{ getOtherInfoData.pressSpeed }}KM</div>
            </div>
          </div>
          <div style="z-index: 0;width: 80%;position: absolute;top: 300px;left: 100px;">
            <img :src="require('./img/车辆-透明.png')" style="width: 100%;height: auto;" />
            <img :src="require('./img/风扇叶-透明.png')" class="fen-che" />
          </div>
        </div>
        <!-- 右侧 -->
        <div style="height: 100%;width: calc(25% - 20px);margin-right: 20px;">
          <div style="height: 94px;flex-flow: column nowrap;background-color: #1B3231;display: flex;align-items: center;padding: 10px;box-sizing: border-box;width: calc(100% - 20px);margin-left: 20px;border-radius: 5px;border: 1px solid #5ECEA9">
            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;background-color: #132522;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">入堆氢气压力</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getHydrogenInfoData.inPressure }}kpa</span>
            </div>
            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">出堆氢气压力</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getHydrogenInfoData.outPressure }}kpa</span>
            </div>
            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;background-color: #132522;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">出堆尾拍压力</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getHydrogenInfoData.tailOutPressure }}kpa</span>
            </div>
            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">氢气浓度</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getHydrogenInfoData.concentration }}%</span>
            </div>
          </div>
          <div style="margin-top: 10px;height: 148px;flex-flow: column nowrap;background-color: #1B3231;display: flex;align-items: center;padding: 10px;box-sizing: border-box;width: calc(100% - 20px);margin-left: 20px;border-radius: 5px;border: 1px solid #5ECEA9">
            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;background-color: #132522;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">输出电压</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getElectricityInfoData.outVoltage }}V</span>
            </div>
            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">输出电流</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getElectricityInfoData.outCurrent }}A</span>
            </div>
            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;background-color: #132522;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">输出功率</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getElectricityInfoData.outPower }}KW</span>
            </div>

            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">电堆输出电流</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getElectricityInfoData.stackOutCurrent }}A</span>
            </div>
            <div style="background-color: #132522;padding-left: 10px;padding-right: 10px;box-sizing: border-box;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">电堆输出电压</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getElectricityInfoData.stackOutVoltage }}V</span>
            </div>
            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">接触器状态</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getElectricityInfoData.statusName }}</span>
            </div>
            <div style="background-color: #132522;padding-left: 10px;padding-right: 10px;box-sizing: border-box;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">系统请求功率</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getElectricityInfoData.sysPower }}KW</span>
            </div>
          </div>
          <!-- 图表 -->
          <div style="height: calc(100% - 94px - 148px - 66px - 20px - 20%);overflow-y: hidden;box-sizing: border-box;padding-left: 20px;">
            <div style="width: 100%;height: 100%;" ref="electricEcharts"/>
          </div>
          <div style="height: 66px;flex-flow: column nowrap;background-color: #1B3231;display: flex;align-items: center;padding: 10px;box-sizing: border-box;width: calc(100% - 20px);margin-left: 20px;border-radius: 5px;border: 1px solid #5ECEA9">
            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;background-color: #132522;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">1.最低单片电压</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getElectricityInfoData.maxVoltage }}V</span>
            </div>
            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">2.最高单片电压</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getElectricityInfoData.maxVoltage }}V</span>
            </div>
            <div style="padding-left: 10px;padding-right: 10px;box-sizing: border-box;background-color: #132522;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <span style="font-size: 12px;line-height: 18px;">3.平均单片电压</span>
              <span style="font-size: 12px;line-height: 18px;">{{ getElectricityInfoData.avgVoltage }}V</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import {
  getBottleInfo, listBottleDetailInfo,
  getRepairPercent, getOtherInfo,
  getElectricityInfo, getHydrogenInfo,
  listElectricity, listRepairPart, listRepairHandleType
} from '@/api/emergency'
import dayjs from 'dayjs'
import isBlank from '@/utils/isBlank'

let intervalRes
let intervalDate
export default {
  name: "emergency",
  data() {
    return {
      show: true,
      pressureEcharts: null,
      electricEcharts: null,
      enteringThePileEcharts: null,
      outThePileEcharts: null,
      faultEcharts: null,
      processingMethod: null,

      batchInfo: {},
      searchVal: null,
      etBottleInfoData: {
        bottleNumber: null,
        id: null,
        plateNumber: null,
        pressure: null,
        temperature: null,
        vehicleFileId: null
      },
      getRepairPercentData: {
        accidentHandlePercent: 0,
        maintenancePercent: 0,
        payOfClaimPercent: 0,
        repairHandlePercent: 0,
        repairPercent: 0,
        serviceNum: 0,
        timelyPercent: 0
      },
      getOtherInfoData: {
        conductivity: 0,
        pressInVoltage: 0,
        pressSpeed: 0,
        waterPumpInVoltage: 0,
        waterPumpSpeed: 0
      },
      getElectricityInfoData: {
        avgVoltage: 0,
        maxVoltage: 0,
        minVoltage: 0,
        outCurrent: 0,
        outPower: 0,
        outVoltage: 0,
        stackOutCurrent: 0,
        stackOutVoltage: 0,
        statusName: '',
        sysPower: 0
      },
      getHydrogenInfoData: {
        concentration: 0,
        inPressure: 0,
        outPressure: 0,
        tailOutPressure: 0
      },
      dataIndex: 0,
      leftDateList: [],
      nowDate: dayjs().format('YYYY年MM月DD日 HH:mm:ss'),
      bottleInfoList: []
    }
  },
  created() {
    document.title = '应急服务数据'
    setTimeout(() => {
      this.etBottleInfo(this.$route.query.plateNumber || null)
      intervalRes = setInterval(this.init, 60_000)
    }, 500)
    intervalDate = setInterval(() => {
      this.nowDate = dayjs().format('YYYY年MM月DD日 HH:mm:ss')
    }, 1000)
    window.addEventListener('resize', this.reLoad)
  },
  beforeDestroy() {
    this.clearEcharts()
    if (intervalRes) {
      clearInterval(intervalRes)
    }
    if (intervalDate) {
      clearInterval(intervalDate)
    }
    window.removeEventListener('resize', this.reLoad)
  },
  methods: {
    fullScan() {
      if (screenfull.isFullscreen) {
        screenfull.exit()
      } else {
        screenfull.request()
      }
    },
    clearEcharts() {
      if (this.pressureEcharts) {
        this.pressureEcharts.dispose()
      }
      if (this.electricEcharts) {
        this.electricEcharts.dispose()
      }
      if (this.enteringThePileEcharts) {
        this.enteringThePileEcharts.dispose()
      }
      if (this.outThePileEcharts) {
        this.outThePileEcharts.dispose()
      }
      if (this.faultEcharts) {
        this.faultEcharts.dispose()
      }
      if (this.processingMethod) {
        this.processingMethod.dispose()
      }
      this.pressureEcharts = null
      this.electricEcharts = null
      this.enteringThePileEcharts = null
      this.outThePileEcharts = null
      this.faultEcharts = null
      this.processingMethod = null
    },
    reLoad() {
      this.clearEcharts()
      this.show = false
      this.$nextTick(() => {
        this.show = true
        setTimeout(this.init, 100)
      })
    },

    /**
     * 故障部件分布
     * @return {Promise<void>}
     */
    async initFaultEcharts() {
      if (!this.faultEcharts) {
        this.faultEcharts = this.$echarts.init(this.$refs.faultEcharts);
      }
      const res = await this.$request(listRepairPart({
        vehicleFileId: this.etBottleInfoData.vehicleFileId
      }))
      this.faultEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        title: {
          show: false
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '0%', //默认60
          top: '0%', //默认60
          containLabel: true,
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: '40%',
            data: res.length == 0 ? [{ name: '无数据', value: 0 }] : res.map(row => {
              return {
                value: row.num,
                name: row.name
              }
            }),
            label: {
              show: true,
              position: 'outside',
              formatter: '{b} {d}%',
              fontSize: 8
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    },
    /**
     * 处理方式分布
     */
    async initProcessingMethod() {
      if (!this.processingMethod) {
        this.processingMethod = this.$echarts.init(this.$refs.processingMethod);
      }
      const res = await this.$request(listRepairHandleType({
        vehicleFileId: this.etBottleInfoData.vehicleFileId
      }))
      this.processingMethod.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        title: {
          show: false
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '0%', //默认60
          top: '0%', //默认60
          containLabel: true,
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: '40%',
            data: res.length === 0 ? [{ name: '无数据', value: 0 }] : res.map(row => {
              return {
                value: row.num,
                name: row.name
              }
            }),
            label: {
              show: true,
              position: 'outside',
              formatter: '{b} {d}%',
              fontSize: 8
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    },
    /**
     * 左侧各种 出入压
     * @return {Promise<void>}
     */
    async initPressureEcharts() {
      if (!this.pressureEcharts) {
        this.pressureEcharts = this.$echarts.init(this.$refs.pressureEcharts);
        this.pressureEcharts.on('click', (event) => {
          this.dataIndex = event.dataIndex
          this.initEnteringThePileEcharts()
          this.initOutThePileEcharts()
        });
      }
      let res = []
      if (!isBlank(this.etBottleInfoData.id)) {
        res = await this.$request(listBottleDetailInfo({
          bottleId: this.etBottleInfoData.id
        }))
      }

      this.leftDateList = res
      this.pressureEcharts.setOption({
        darkMode: true,
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.map(row => dayjs(row.time).format('HH:mm:ss'))
        },
        tooltip: {
          show: false,
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '0%', //默认60
          top: '5%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: res.map(row => row.inOxygenPressure),
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#4BABC0'
            },
            itemStyle: {
              color: '#4BABC0'
            }
          },
          {
            data: res.map(row => row.inWaterPressure),
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#EADA5C'
            },
            itemStyle: {
              color: '#EADA5C'
            }
          },
          {
            data: res.map(row => row.inWaterTemperature),
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#61A258'
            },
            itemStyle: {
              color: '#61A258'
            }
          },
          {
            data: res.map(row => row.inWindPressure),
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#F3A84C'
            },
            itemStyle: {
              color: '#F3A84C'
            }
          },
          {
            data: res.map(row => row.inWindSpeed),
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#F3A84C'
            },
            itemStyle: {
              color: '#F3A84C'
            }
          },
          {
            data: res.map(row => row.outOxygenPressure),
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#593FA4'
            },
            itemStyle: {
              color: '#593FA4'
            }
          },
          {
            data: res.map(row => row.outWaterPressure),
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#54B5E2'
            },
            itemStyle: {
              color: '#54B5E2'
            }
          },
          {
            data: res.map(row => row.outWaterTemperature),
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#3A4D9C'
            },
            itemStyle: {
              color: '#3A4D9C'
            }
          },
          {
            data: res.map(row => row.outWindPressure),
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#4292E8'
            },
            itemStyle: {
              color: '#4292E8'
            }
          },
          {
            data: res.map(row => row.outWindSpeed),
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#4CACC0'
            },
            itemStyle: {
              color: '#4CACC0'
            }
          },
        ]
      })

      this.initEnteringThePileEcharts()
      this.initOutThePileEcharts()
    },
    /**
     * 获取电压折线图
     */
    async initElectricEcharts() {
      if (!this.electricEcharts) {
        this.electricEcharts = this.$echarts.init(this.$refs.electricEcharts);
      }
      const res = await this.$request(listElectricity({
        vehicleFileId: this.etBottleInfoData.vehicleFileId
      }))
      this.electricEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.map(row => row.name),
          animationDuration: 300,
          animationDurationUpdate: 300,
        },
        yAxis: {
          type: 'value',
          animationDuration: 300,
          animationDurationUpdate: 300,
        },
        grid: {
          left: '3%', //默认10%
          right: '10%', //默认10%
          bottom: '5%', //默认60
          top: '5%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'line',
            smooth: true,
            areaStyle: {
            }
          }
        ]
      })
    },
    /**
     * 入堆
     * @return {Promise<void>}
     */
    async initEnteringThePileEcharts() {
      if (!this.enteringThePileEcharts) {
        this.enteringThePileEcharts = this.$echarts.init(this.$refs.enteringThePileEcharts);
      }

      let data = {
        inWaterTemperature: 0,
        inWaterPressure: 0,
        inWindSpeed: 0,
        inWindPressure: 0,
        inOxygenPressure: 0
      }
      if (this.leftDateList.length !== 0) {
        data = this.leftDateList[this.dataIndex]
      }
      this.enteringThePileEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          max: 'dataMax'
        },
        yAxis: {
          type: 'category',
          data: ['水温', '水压', '风速', '风压', '氧压'],
          inverse: true,
          animationDuration: 100,
          animationDurationUpdate: 100,
          max: 5
        },
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            data: [data.inWaterTemperature, data.inWaterPressure, data.inWindSpeed, data.inWindPressure, data.inOxygenPressure],
            label: {
              show: true,
              position: 'right',
              valueAnimation: true
            }
          }
        ],
        legend: {
          show: true
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '4%', //默认60
          top: '0%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        animationDuration: 0,
        animationDurationUpdate: 300,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      })
    },
    /**
     * 出堆
     * @return {Promise<void>}
     */
    async initOutThePileEcharts() {
      if (!this.outThePileEcharts) {
        this.outThePileEcharts = this.$echarts.init(this.$refs.outThePileEcharts);
      }
      let data = {
        inWaterTemperature: 0,
        inWaterPressure: 0,
        inWindSpeed: 0,
        inWindPressure: 0,
        inOxygenPressure: 0
      }
      if (this.leftDateList.length !== 0) {
        data = this.leftDateList[this.dataIndex]
      }
      this.outThePileEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          max: 'dataMax'
        },
        yAxis: {
          type: 'category',
          data: ['水温', '水压', '风速', '风压', '氧压'],
          inverse: true,
          animationDuration: 100,
          animationDurationUpdate: 100,
          max: 5
        },
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            data: [data.inWaterTemperature, data.inWaterPressure, data.inWindSpeed, data.inWindPressure, data.inOxygenPressure],
            label: {
              show: true,
              position: 'right',
              valueAnimation: true
            }
          }
        ],
        legend: {
          show: true
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '4%', //默认60
          top: '0%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        animationDuration: 0,
        animationDurationUpdate: 300,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      })
    },
    /**
     * 获取气瓶信息
     * @return {Promise<void>}
     */
    async etBottleInfo(keyword) {
      const res = await this.$request(getBottleInfo({
        code: keyword,
        limit: 20
      }))
      this.bottleInfoList = res.map(row => {
        return Object.assign({}, row, {
          label: row.plateNumber + ' ' + (isBlank(row.bottleNumber) ? '' : row.bottleNumber),
          value: row.plateNumber + ' ' + (isBlank(row.bottleNumber) ? '' : row.bottleNumber)
        })
      })

      if (!isBlank(this.$route.query.plateNumber)) {
        this.bottleInfoList = this.bottleInfoList.filter(row => row.plateNumber === this.$route.query.plateNumber)
      }

      if (this.bottleInfoList.length !== 0) {
        Object.assign(this.etBottleInfoData, this.bottleInfoList[0])
      } else {
        this.$message.warning('未查询到数据')
        return
      }
      this.searchVal = null
      this.init()
    },
    /**
     * 中间左侧数据
     * @return {Promise<void>}
     */
    async getRepairPercent() {
      const res = await this.$request(getRepairPercent({
        vehicleFileId: this.etBottleInfoData.vehicleFileId
      }))
      Object.assign(this.getRepairPercentData, res)
    },
    /**
     * 中间右侧数据
     * @return {Promise<void>}
     */
    async getOtherInfo() {
      const res = await this.$request(getOtherInfo({
        vehicleFileId: this.etBottleInfoData.vehicleFileId
      }))
      Object.assign(this.getOtherInfoData, res)
    },
    /**
     * 右侧的表格行数据
     * @return {Promise<void>}
     */
    async getElectricityInfo() {
      const res = await this.$request(getElectricityInfo({
        vehicleFileId: this.etBottleInfoData.vehicleFileId
      }))
      Object.assign(this.getElectricityInfoData, res)
    },
    /**
     * 右上角
     * @return {Promise<void>}
     */
    async getHydrogenInfo() {
      const res = await this.$request(getHydrogenInfo({
        vehicleFileId: this.etBottleInfoData.vehicleFileId
      }))
      Object.assign(this.getHydrogenInfoData, res)
    },
    async init() {
      if (isBlank(this.etBottleInfoData.vehicleFileId)) {
        return
      }
      this.getRepairPercent()
      this.getOtherInfo()
      this.getElectricityInfo()
      this.getHydrogenInfo()
      this.initPressureEcharts()
      this.initElectricEcharts()
      this.initFaultEcharts()
      this.initProcessingMethod()
    }
  }
}
</script>

<style lang="stylus" scoped>
.padding_box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: white;
}
#dv-full-screen-container {
  height: 100vh !important;
}
.content_box {
  width: 100%;
  display: flex;
  height: calc(100% - 100px);
  align-items: flex-start;
}
.input_search {
  height: 100%;width: 100%;border: none;background-color: transparent;
  color: white;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 40px;
}
.input_search:focus {
  border: none !important;
  outline: none;
}
.search_icon {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}
@keyframes rotate {
  0% { transform: rotate(0deg); } /* 初始状态为不旋转 */
  100% { transform: rotate(360deg); } /* 结束状态为完全旋转一周（360度）*/
}
.fen-che {
  width: 15%;height: auto;position: absolute;
  right: 10%;top: 40%;
  //animation-name: rotate; /* 指定要应用的动画名称 */
  //animation-duration: 2s; /* 设置动画持续时间 */
  //animation-iteration-count: infinite; /* 无限次重复播放动画 */
  //animation-timing-function: linear; /* 线性过渡效果 */
}
</style>
