export function listStation(params) {
  return {
    url: `/hydrogen/station/real/listStation`,
    method: 'get',
    params
  }
}

export function getStationInfo(params) {
  return {
    url: `/hydrogen/station/real/getStationInfo/${params.stationId}`,
    method: 'get',
    params
  }
}
