<template>
  <div style="width: 100%;position: relative;pointer-events: visible">
    <el-select class="search_class"
               popper-append-to-body
               filterable
               clearable
               v-bind="$attrs"
               v-model="val_"
               v-on="$listeners">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
<!--    <i class="el-icon-search search_icon"/>-->
  </div>
</template>

<script>
export default {
  name: "QcSelect",
  model: {
    prop: 'value',
    event: 'to-change'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: () => ''
    }
  },
  computed: {
    val_: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('to-change',val)
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
.search_class {
  width: 100%;
}
::v-deep .search_class .el-input {
  height: 30px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #69E5B7;
  background-color: transparent !important;
}
::v-deep .search_class .el-input__inner {
  background-color: transparent !important;
  border: none;
}
.search_icon {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}
//::v-deep .search_class .el-input__suffix {
//  display: none;
//}
</style>
