<template>
  <div style="overflow-y: hidden;width: 100%;height: 100%;">
    <div class="padding_box">
      <top-header @click.native="fullScan" style="color: #70EFBD" title="加氢站实时动态" />
      <div style="position: fixed;right: 380px;top: 60px;color: #6DE9B9;">{{ nowDate }}</div>
      <div class="content_box">
        <!-- 左侧 -->
        <div style="height: 100%;width: calc(20% - 20px);margin-right: 20px;box-sizing: border-box;">
          <qc-select
              popper-append-to-body
              style="width: calc(100% - 30px);margin-left: 20px;margin-bottom: 10px;"
              @change="(val) => {
                 if (val) {
                   Object.assign(listStationInfo, listStationData.find(row => row.id === val));
                   init();
                 }
              }"
              filterable
              remote
              :remote-method="listStation"
              :options="listStationData"
              placeholder="请输入加氢站名称"
              v-model="searchVal"/>
          <div ref="leftRef" style="position: relative;background-color: #1B3231;box-sizing: border-box;margin-top: 10px;margin-left: 20px;width: calc(100% - 21px);height: calc(100% - 50px);">
            <canvas ref="leftCanvasRef" class="horn"/>
            <div style="box-sizing: border-box;padding: 10px;z-index: 2;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">

              <!-- left top -->
              <div ref="leftTopRef" style="height: 25%;width: 100%;display: flex;align-items: center;justify-content: center;">
                <dv-decoration-9
                    :color="['#6FEFBC']"
                    :style="{
                      width: (leftTopStyle.height * 0.8) + 'px',
                      height: (leftTopStyle.height * 0.8) + 'px',
                    }"
                >
                  <div style="display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                    <div style="font-size: 22px;font-weight: bolder;">99%</div>
                    <div style="font-size: 8px;">预约达成率</div>
                  </div>
                </dv-decoration-9>
              </div>

              <!-- left middle -->
              <div ref="leftTopRef" style="height: 20%;width: 100%;display: flex;align-items: center;justify-content: center;">
                <div style="display: flex;align-items: center;flex-flow: row wrap;width: 100%;border: 1px solid #69E5B7;box-sizing: border-box;padding: 20px;">
                  <div style="width: 50%;display: flex;align-items: center;margin-bottom: 10px;">
                    <img :src="require('./img/告警.png')" style="width: 30px;height: auto;" />
                    <div style="font-size: 10px;margin-left: 6px;">
                      <div style="color: #69E5B7;">24小时告警</div>
                      <div>0</div>
                    </div>
                  </div>
                  <div style="width: 50%;display: flex;align-items: center;margin-bottom: 10px;">
                    <img :src="require('./img/告警 (1).png')" style="width: 30px;height: auto;" />
                    <div style="font-size: 10px;margin-left: 6px;">
                      <div style="color: #69E5B7;">本周内巡检异常</div>
                      <div>0</div>
                    </div>
                  </div>
                  <div style="width: 50%;display: flex;align-items: center;margin-bottom: 10px;">
                    <img :src="require('./img/告警.png')" style="width: 30px;height: auto;" />
                    <div style="font-size: 10px;margin-left: 6px;">
                      <div style="color: #69E5B7;">站场车辆(台)</div>
                      <div>0</div>
                    </div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 10px;height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;justify-content: space-between">
                <div style="display: flex;align-items: center">
                  <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                  周动态
                </div>
              </div>
              <div style="height: 22%;width: 100%;margin-top: 5px;">
                <div style="width: 100%;height: 100%;" ref="leftEcharts1"/>
              </div>

              <div style="margin-top: 10px;height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;justify-content: space-between">
                <div style="display: flex;align-items: center">
                  <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                  站场24小时加注车辆动态
                </div>
              </div>
              <div style="height: 22%;width: 100%;margin-top: 5px;">
                <div style="width: 100%;height: 100%;" ref="leftEcharts2"/>
              </div>

            </div>
          </div>
        </div>
        <!-- 中间 -->
        <div style="height: calc(100% - 12px);position: relative;box-sizing: border-box;padding-right: 15px;width: 60%;">

          <div style="height: 59px;width: 100%;display: flex;align-items: center;justify-content: space-around">
            <div style="border: 1px solid #7CCDAD;box-sizing: border-box;padding: 10px;">
              <div style="color: #75C3A4;font-size: 14px;">当前氢价(元/kg)</div>
              <div style="color: #FCFCFC;font-size: 14px;">¥{{ stationInfo.unitPrice }}</div>
            </div>
            <div style="border: 1px solid #7CCDAD;box-sizing: border-box;padding: 10px;">
              <div style="color: #75C3A4;font-size: 14px;">加氢预约次数</div>
              <div style="color: #FCFCFC;font-size: 14px;">{{ stationInfo.appointmentNum }}</div>
            </div>
            <div style="border: 1px solid #7CCDAD;box-sizing: border-box;padding: 10px;">
              <div style="color: #75C3A4;font-size: 14px;">本日加氢量(kg)</div>
              <div style="color: #FCFCFC;font-size: 14px;">{{ stationInfo.todayWeight }}</div>
            </div>
            <div style="border: 1px solid #7CCDAD;box-sizing: border-box;padding: 10px;">
              <div style="color: #75C3A4;font-size: 14px;">本日加氢次数</div>
              <div style="color: #FCFCFC;font-size: 14px;">{{ stationInfo.todayVehicleNum }}</div>
            </div>
            <div style="border: 1px solid #7CCDAD;box-sizing: border-box;padding: 10px;">
              <div style="color: #75C3A4;font-size: 14px;">本月加氢量(kg)</div>
              <div style="color: #FCFCFC;font-size: 14px;">{{ stationInfo.monthWeight }}</div>
            </div>
            <div style="border: 1px solid #7CCDAD;box-sizing: border-box;padding: 10px;">
              <div style="color: #75C3A4;font-size: 14px;">本月加氢车次</div>
              <div style="color: #FCFCFC;font-size: 14px;">{{ stationInfo.monthVehicleNum }}</div>
            </div>
          </div>

          <div class="middle_bg" style="margin-top: 10px;height: 33%;width: 100%;display: flex;justify-content: space-around;position: relative;">
            <div style="width: 30%;display: flex;align-items: center;flex-flow: column nowrap;">
              <span style="margin-top: 10px;color: #56B681;font-size: 12px;">加氢机系统</span>
              <img :src="require('./img/编组 4.png')" style="width: 70%;height: auto;margin-top: 5px;" />
              <div style="margin-top: -10px;width: 100%;display: flex;align-items: center;justify-content: space-around">
                <div style="width: calc(50% - 5px);border: 1px solid #56B681;box-sizing: border-box;">
                  <div style="box-sizing: border-box;padding-left: 4px;background-color: #6FEFBC;width: 100%;height: 20px;font-size: 8px;line-height: 20px;color: black">
                    1号枪
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>总累计流量</span>
                    <span>100000</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>压力流量计</span>
                    <span>23</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>压力传感</span>
                    <span>23232</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>温度流量计</span>
                    <span>36</span>
                  </div>
                  <div style="display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>限时流量</span>
                    <span>4311</span>
                  </div>
                </div>
                <div style="width: calc(50% - 5px);border: 1px solid #56B681;box-sizing: border-box;">
                  <div style="box-sizing: border-box;padding-left: 4px;background-color: #6FEFBC;width: 100%;height: 20px;font-size: 8px;line-height: 20px;color: black">2号枪</div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>总累计流量</span>
                    <span>100000</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>压力流量计</span>
                    <span>23</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>压力传感</span>
                    <span>23232</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>温度流量计</span>
                    <span>36</span>
                  </div>
                  <div style="display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>限时流量</span>
                    <span>4311</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 30%;">
              <div style="margin-bottom: 30px;margin-top: 10px;font-size: 20px;color: #D8DCD9;text-align: center;">当前站场氢气余量</div>
              <div style="font-size:30px;color: #71F6FD;font-weight: bolder;text-align: center;display: flex;align-items: center;justify-content: center;">
                <img :src="require('./img/5.png')" style="width: 20px;height: auto;" />
                <img :src="require('./img/0.png')" style="width: 20px;height: auto;margin-left: 5px;" />
                <img :src="require('./img/0.png')" style="width: 20px;height: auto;margin-left: 5px;" />
                kg
              </div>
              <div style="margin-top: 40px;width: 100%;background-color: #1F4730;padding: 10px;box-sizing: border-box;">
                <div style="width: 100%;text-align: center;font-size: 12px;color: #4DABCC;">当前安全员</div>
                <div style="margin-top: 10px;width: 100%;display: flex;justify-content: space-around;">
                  <div style="display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                    <img style="width: 30px;height: auto;" :src="require('./img/头像 (1).png')" />
                    <span style="font-size: 10px;">邱丽君</span>
                  </div>
                  <div style="display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                    <img style="width: 30px;height: auto;" :src="require('./img/头像 (1).png')" />
                    <span style="font-size: 10px;">周利</span>
                  </div>
                  <div style="display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                    <img style="width: 30px;height: auto;" :src="require('./img/头像 (1).png')" />
                    <span style="font-size: 10px;">邓建龙</span>
                  </div>
                  <div style="display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                    <img style="width: 30px;height: auto;" :src="require('./img/头像 (1).png')" />
                    <span style="font-size: 10px;">肖正</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 30%;display: flex;align-items: center;flex-flow: column nowrap;">
              <span style="margin-top: 10px;color: #56B681;font-size: 12px;">加氢机系统</span>
              <img :src="require('./img/编组 4.png')" style="width: 70%;height: auto;margin-top: 5px;" />
              <div style="margin-top: -10px;width: 100%;display: flex;align-items: center;justify-content: space-around">
                <div style="width: calc(50% - 5px);border: 1px solid #56B681;box-sizing: border-box;">
                  <div style="box-sizing: border-box;padding-left: 4px;background-color: #6FEFBC;width: 100%;height: 20px;font-size: 8px;line-height: 20px;color: black">
                    1号枪
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>总累计流量</span>
                    <span>100000</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>压力流量计</span>
                    <span>23</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>压力传感</span>
                    <span>23232</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>温度流量计</span>
                    <span>36</span>
                  </div>
                  <div style="display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>限时流量</span>
                    <span>4311</span>
                  </div>
                </div>
                <div style="width: calc(50% - 5px);border: 1px solid #56B681;box-sizing: border-box;">
                  <div style="box-sizing: border-box;padding-left: 4px;background-color: #6FEFBC;width: 100%;height: 20px;font-size: 8px;line-height: 20px;color: black">2号枪</div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>总累计流量</span>
                    <span>100000</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>压力流量计</span>
                    <span>23</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>压力传感</span>
                    <span>23232</span>
                  </div>
                  <div style="border-bottom: 1px solid #387A8C;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>温度流量计</span>
                    <span>36</span>
                  </div>
                  <div style="display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 4px;padding-right: 4px;width: 100%;height: 20px;font-size: 8px;line-height: 20px;">
                    <span>限时流量</span>
                    <span>4311</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 底部 -->
          <div style="margin-top: 20px;width: 100%;display: flex;justify-content: space-between;height: calc(67% - 89px)">
            <div style="width: calc(45% - 5px);height: 100%;">
              <div style="width: 100%;display: flex;justify-content: space-between;flex-flow: row wrap;height: 45%">
                <div style="width: calc(60% - 5px);border: 0.5px solid #69E5B7;background-color: #1A3134">
                  <div style="margin-top: 5px;margin-bottom: 5px;width: 100%;text-align: center;font-size: 10px;color: #4CA9D0;">中压罐</div>
                  <div style="width: 100%;display: flex;align-items: center;justify-content: space-around">
                    <div style="width: 30%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                      <img style="width: 100%;height: auto" :src="require('./img/圆柱体.png')" />
                      <div style="font-size: 8px;color: #4DADD3;">1号罐</div>
                      <div style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;color: #E6E8E7;font-size: 10px;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                        <div>余量</div>
                        <div>214kg</div>
                      </div>
                    </div>
                    <div style="width: 30%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                      <img style="width: 100%;height: auto" :src="require('./img/圆柱体.png')" />
                      <div style="font-size: 8px;color: #4DADD3;">2号罐</div>
                      <div style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;color: #E6E8E7;font-size: 10px;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                        <div>余量</div>
                        <div>200kg</div>
                      </div>
                    </div>
                    <div style="width: 30%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                      <img style="width: 100%;height: auto" :src="require('./img/圆柱体.png')" />
                      <div style="font-size: 8px;color: #4DADD3;">3号罐</div>
                      <div style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;color: #E6E8E7;font-size: 10px;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                        <div>余量</div>
                        <div>122</div>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%;display: flex;align-items: center;justify-content: space-around">
                    <div style="position: relative;font-size: 8px;width: 30%;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap">
                      <img :src="require('./img/压力.png')" style="width: 100%;height: auto;">
                      <div style="position: absolute;bottom: 10px;left: 0;width: 100%;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                        <div style="color: #D6DADA;">22mp</div>
                        <div style="color: #DFE4E4">TT902</div>
                      </div>
                    </div>
                    <div style="position: relative;font-size: 8px;width: 30%;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap">
                      <img :src="require('./img/压力.png')" style="width: 100%;height: auto;">
                      <div style="position: absolute;bottom: 10px;left: 0;width: 100%;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                        <div style="color: #D6DADA;">22mp</div>
                        <div style="color: #DFE4E4">TT902</div>
                      </div>
                    </div>
                    <div style="position: relative;font-size: 8px;width: 30%;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap">
                      <img :src="require('./img/压力.png')" style="width: 100%;height: auto;">
                      <div style="position: absolute;bottom: 10px;left: 0;width: 100%;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                        <div style="color: #D6DADA;">22mp</div>
                        <div style="color: #DFE4E4">TT902</div>
                      </div>
                    </div>

                  </div>
                  <div style="margin-bottom: 10px;width: 100%;display: flex;align-items: center;justify-content: center;font-size: 10px;color: #52B6E0;">
                    氢气管温度TTo301
                    <div style="margin-right: 3px;margin-left: 3px;width: 30%;height: 20px;background-color: #122722;border-radius: 5px;">
                      <div style="width: 60%;height: 100%;background-color: #81D7A1;border-radius: 5px;" />
                    </div>
                    <span style="font-size: 12px;">34°c</span>
                  </div>
                </div>
                <div style="width: calc(40% - 5px);border: 0.5px solid #69E5B7;background-color: #1A3134;padding-bottom: 30px;">
                  <div style="margin-bottom: 5px;margin-top: 5px;width: 100%;text-align: center;font-size: 10px;color: #4CA9D0;">低压罐</div>
                  <div style="width: 100%;display: flex;align-items: center;justify-content: space-around;flex-flow: column nowrap;">
                    <div style="width: 45%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                      <img style="width: 100%;height: auto" :src="require('./img/圆柱体.png')" />
                      <div style="font-size: 8px;color: #4DADD3;">1号罐</div>
                      <div style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;color: #E6E8E7;font-size: 10px;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                        <div>余量</div>
                        <div>214kg</div>
                      </div>
                    </div>
                    <div style="position: relative;font-size: 8px;width: 45%;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap">
                      <img :src="require('./img/压力.png')" style="width: 100%;height: auto;"/>
                      <div style="position: absolute;bottom: 10px;left: 0;width: 100%;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                        <div style="color: #D6DADA;">22mp</div>
                        <div style="color: #DFE4E4">TT902</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div style="width: 100%;border: 0.5px solid #69E5B7;margin-top: 10px;box-sizing: border-box;padding: 10px;height: calc(55% - 10px);">
                <div style="color: #479BBC;font-size: 12px;margin-bottom: 10px;">预冷系统</div>
                <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;flex-flow: row wrap;">
                  <div style="margin-bottom: 10px;border: 0.5px solid #3E83A1;width: calc(50% - 5px);box-sizing: border-box;padding: 10px;display: flex;align-items: center;justify-content: space-around">
                    <div style="font-size: 12px;">
                      <div>进1号加氢机温度</div>
                      <div>T0dsd1</div>
                    </div>
                    <div style="display: flex;align-items: center">
                      37°c
                    </div>
                  </div>
                  <div style="margin-bottom: 10px;border: 0.5px solid #3E83A1;width: calc(50% - 5px);box-sizing: border-box;padding: 10px;display: flex;align-items: center;justify-content: space-around">
                    <div style="font-size: 12px;">
                      <div>进2号加氢机温度</div>
                      <div>T0dsd1</div>
                    </div>
                    <div style="display: flex;align-items: center">
                      37°c
                    </div>
                  </div>
                  <div style="margin-bottom: 10px;border: 0.5px solid #3E83A1;width: calc(50% - 5px);box-sizing: border-box;padding: 10px;display: flex;align-items: center;justify-content: space-around">
                    <div style="font-size: 12px;">
                      <div>进水温度</div>
                      <div>T0dsd1</div>
                    </div>
                    <div style="display: flex;align-items: center">
                      37°c
                    </div>
                  </div>
                  <div style="margin-bottom: 10px;border: 0.5px solid #3E83A1;width: calc(50% - 5px);box-sizing: border-box;padding: 10px;display: flex;align-items: center;justify-content: space-around">
                    <div style="font-size: 12px;">
                      <div>回水温度</div>
                      <div>T0dsd1</div>
                    </div>
                    <div style="display: flex;align-items: center">
                      37°c
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div style="display: flex;box-sizing: border-box;padding: 10px;width: calc(55% - 5px);border: 0.5px solid #69E5B7;background-color: #1A3134">
              <div style="width: 50%;height: 100%">
                <div style="width: 90%;height: calc(34% - 10px);border: 2px solid #6FEFBC;box-sizing: border-box;padding: 10px;display: flex;align-items: center;justify-content: center;">
                  <img :src="require('./img/编组.png')" style="width: 90%;height: auto;" />
                </div>
                <div style="margin-top: 5px;margin-bottom: 5px;height: 16%;width: 100%;display: flex;align-items: center">
                  <div style="width: 30%;font-size: 12px;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                    <div style="position: relative;width: 100%;">
                      <img :src="require('./img/编组 3.png')" style="width: 100%;" />
                      <div style="width: 100%;display: flex;align-items: center;justify-content: center;position: absolute;bottom: 0;left: 0">38mp</div>
                    </div>
                    氢气源压力
                  </div>
                  <div style="width: 30%;font-size: 12px;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                    <div style="position: relative;width: 100%;">
                      <img :src="require('./img/编组 3.png')" style="width: 100%;" />
                      <div style="width: 100%;display: flex;align-items: center;justify-content: center;position: absolute;bottom: 0;left: 0">40mp</div>
                    </div>
                    进口压力
                  </div>
                  <div style="width: 30%;font-size: 12px;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                    <div style="position: relative;width: 100%;">
                      <img :src="require('./img/编组 3.png')" style="width: 100%;" />
                      <div style="width: 100%;display: flex;align-items: center;justify-content: center;position: absolute;bottom: 0;left: 0">84mp</div>
                    </div>
                    出口压力
                  </div>
                </div>
                <div style="width: 90%;height: calc(34% - 10px);border: 2px solid #6FEFBC;box-sizing: border-box;padding: 10px;display: flex;align-items: center;justify-content: center;">
                  <img :src="require('./img/编组.png')" style="width: 90%;height: auto;" />
                </div>
                <div style="margin-top: 5px;height: 16%;width: 100%;display: flex;align-items: center">
                  <div style="width: 30%;font-size: 12px;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                    <div style="position: relative;width: 100%;">
                      <img :src="require('./img/编组 3.png')" style="width: 100%;" />
                      <div style="width: 100%;display: flex;align-items: center;justify-content: center;position: absolute;bottom: 0;left: 0">38mp</div>
                    </div>
                    氢气源压力
                  </div>
                  <div style="width: 30%;font-size: 12px;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                    <div style="position: relative;width: 100%;">
                      <img :src="require('./img/编组 3.png')" style="width: 100%;" />
                      <div style="width: 100%;display: flex;align-items: center;justify-content: center;position: absolute;bottom: 0;left: 0">42mp</div>
                    </div>
                    进口压力
                  </div>
                  <div style="width: 30%;font-size: 12px;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                    <div style="position: relative;width: 100%;">
                      <img :src="require('./img/编组 3.png')" style="width: 100%;" />
                      <div style="width: 100%;display: flex;align-items: center;justify-content: center;position: absolute;bottom: 0;left: 0">76mp</div>
                    </div>
                    出口压力
                  </div>
                </div>
              </div>
              <div style="width: 50%;height: 100%">
                <div style="position: relative;width: 100%;height: 50%;display: flex;flex-flow: column nowrap;align-items: flex-end;">
                  <div style="color: #4983B0;font-size: 12px;position: absolute;left: 0;top: 0;height: 100%;box-sizing: border-box;padding-top: 10%">一级压缩机</div>
                  <span style="font-size: 10px;margin-bottom: 4px;color: #4983B0;">累计运行时间：5小时31分22秒</span>
                  <div style="font-size: 12px;height: calc(100% - 30px);width: 70%;border: 1px solid #6FEFBC;">
                    <div style="box-sizing: border-box;padding-left: 5px;padding-right:5px;width: 100%;height: 20%;background-color: #122722;display: flex;align-items: center;justify-content: space-between;">
                      <span style="color: #A7AEAF;">换热器出口温度</span>
                      <span style="color: #4983B0">39.5°C</span>
                    </div>
                    <div style="box-sizing: border-box;padding-left: 5px;padding-right:5px;width: 100%;height: 20%;background-color: #293D3E;display: flex;align-items: center;justify-content: space-between;">
                      <span style="color: #A7AEAF">压缩机出口温度</span>
                      <span style="color: #4983B0">38.2°C</span>
                    </div>
                    <div style="box-sizing: border-box;padding-left: 5px;padding-right:5px;width: 100%;height: 20%;background-color: #122722;display: flex;align-items: center;justify-content: space-between;">
                      <span style="color: #A7AEAF">油温</span>
                      <span style="color: #4983B0">38.5°C</span>
                    </div>
                    <div style="box-sizing: border-box;padding-left: 5px;padding-right:5px;width: 100%;height: 20%;background-color: #293D3E;display: flex;align-items: center;justify-content: space-between;">
                      <span style="color: #A7AEAF">冷却水温度</span>
                      <span style="color: #4983B0">38.1°C</span>
                    </div>
                    <div style="box-sizing: border-box;padding-left: 5px;padding-right:5px;width: 100%;height: 20%;background-color: #122722;display: flex;align-items: center;justify-content: space-between;">
                      <span style="color: #A7AEAF">仪表风温度</span>
                      <span style="color: #4983B0">36.5°C</span>
                    </div>
                  </div>
                </div>
                <div style="position: relative;width: 100%;height: 50%;display: flex;flex-flow: column nowrap;align-items: flex-end;">
                  <div style="color: #4983B0;font-size: 12px;position: absolute;left: 0;top: 0;height: 100%;box-sizing: border-box;padding-top: 10%">二级压缩机</div>
                  <span style="font-size: 10px;margin-bottom: 4px;color: #4983B0;">累计运行时间：5小时31分22秒</span>
                  <div style="font-size: 12px;height: calc(100% - 30px);width: 70%;border: 1px solid #6FEFBC;">
                    <div style="box-sizing: border-box;padding-left: 5px;padding-right:5px;width: 100%;height: 20%;background-color: #122722;display: flex;align-items: center;justify-content: space-between;">
                      <span style="color: #A7AEAF">换热器出口温度</span>
                      <span style="color: #4983B0">37.5°C</span>
                    </div>
                    <div style="box-sizing: border-box;padding-left: 5px;padding-right:5px;width: 100%;height: 20%;background-color: #293D3E;display: flex;align-items: center;justify-content: space-between;">
                      <span style="color: #A7AEAF">压缩机出口温度</span>
                      <span style="color: #4983B0">36.2°C</span>
                    </div>
                    <div style="box-sizing: border-box;padding-left: 5px;padding-right:5px;width: 100%;height: 20%;background-color: #122722;display: flex;align-items: center;justify-content: space-between;">
                      <span style="color: #A7AEAF">油温</span>
                      <span style="color: #4983B0">39.5°C</span>
                    </div>
                    <div style="box-sizing: border-box;padding-left: 5px;padding-right:5px;width: 100%;height: 20%;background-color: #293D3E;display: flex;align-items: center;justify-content: space-between;">
                      <span style="color: #A7AEAF">冷却水温度</span>
                      <span style="color: #4983B0">33.1°C</span>
                    </div>
                    <div style="box-sizing: border-box;padding-left: 5px;padding-right:5px;width: 100%;height: 20%;background-color: #122722;display: flex;align-items: center;justify-content: space-between;">
                      <span style="color: #A7AEAF">仪表风温度</span>
                      <span style="color: #4983B0">39.5°C</span>
                    </div>
                  </div>
                </div>
              </div>

<!--              <div style="height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;margin-bottom: 10px;">-->
<!--                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>一级压缩机-->
<!--              </div>-->
<!--              <div style="width: 100%;font-size: 10px;">-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #122722;">-->
<!--                  <span>换热器出口温度</span>-->
<!--                  <span style="color: #4D88B8">39.5°C</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #2A3E3F;">-->
<!--                  <span>压缩机出口温度</span>-->
<!--                  <span style="color: #4D88B8">37.3°C</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #122722;">-->
<!--                  <span>油温</span>-->
<!--                  <span style="color: #4D88B8">40.12°C</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #2A3E3F;">-->
<!--                  <span>冷却水温度</span>-->
<!--                  <span style="color: #4D88B8">59.33°C</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #122722;">-->
<!--                  <span>仪表风压力</span>-->
<!--                  <span style="color: #4D88B8">38mp</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #2A3E3F;">-->
<!--                  <span>氢气源压力</span>-->
<!--                  <span style="color: #4D88B8">23mp</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #122722;">-->
<!--                  <span>进口压力</span>-->
<!--                  <span style="color: #4D88B8">12mp</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #2A3E3F;">-->
<!--                  <span>出口压力</span>-->
<!--                  <span style="color: #4D88B8">44mp</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="margin-top: 10px;height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;margin-bottom: 10px;">-->
<!--                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>二级压缩机-->
<!--              </div>-->
<!--              <div style="width: 100%;font-size: 10px;">-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #122722;">-->
<!--                  <span>换热器出口温度</span>-->
<!--                  <span style="color: #4D88B8">39.5°C</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #2A3E3F;">-->
<!--                  <span>压缩机出口温度</span>-->
<!--                  <span style="color: #4D88B8">37.3°C</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #122722;">-->
<!--                  <span>油温</span>-->
<!--                  <span style="color: #4D88B8">40.12°C</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #2A3E3F;">-->
<!--                  <span>冷却水温度</span>-->
<!--                  <span style="color: #4D88B8">59.33°C</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #122722;">-->
<!--                  <span>仪表风压力</span>-->
<!--                  <span style="color: #4D88B8">38mp</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #2A3E3F;">-->
<!--                  <span>氢气源压力</span>-->
<!--                  <span style="color: #4D88B8">23mp</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #122722;">-->
<!--                  <span>进口压力</span>-->
<!--                  <span style="color: #4D88B8">12mp</span>-->
<!--                </div>-->
<!--                <div style="display: flex;align-items:center;justify-content:space-between;width: 100%;box-sizing: border-box;padding: 4px;background-color: #2A3E3F;">-->
<!--                  <span>出口压力</span>-->
<!--                  <span style="color: #4D88B8">44mp</span>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>

        </div>
        <!-- 右侧 -->
        <div ref="rightRef" style="position: relative;height: calc(100% - 10px);width: calc(20% - 20px);margin-right: 20px;">
          <canvas ref="rightCanvasRef" class="horn"/>
          <div style="box-sizing: border-box;padding: 10px;z-index: 2;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">

            <div style="margin-top: 10px;height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;justify-content: space-between">
              <div style="display: flex;align-items: center">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                已持续安全运营
              </div>
            </div>

            <div style="margin-top: 10px;width: 100%;display: flex;align-items: center;justify-content: center;">
              <div style="margin-right: 10px;color: #F8AE10;display: flex;align-items: center;justify-content: center;width: 40px;height: 40px;box-sizing: border-box;border-radius: 3px;font-size: 24px;font-weight: bolder;border: 1px solid #60CA93;background-color: #284746;">
                0
              </div>
              <div style="margin-right: 10px;color: #F8AE10;display: flex;align-items: center;justify-content: center;width: 40px;height: 40px;box-sizing: border-box;border-radius: 3px;font-size: 24px;font-weight: bolder;border: 1px solid #60CA93;background-color: #284746;">
                0
              </div>
              <div style="margin-right: 10px;color: #F8AE10;display: flex;align-items: center;justify-content: center;width: 40px;height: 40px;box-sizing: border-box;border-radius: 3px;font-size: 24px;font-weight: bolder;border: 1px solid #60CA93;background-color: #284746;">
                4
              </div>
              <div style="margin-right: 10px;color: #F8AE10;display: flex;align-items: center;justify-content: center;width: 40px;height: 40px;box-sizing: border-box;border-radius: 3px;font-size: 24px;font-weight: bolder;border: 1px solid #60CA93;background-color: #284746;">
                2
              </div>
              <span>天</span>
            </div>

            <div style="margin-bottom: 10px;margin-top: 40px;height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;justify-content: space-between">
              <div style="display: flex;align-items: center">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                氢气系统
              </div>
            </div>

            <div style="display: flex;align-items: center;flex-flow: row wrap;justify-content: space-between">
              <div style="margin-bottom: 10px;flex-flow: column nowrap;display: flex;align-items: center;justify-content: center">
                <span style="font-size: 12px;color: #15D3E9">氢气盘PT0501</span>
                <span style="font-size: 18px;color: #F8AE10;font-weight: bolder;">91mpa</span>
              </div>
              <div style="margin-bottom: 10px;flex-flow: column nowrap;display: flex;align-items: center;justify-content: center">
                <span style="font-size: 12px;color: #15D3E9">氢气盘PT0501</span>
                <span style="font-size: 18px;color: #F8AE10;font-weight: bolder;">91mpa</span>
              </div>
            </div>
            <div style="display: flex;align-items: center;flex-flow: row wrap;justify-content: space-between">
              <div style="margin-bottom: 10px;flex-flow: column nowrap;display: flex;align-items: center;justify-content: center">
                <span style="font-size: 12px;color: #15D3E9">氢气盘PT0501</span>
                <span style="font-size: 18px;color: #F8AE10;font-weight: bolder;">91mpa</span>
              </div>
              <div style="margin-bottom: 10px;flex-flow: column nowrap;display: flex;align-items: center;justify-content: center">
                <span style="font-size: 12px;color: #15D3E9">氢气盘PT0501</span>
                <span style="font-size: 18px;color: #F8AE10;font-weight: bolder;">91mpa</span>
              </div>
            </div>

            <div style="margin-top: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <img :src="require('./img/告警 (2).png')" style="width: 30px;height: auto;" />
              <span style="font-weight: bolder;">&gt; 80℃</span>
            </div>
            <div style="font-size: 12px">06 - 12 12:00(10次)</div>
            <div style="font-size: 12px;display: flex;align-items: center;justify-content: space-between">
              <span>设备：一级压缩机</span>
              <span>指标：冷冻水温度</span>
            </div>

            <div style="margin-top: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <img :src="require('./img/告警 (3).png')" style="width: 30px;height: auto;" />
              <span style="font-weight: bolder;">&gt; 80℃</span>
            </div>
            <div style="font-size: 12px">06 - 12 12:00(10次)</div>
            <div style="font-size: 12px;display: flex;align-items: center;justify-content: space-between">
              <span>设备：一级压缩机</span>
              <span>指标：冷冻水温度</span>
            </div>

            <div style="margin-top: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;">
              <img :src="require('./img/告警 (3).png')" style="width: 30px;height: auto;" />
              <span style="font-weight: bolder;">&gt; 80℃</span>
            </div>
            <div style="font-size: 12px">06 - 12 12:00(10次)</div>
            <div style="font-size: 12px;display: flex;align-items: center;justify-content: space-between">
              <span>设备：一级压缩机</span>
              <span>指标：冷冻水温度</span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import dayjs from 'dayjs'
import { listStation, getStationInfo } from '@/api/hydrogen/station/real'
import isBlank from '@/utils/isBlank'

let intervalRes
let intervalDate
export default {
  name: "hydrogen-refueling-station-dynamics",
  data() {
    const stationInfo = this.defaultStationInfo()
    return {
      searchVal: null,
      leftTopStyle: {
        width: 100,
        height: 100
      },
      leftEcharts1: null,
      leftEcharts2: null,
      nowDate: dayjs().format('YYYY年MM月DD日 HH:mm:ss'),
      listStationData: [],
      listStationInfo: {
        id: null,
        name: null
      },
      stationInfo
    }
  },
  created() {
    document.title = '加氢站实时动态'
    intervalDate = setInterval(() => {
      this.nowDate = dayjs().format('YYYY年MM月DD日 HH:mm:ss')
    }, 1000)
    window.addEventListener('resize', this.reLoad)
  },
  async mounted() {
    await this.listStation()
    if (this.listStationData.length !== 0) {
      Object.assign(this.listStationInfo, this.listStationData[0])
    }
    this.init()
    this.leftTopStyle.width = this.$refs.leftTopRef.offsetWidth
    this.leftTopStyle.height = this.$refs.leftTopRef.offsetHeight
  },
  beforeDestroy() {
    this.clearEcharts()
    if (intervalRes) {
      clearInterval(intervalRes)
    }
    if (intervalDate) {
      clearInterval(intervalDate)
    }
    window.removeEventListener('resize', this.reLoad)
  },
  methods: {
    fullScan() {
      if (screenfull.isFullscreen) {
        screenfull.exit()
      } else {
        screenfull.request()
      }
    },
    clearEcharts() {

    },
    reLoad() {
      this.clearEcharts()
      this.$nextTick(() => {
        setTimeout(this.init, 100)
      })
    },
    search() {},
    async init() {
      this.getStationInfo()
      this.drawLeftBoxBorder()
      this.drawRightBoxBorder()
      this.initLeft1Echarts()
      this.initLeft2Echarts()
    },
    drawLeftBoxBorder() {
      const height = this.$refs.leftRef.offsetHeight
      const width = this.$refs.leftRef.offsetWidth
      // hornRef
      let canvas = this.$refs.leftCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      context.moveTo(0,0);
      context.lineTo(0, height);

      context.moveTo(0, height);
      context.lineTo(width, height);

      // 右边线段
      context.moveTo(width, height);
      context.lineTo(width, 20);

      // 顶部线段
      context.moveTo(0, 0);
      context.lineTo(width - 20, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(width - 20, 0);
      context.lineTo(width, 20);
      context.stroke();
    },
    drawRightBoxBorder() {
      const height = this.$refs.rightRef.offsetHeight
      const width = this.$refs.rightRef.offsetWidth
      // hornRef
      let canvas = this.$refs.rightCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3

      // left
      context.moveTo(0,height);
      context.lineTo(0, 20);

      // bottom
      context.moveTo(0, height);
      context.lineTo(width, height);

      // right
      context.moveTo(width, height);
      context.lineTo(width, 0);

      // top
      context.moveTo(width, 0);
      context.lineTo(20, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(20 , 0);
      context.lineTo(0, 20);
      context.stroke();
    },
    async initLeft1Echarts() {
      if (!this.leftEcharts1) {
        this.leftEcharts1 = this.$echarts.init(this.$refs.leftEcharts1);
      }
      this.leftEcharts1.setOption({
        tooltip: {
          show: true,
          trigger: 'item'
        },
        grid: {
          left: '0%',
          right: '0%', //默认10%
          bottom: '3%', //默认60
          top: '50', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        legend: {
          data: [
            {
              name: '加注量',
              textStyle: {
                color: '#fff'
              }
            },
            {
              name: '车次',
              textStyle: {
                color: '#fff'
              }
            }
          ]
        },
        darkMode: true,
        xAxis: [
          {
            type: 'category',
            data: ['周一','周二','周三', '周四', '周五', '周六', '周日'],
            axisPointer: {
              type: 'shadow'
            },
            splitLine: {
              show: true,
              lineStyle: {
                width: 0.5,
                // 网格线的颜色
                color: '#425052',
                // 网格线的类型
                type: 'solid',
                cup: 'round',
                join: 'miter',
                miterLimit: 3,
                shadowBlur: 10,
                shadowColor: '#425052',
                opacity: 1
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '加注量(kg)',
            min: 0,
            max: 250,
            interval: 50,
            // axisLabel: {
            //   formatter: '{value} kg'
            // },
            splitLine: {
              show: true,
              lineStyle: {
                width: 0.5,
                // 网格线的颜色
                color: '#425052',
                // 网格线的类型
                type: 'solid',
                cup: 'round',
                join: 'miter',
                miterLimit: 3,
                shadowBlur: 10,
                shadowColor: '#425052',
                opacity: 1
              }
            },
          },
          {
            type: 'value',
            name: '车次',
            // axisLabel: {
            //   formatter: '{value} 车次'
            // },
            min: 0,
            max: 25,
            interval: 5,
            splitLine: {
              show: false,
              lineStyle: {
                width: 0.5,
                // 网格线的颜色
                color: '#425052',
                // 网格线的类型
                type: 'solid',
                cup: 'round',
                join: 'miter',
                miterLimit: 3,
                shadowBlur: 10,
                shadowColor: '#425052',
                opacity: 1
              }
            },
          }
        ],
        series: [
          {
            name: '加注量',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml';
              }
            },
            data: [222,123,55,100,278,155,120]
          },
          {
            name: '车次',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml';
              }
            },
            data: [120,90,3,49,5,60,7]
          },
        ]
      })
    },
    async initLeft2Echarts() {
      if (!this.leftEcharts2) {
        this.leftEcharts2 = this.$echarts.init(this.$refs.leftEcharts2);
      }
      this.leftEcharts2.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: ['08:00', '12:00', '20:00', '00:00', '04:00'],
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        grid: {
          left: '0%', //默认10%
          right: '0%', //默认10%
          bottom: '3%', //默认60
          top: '30', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          },
          name: '氢气量(kg)',
          nameLocation: 'end',
          axisTick: {
            show: true,
            length: 0
          },
          axisLabel: {
            show: true,
            margin: 15 // 根据字体大小调整覆盖程度
          },
        },
        series: [
          {
            data: [1000, 1400, 1200, 400, 1200],
            type: 'bar',
            smooth: true,
            barMaxWidth: '30%',
            itemStyle: {
              color: '#6FEFBC'
            }
          }
        ]
      })
    },

    // 获取氢站列表
    async listStation(keyword) {
      const res = await this.$request(listStation({
        limit: 20,
        name: keyword
      }))
      this.listStationData = res.map(row => {
        return Object.assign({}, row, {
          label: row.name,
          value: row.id
        })
      })
    },
    defaultStationInfo() {
      return {
        appointmentNum: null,
        appointmentOkNum: null,
        appointmentPercent: null,
        id: null,
        monthVehicleNum: null,
        monthWeight: null,
        name: null,
        todayVehicleNum: null,
        todayWeight: null,
        unitPrice: null
      }
    },
    // 获取氢站信息
    async getStationInfo() {
      if (isBlank(this.listStationInfo.id)) {
        return
      }
      const res = await this.$request(getStationInfo({
        stationId: this.listStationInfo.id
      }))
      Object.assign(this.stationInfo, res)
    }
  }
}
</script>

<style lang="stylus" scoped>
.middle_bg {
  background-image: url("./img/背景.png");
  background-size: cover;
}
.padding_box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: white;
}
.content_box {
  width: 100%;
  display: flex;
  height: calc(100% - 100px);
  align-items: flex-start;
}
.input_search {
  height: 100%;width: 100%;border: none;background-color: transparent;
  color: white;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 40px;
}
.input_search:focus {
  border: none !important;
  outline: none;
}
.search_icon {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}
.horn {
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 2;
  background-color: #282c34;
}
</style>
