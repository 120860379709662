export function getMonthBreakRules(params) {
  return {
    url: `/operator/getMonthBreakRules/${params.batchId}`,
    method: 'get',
    params
  }
}

export function getOperatorData(params) {
  return {
    url: `/operator/getOperatorData/${params.batchId}`,
    method: 'get',
    params
  }
}

export function getTodayRepair(params) {
  return {
    url: `/operator/getTodayRepair/${params.batchId}`,
    method: 'get',
    params
  }
}

export function listAnnualReviewEarly(params) {
  return {
    url: `/operator/listAnnualReviewEarly/${params.batchId}`,
    method: 'get',
    params
  }
}

export function listAreaEarly(params) {
  return {
    url: `/operator/listAreaEarly/${params.batchId}`,
    method: 'get',
    params
  }
}

export function listAvgHydrogen(data) {
  return {
    url: `/operator/listAvgHydrogen/${data.batchId}`,
    method: 'post',
    data
  }
}

export function listBreakRules(data) {
  return {
    url: `/operator/listBreakRules/${data.batchId}`,
    method: 'post',
    data
  }
}

export function listDriverMileage(data) {
  return {
    url: `/operator/listDriverMileage/${data.batchId}`,
    method: 'post',
    data
  }
}

export function listHydrogenData(data) {
  return {
    url: `/operator/listHydrogenData/${data.batchId}`,
    method: 'post',
    data
  }
}

export function listHydrogenSysEarly(params) {
  return {
    url: `/operator/listHydrogenSysEarly/${params.batchId}`,
    method: 'get',
    params
  }
}


export function listInsuranceEarly(params) {
  return {
    url: `/operator/listInsuranceEarly/${params.batchId}`,
    method: 'get',
    params
  }
}

export function listMileageLoss(data) {
  return {
    url: `/operator/listMileageLoss/${data.batchId}`,
    method: 'post',
    data
  }
}

export function listRepairHandle(params) {
  return {
    url: `/operator/listRepairHandle/${params.batchId}`,
    method: 'get',
    params
  }
}

export function listRepairPart(params) {
  return {
    url: `/operator/listRepairPart/${params.batchId}`,
    method: 'get',
    params
  }
}

export function listRepairPercent(data) {
  return {
    url: `/operator/listRepairPercent/${data.batchId}`,
    method: 'post',
    data
  }
}

export function listTargetMileagePercent(data) {
  return {
    url: `/operator/listTargetMileagePercent/${data.batchId}`,
    method: 'post',
    data
  }
}

export function listTrajectory(data) {
  return {
    url: `/operator/listTrajectory/${data.batchId}`,
    method: 'post',
    data
  }
}

