<template>
  <div style="pointer-events: visible;width: 100%;display: flex;align-items: center;justify-content: flex-end;">
    <el-date-picker
        v-model="formData.time"
        class="select_bg"
        :clearable="false"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
    </el-date-picker>
    <el-button @click="search" round style="margin-left: 10px;background-color: #69E5B7;">加载轨迹</el-button>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: "SearchBar",
  data() {
    const date = new Date()
    return {
      formData: {
        time: [
          dayjs(date).subtract(1, 'hour'),
          date
        ]
      }
    }
  },
  methods: {
    search() {
      this.$emit('search', this.formData.time)
    }
  },
  mounted() {
    this.$emit('init', this.formData.time)
  }
}
</script>

<style lang="stylus"  scoped>
::v-deep .select_bg .el-input__inner {
  background-color: transparent !important;
  border-color: #69E5B7 !important;
  color: #F8F8F8 !important;
}
::v-deep .select_bg.el-date-editor {
  background-color: transparent !important;
  border-color: #69E5B7 !important;
  .el-range-input {
    color: #F8F8F8 !important;
    background-color: transparent !important;
  }
}

</style>
