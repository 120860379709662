<template>
  <div style="overflow-y: hidden;width: 100%;height: 100%;">
    <div class="padding_box">
      <div ref="map" style="width: 100%;height: 100%;position: fixed;left: 0;top: 0;z-index: 0"></div>
      <top-header @click.native="fullScan" style="color: #70EFBD" title="车辆数据综合看板" />
      <div style="position: fixed;left: 400px;top: 60px;color: #6DE9B9;">当前区域：{{ areaCode.fullname || '广州市' }}</div>
      <div style="position: fixed;right: 380px;top: 60px;color: #6DE9B9;">{{ nowDate }}</div>
      <div class="content_box">
        <!-- 左侧 -->
        <div style="height: 100%;width: calc(25% - 20px);margin-right: 20px;box-sizing: border-box;">
<!--          <qc-select-->
<!--              popper-append-to-body-->
<!--              filterable-->
<!--              style="width: calc(100% - 20px);margin-left: 20px;margin-bottom: 10px;"-->
<!--              @change="(val) => {-->
<!--                 if (val) {-->
<!--                   Object.assign(areaCode, areaCodeList.find(row => row.code === val));-->
<!--                   init();-->
<!--                 }-->
<!--              }"-->
<!--              remote-->
<!--              :remote-method="listArea"-->
<!--              :options="areaCodeList"-->
<!--              placeholder="请输入区域查看车辆分布"-->
<!--              v-model="searchVal"/>-->
          <select-region
              ref="regionRef"
              @change="selectRegionCb"
              style="width: calc(100% - 20px);margin-left: 20px;margin-bottom: 10px;"
              v-model="searchVal"
              filterable
              placeholder="请输入区域查看车辆分布"
          />
          <div style="box-sizing: border-box;margin-top: 10px;margin-left: 20px;width: calc(100% - 21px);height: calc(100% - 50px);">
            <!-- 左边 top -->
            <div ref="leftTopRef" style="position: relative;width: 100%;height: calc(35% - 10px);">
              <canvas ref="leftTopCanvasRef" class="horn"/>
              <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;position: absolute;left: 0;top: 0;">
                <dv-decoration-9 :style="{
                                  width: leftTopStyle.height * 0.7 + 'px',
                                  height: leftTopStyle.height * 0.7 + 'px'
                                }"
                                 v-if="show"
                                 style="z-index: 100">
                  <div style="display: flex;flex-flow: column nowrap;align-items: center;justify-content: center;">
                    <div style="font-size: 22px;font-weight: bolder;">{{ leftCarInfo.totalNum }}</div>
                    <div style="font-size: 8px;">车辆总数</div>
                  </div>
                </dv-decoration-9>
              </div>

              <div style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: 2;box-sizing: border-box;padding: 10px;">
                <div style="height: 16px;overflow-y: hidden;z-index: 1000;justify-content: space-between;font-size: 12px;width: 100%;display: flex;align-items: center;">
                  <div style="display: flex;align-items: center;">
                    <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                    车辆总数据
                  </div>
                </div>
                <div style="margin-top: 15px;width: 100%;display: flex;align-items: center;justify-content: space-between;padding-left: 30px;padding-right: 30px;">
                  <div v-if="leftCarInfo.dataList[0]" style="display: flex;flex-flow: column nowrap;align-items: center">
                    <div style="font-size: 8px;">{{ leftCarInfo.dataList[0].name }}总数</div>
                    <div :style="{ color: leftCarInfo.dataList[0].color }" style="font-size: 18px;font-weight: bolder;">{{ leftCarInfo.dataList[0].num }}</div>
                  </div>
                  <div v-if="leftCarInfo.dataList[1]" style="display: flex;flex-flow: column nowrap;align-items: center">
                    <div style="font-size: 8px;">{{ leftCarInfo.dataList[1].name }}总数</div>
                    <div  :style="{ color: leftCarInfo.dataList[1].color }" style="font-size: 18px;font-weight: bolder;color: #F3A539">{{ leftCarInfo.dataList[1].num }}</div>
                  </div>
                </div>
                <div style="margin-top: 15px;width: 100%;display: flex;align-items: center;justify-content: space-between;padding-left: 10px;padding-right: 10px;">
                  <div v-if="leftCarInfo.dataList[2]" style="display: flex;flex-flow: column nowrap;align-items: center">
                    <div style="font-size: 8px;">{{ leftCarInfo.dataList[2].name }}总数</div>
                    <div  :style="{ color: leftCarInfo.dataList[2].color }" style="font-size: 18px;font-weight: bolder;color: #5ECF90">{{ leftCarInfo.dataList[2].num }}</div>
                  </div>
                  <div v-if="leftCarInfo.dataList[3]" style="display: flex;flex-flow: column nowrap;align-items: center">
                    <div style="font-size: 8px;">{{ leftCarInfo.dataList[3].name }}总数</div>
                    <div  :style="{ color: leftCarInfo.dataList[3].color }" style="font-size: 18px;font-weight: bolder;color: #F3A539">{{ leftCarInfo.dataList[3].num }}</div>
                  </div>
                </div>
                <div style="margin-top: 15px;width: 100%;display: flex;align-items: center;justify-content: space-between;padding-left: 10px;padding-right: 10px;">
                  <div v-if="leftCarInfo.dataList[4]" style="display: flex;flex-flow: column nowrap;align-items: center">
                    <div style="font-size: 8px;">{{ leftCarInfo.dataList[4].name }}总数</div>
                    <div :style="{ color: leftCarInfo.dataList[4].color }" style="font-size: 18px;font-weight: bolder;color: #5ECF90">{{ leftCarInfo.dataList[4].num }}</div>
                  </div>
                  <div v-if="leftCarInfo.dataList[5]" style="display: flex;flex-flow: column nowrap;align-items: center">
                    <div style="font-size: 8px;">{{ leftCarInfo.dataList[5].name }}总数</div>
                    <div :style="{ color: leftCarInfo.dataList[5].color }" style="font-size: 18px;font-weight: bolder;color: #F3A539">{{ leftCarInfo.dataList[5].num }}</div>
                  </div>
                </div>
                <dv-decoration-4 :reverse="true" style="width:90%;height:5px;position: absolute;bottom: 10px;left: 5%;" />
              </div>
            </div>
            <!-- 左边 middle -->
            <div ref="leftMiddleRef" style="position: relative;width: 100%;height: 30%;margin-top: 10px;">
              <canvas ref="leftMiddleCanvasRef" class="horn"/>
              <div style="box-sizing: border-box;padding: 10px;z-index: 2;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">
                <div style="height: 16px;justify-content: space-between;font-size: 12px;width: 100%;display: flex;align-items: center;">
                  <div style="display: flex;align-items: center;">
                    <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                    车辆接入情况
                  </div>
                  <select-date style="width: calc(100% - 100px)"
                               @date-change="$nextTick(initCarAccessEcharts)"
                               :date-type.sync="carAccessEchartsParam.type"
                               :date.sync="carAccessEchartsParam.date" />
                </div>
                <div style="height: calc(100% - 16px);width: 100%;">
                  <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;" ref="carAccessEcharts"/>
                </div>
              </div>
            </div>
            <!-- 左边 bottom -->
            <div ref="leftBottomRef" style="z-index: 0;position: relative;width: 100%;height: calc(35% - 10px);margin-top: 10px;">
              <canvas ref="leftBottomCanvasRef" class="horn"/>
              <div style="box-sizing: border-box;padding: 10px;z-index: 2;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">
                <div style="height: 16px;overflow-y: hidden;justify-content: space-between;font-size: 12px;width: 100%;display: flex;align-items: center;">
                  <div style="display: flex;align-items: center;">
                    <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                    合作企业接入情况
                  </div>
                </div>
                <div style="margin-top: 10px;width: 100%;display: flex;align-items: center;justify-content: center;">
                  <div style="width: 33.333%;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                    <div style="color: white;font-size: 10px">品牌企业</div>
                    <div style="font-weight: bolder;font-size: 22px;color: #FFA100">{{ companyInfo.brandNum }}</div>
                  </div>
                  <div style="width: 33.333%;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                    <div style="color: white;font-size: 10px">整车企业</div>
                    <div style="font-weight: bolder;font-size: 22px;color: #FFA100">{{ companyInfo.factoryNum }}</div>
                  </div>
                  <div style="width: 33.333%;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                    <div style="color: white;font-size: 10px">接入车辆型号</div>
                    <div style="font-weight: bolder;font-size: 22px;color: #FFA100">{{ companyInfo.carNum }}</div>
                  </div>
                </div>
                <div style="margin-top: 10px;padding: 10px;box-sizing: border-box;box-shadow: 0 0 10px #1D433D inset;border-radius: 10px;width: 100%;">
                  <div style="font-size: 12px;">合作企业</div>
                  <div style="padding: 5px;margin-top: 10px;width: 100%;display: flex;align-items: center;flex-flow: row wrap;">
                    <img v-for="(item, index) of companyInfo.brands"
                         :key="index + 'img'"
                         style="margin-right: 5px;margin-bottom: 5px;width: 30px;height: 30px;border-radius: 4px;"
                         :src="item.logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 中间 -->
        <div style="pointer-events: none;height: calc(100% - 12px);position: relative;box-sizing: border-box;padding-right: 15px;width: 50%;">
          <div style="position: absolute;left: 0;top: 0;width: 100%;display: flex;align-items: center;justify-content: space-around;">
            <div style="background-color:#183532;width: 20%;padding-top: 10px;padding-bottom: 10px;padding-right: 20px;padding-left: 20px;border-radius: 5px;border: 1px solid #5DCA8D;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
              <div style="font-weight: bolder;display: flex;align-items: flex-end;font-size: 25px;color: #FFA100">
                {{ vehicleTotalDataInfo.totalMileage }}<span style="font-size: 8px">km</span>
              </div>
              <div style="font-size: 10px;color: #D5D6D6;">总里程</div>
            </div>
            <div style="background-color:#183532;width: 20%;padding-top: 10px;padding-bottom: 10px;padding-right: 20px;padding-left: 20px;border-radius: 5px;border: 1px solid #5DCA8D;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
              <div style="font-weight: bolder;display: flex;align-items: flex-end;font-size: 25px;color: #FFA100">
                {{ vehicleTotalDataInfo.totalCo2Num }}<span style="font-size: 8px">kg</span>
              </div>
              <div style="font-size: 10px;color: #D5D6D6;">减碳</div>
            </div>
            <div style="background-color:#183532;width: 20%;padding-top: 10px;padding-bottom: 10px;padding-right: 20px;padding-left: 20px;border-radius: 5px;border: 1px solid #5DCA8D;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
              <div style="font-weight: bolder;display: flex;align-items: flex-end;font-size: 25px;color: #FFA100">
                {{ vehicleTotalDataInfo.hydrogenNum }}
              </div>
              <div style="font-size: 10px;color: #D5D6D6;">加氢次数</div>
            </div>
            <div style="background-color:#183532;width: 20%;padding-top: 10px;padding-bottom: 10px;padding-right: 20px;padding-left: 20px;border-radius: 5px;border: 1px solid #5DCA8D;display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
              <div style="font-weight: bolder;display: flex;align-items: flex-end;font-size: 25px;color: #FFA100">
                {{ vehicleTotalDataInfo.totalHydrogenWeight }}<span style="font-size: 8px">kg</span>
              </div>
              <div style="font-size: 10px;color: #D5D6D6;">总加氢量</div>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div style="height: 100%;width: calc(25% - 20px);margin-right: 20px;">
          <!-- 左边 top -->
          <div ref="rightTopRef" style="position: relative;width: 100%;height: calc(30% - 10px);">
            <canvas ref="rightTopCanvasRef" class="horn"/>
            <div style="box-sizing: border-box;padding: 10px;z-index: 2;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">
              <div style="height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;justify-content: space-between">
                <div style="display: flex;align-items: center">
                  <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                  本区车辆分布
                </div>
                <span style="font-size: 8px;color: #CB8518">{{ areaCode.name }}</span>
              </div>
              <div style="width: 100%;height: calc(100% - 16px);" ref="areaEcharts"/>
            </div>
          </div>
          <!-- 左边 top -->
          <div ref="rightMiddleRef" style="position: relative;width: 100%;height: calc(70% - 10px);margin-top: 10px;">
            <canvas ref="rightMiddleCanvasRef" class="horn"/>
            <div style="box-sizing: border-box;padding: 10px;z-index: 2;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">
              <qc-select
                  popper-append-to-body
                  filterable
                  style="width: 100%;"
                  @change="(val) => {
                     if (val) {
                       $nextTick(getVehicle);
                     }
                  }"
                  remote
                  :remote-method="listPlateNumber"
                  :options="plateNumberList"
                  placeholder="请输入车牌号"
                  v-model="searchCarVal"/>
              <div style="border-bottom: 1px solid #4AA187;height: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 10px;padding-right: 10px;">
                <span style="font-size: 10px;color: #56B07F">车牌</span>
                <span style="font-size: 12px;">{{ vehicleInfo.plateNumber }}</span>
              </div>
              <div style="border-bottom: 1px solid #4AA187;height: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 10px;padding-right: 10px;">
                <span style="font-size: 10px;color: #56B07F">车架号</span>
                <span style="font-size: 12px;">{{ vehicleInfo.idCode }}</span>
              </div>
              <div style="border-bottom: 1px solid #4AA187;height: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 10px;padding-right: 10px;">
                <span style="font-size: 10px;color: #56B07F">品牌型号</span>
                <span v-if="!isBlank(vehicleInfo.brandName)" style="font-size: 12px;">{{ vehicleInfo.brandName + vehicleInfo.vehicleCarName }}</span>
              </div>
              <div style="border-bottom: 1px solid #4AA187;height: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 10px;padding-right: 10px;">
                <span style="font-size: 10px;color: #56B07F">本日里程数</span>
                <span v-if="!isBlank(vehicleInfo.todayMileage)" style="font-size: 12px;">{{ vehicleInfo.todayMileage }}km</span>
              </div>
              <div style="border-bottom: 1px solid #4AA187;height: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 10px;padding-right: 10px;">
                <span style="font-size: 10px;color: #56B07F">累计总里程数</span>
                <span v-if="!isBlank(vehicleInfo.driveDistance)" style="font-size: 12px;">{{ vehicleInfo.driveDistance }}km</span>
              </div>
              <div style="border-bottom: 1px solid #4AA187;height: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 10px;padding-right: 10px;">
                <span style="font-size: 10px;color: #56B07F">减碳</span>
                <span v-if="!isBlank(vehicleInfo.co2Num)" style="font-size: 12px;">{{ vehicleInfo.co2Num }}kg</span>
              </div>
              <div style="border-bottom: 1px solid #4AA187;height: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 10px;padding-right: 10px;">
                <span style="font-size: 10px;color: #56B07F">总加氢量</span>
                <span v-if="!isBlank(vehicleInfo.totalHydrogenWeight)" style="font-size: 12px;">{{ vehicleInfo.totalHydrogenWeight }}kg</span>
              </div>
              <div style="border-bottom: 1px solid #4AA187;height: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 10px;padding-right: 10px;">
                <span style="font-size: 10px;color: #56B07F">在线状态</span>
                <span v-if="!isBlank(vehicleInfo.vehicleStatus)" style="font-size: 12px;">{{ vehicleInfo.vehicleStatus === '1' ? '在线': '离线' }}</span>
              </div>
              <div style="border-bottom: 1px solid #4AA187;height: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 10px;padding-right: 10px;">
                <span style="font-size: 10px;color: #56B07F">车辆状态</span>
                <span style="font-size: 12px;">{{ vehicleInfo.vehicleStatusName }}</span>
              </div>
              <div style="border-bottom: 1px solid #4AA187;height: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 10px;padding-right: 10px;">
                <span style="font-size: 10px;color: #56B07F">所在经纬度</span>
                <span v-if="!isBlank(vehicleInfo.lastLocationLat)" style="font-size: 12px;">{{ vehicleInfo.lastLocationLat }},{{ vehicleInfo.lastLocationLng }}</span>
              </div>
              <div style="border-bottom: 1px solid #4AA187;height: 30px;width: 100%;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding-left: 10px;padding-right: 10px;">
                <span style="font-size: 10px;color: #56B07F">所在地址</span>
                <span style="font-size: 12px;">
                  {{ vehicleInfo.lastLocationAddress }}
                </span>
              </div>
              <div style="margin-top: 15px;display: flex;align-items: center;justify-content: space-around">
                <el-button :disabled="isBlank(vehicleInfo.plateNumber)"
                           style="width: 45%;background-color: #224C46;color: #68E0B9"
                           @click="xiangQin(vehicleInfo.plateNumber)"
                           round>
                  详情
                </el-button>
                <el-button :disabled="isBlank(vehicleInfo.plateNumber)"
                           @click="guiJi(vehicleInfo.plateNumber)"
                           style="width: 45%;background-color: #224C46;color: #68E0B9"
                           round>
                  轨迹
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import dayjs from 'dayjs'
import { getVehicle, getVehicleTotalData, countCompany, getVehicleTypeTotal, listVehicleAddData, listVehicle} from '@/api/vehicle'
import isBlank from "@/utils/isBlank";
import { listArea, listPlateNumber } from '@/api/common'

const endTime = dayjs(new Date()).format('YYYY')
const startTime = dayjs(new Date()).subtract(5, 'year').format('YYYY')

function getFormatTypeDate(type, val) {
  switch (type) {
    case '3': // day
      return {
        startTime: isBlank(val) ? null : dayjs(val).format('YYYYMMDD'),
        endTime: null
      }
    case '2': // moon
      return {
        startTime: dayjs(val[0]).format('YYYYMM'),
        endTime: dayjs(val[1]).format('YYYYMM'),
      }
    case '1': // year
      return {
        startTime: dayjs(val[0]).format('YYYY'),
        endTime: dayjs(val[1]).format('YYYY'),
      }
  }
}

let intervalRes
let intervalDate
let map
export default {
  name: "vehicle-data",
  data() {
    const vehicleInfo = this.defaultVehicleInfo()
    return {
      searchVal: null,
      searchCarVal: null,
      show: false,
      nowDate: dayjs().format('YYYY年MM月DD日 HH:mm:ss'),
      leftTopStyle: {
        width: 100,
        height: 100
      },

      carAccessEchartsParam: {
        type: '1',
        date: [startTime, endTime]
      },

      areaEcharts: null,
      leftCarInfo: {
        totalNum: 0, // 车辆总数
        dataList: []
      },
      rightCarInfo: {
        totalNum: 0, // 车辆总数
        dataList: []
      },
      companyInfo: {
        brandNum: 0,
        carNum: 0,
        factoryNum: 0,
        brands: []
      },
      vehicleTotalDataInfo: {
        hydrogenNum: 0,
        totalCo2Num: 0,
        totalHydrogenWeight: 0,
        totalMileage: 0
      },
      vehicleInfo,
      areaCode: {
        code: null,
        name: null,
        fullname: null
      },
      areaCodeList: [],

      plateNumberList: [],
    }
  },
  created() {
    document.title = '车辆数据综合看板'
    intervalDate = setInterval(() => {
      this.nowDate = dayjs().format('YYYY年MM月DD日 HH:mm:ss')
    }, 1000)
    window.addEventListener('resize', this.reLoad)
    intervalRes = setInterval(() => {
      this.init()
    }, 60_000)
  },
  async mounted() {
    await this.listArea('广州')
    this.init()
    this.leftTopStyle.width = this.$refs.leftTopRef.offsetWidth
    this.leftTopStyle.height = this.$refs.leftTopRef.offsetHeight
    this.$nextTick(() => {
      this.show = true
    })
  },
  beforeDestroy() {
    this.clearEcharts()
    if (intervalRes) {
      clearInterval(intervalRes)
    }
    if (intervalDate) {
      clearInterval(intervalDate)
    }
    window.removeEventListener('resize', this.reLoad)
  },
  methods: {
    xiangQin(plateNumber) {
      this.$router.push({
        path: '/emergency',
        query: {
          plateNumber
        }
      })
    },
    guiJi(plateNumber) {
      this.$router.push({
        path: '/safety-supervision',
        query: {
          plateNumber
        }
      })
    },
    isBlank,
    fullScan() {
      if (screenfull.isFullscreen) {
        screenfull.exit()
      } else {
        screenfull.request()
      }
    },
    clearEcharts() {

    },
    reLoad() {
      this.clearEcharts()
      this.$nextTick(() => {
        setTimeout(this.init, 100)
      })
    },
    async init() {
      this.getVehicleTypeTotal()
      this.countCompany()
      this.getVehicleTotalData()

      this.initCarAccessEcharts()
      this.drawLeftTopBoxBorder()
      this.drawLeftMiddleBoxBorder()
      this.drawLeftBottomBoxBorder()
      this.drawRightTopBoxBorder()
      this.drawRightMiddleBoxBorder()
      this.initAreaEcharts()
      this.initMap()
      this.listPlateNumber()
    },
    drawLeftTopBoxBorder() {
      const height = this.$refs.leftTopRef.offsetHeight
      const width = this.$refs.leftTopRef.offsetWidth
      // hornRef
      let canvas = this.$refs.leftTopCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      context.moveTo(0,0);
      context.lineTo(0, height);

      context.moveTo(0, height);
      context.lineTo(width, height);

      // 右边线段
      context.moveTo(width, height);
      context.lineTo(width, 30);

      // 顶部线段
      context.moveTo(0, 0);
      context.lineTo(width - 30, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(width - 30, 0);
      context.lineTo(width, 30);
      context.stroke();
    },
    drawLeftMiddleBoxBorder() {
      const height = this.$refs.leftMiddleRef.offsetHeight
      const width = this.$refs.leftMiddleRef.offsetWidth
      let canvas = this.$refs.leftMiddleCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      context.moveTo(0,0);
      context.lineTo(0, height);

      context.moveTo(0, height);
      context.lineTo(width, height);

      // 右边线段
      context.moveTo(width, height);
      context.lineTo(width, 0);

      // 顶部线段
      context.moveTo(0, 0);
      context.lineTo(width , 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(width, 0);
      context.lineTo(width, 0);
      context.stroke();
    },
    drawLeftBottomBoxBorder() {
      const height = this.$refs.leftBottomRef.offsetHeight
      const width = this.$refs.leftBottomRef.offsetWidth
      // hornRef
      let canvas = this.$refs.leftBottomCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      // left
      context.moveTo(0,0);
      context.lineTo(0, height);

      // bottom
      context.moveTo(0, height);
      context.lineTo(width - 30, height);

      // right
      context.moveTo(width, 0);
      context.lineTo(width, height - 30);

      // top
      context.moveTo(0, 0);
      context.lineTo(width , 0);
      context.stroke();

      context.lineWidth = 0.8
      context.moveTo(width - 30, height);
      context.lineTo(width, height - 30);
      context.stroke();
    },
    drawRightTopBoxBorder() {
      const height = this.$refs.rightTopRef.offsetHeight
      const width = this.$refs.rightTopRef.offsetWidth
      // hornRef
      let canvas = this.$refs.rightTopCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      // left
      context.moveTo(0,height);
      context.lineTo(0, 10);

      // bottom
      context.moveTo(0, height);
      context.lineTo(width, height);

      // right
      context.moveTo(width, height);
      context.lineTo(width, 0);

      // top
      context.moveTo(width, 0);
      context.lineTo(10, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(10, 0);
      context.lineTo(0, 10);
      context.stroke();
    },
    drawRightMiddleBoxBorder() {
      const height = this.$refs.rightMiddleRef.offsetHeight
      const width = this.$refs.rightMiddleRef.offsetWidth
      // hornRef
      let canvas = this.$refs.rightMiddleCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      // left
      context.moveTo(0,0);
      context.lineTo(0, height - 30);

      // bottom
      context.moveTo(width, height);
      context.lineTo(30, height);

      // right
      context.moveTo(width, height);
      context.lineTo(width, 0);

      // top
      context.moveTo(width, 0);
      context.lineTo(0, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(0, height - 30);
      context.lineTo(30, height);
      context.stroke();
    },

    async initCarAccessEcharts() {
      if (!this.carAccessEcharts) {
        this.carAccessEcharts = this.$echarts.init(this.$refs.carAccessEcharts);
      }

      const res = await this.$request(listVehicleAddData(Object.assign({}, this.carAccessEchartsParam, {
        ...getFormatTypeDate(this.carAccessEchartsParam.type, this.carAccessEchartsParam.date),
      }), {
        areaType: this.areaCode.level,
        areaCode: this.areaCode.code
      }))

      this.carAccessEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '3%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        series: [
          {
            data: res.map(row => Number(row.num)),
            type: 'bar'
          }
        ]
      })
    },
    async initAreaEcharts() {
      await this.getAreaVehicleTypeTotal()
      if (!this.areaEcharts) {
        this.areaEcharts = this.$echarts.init(this.$refs.areaEcharts);
      }
      this.areaEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        title: {
          show: true,
          textVerticalAlign: 'center',
          textAlign: 'center',
          text:  this.rightCarInfo.totalNum + '\n本区车辆',
          left: '49%',
          textStyle: {
            color: '#fff',
            fontSize: 14,
          },
          top: '48%',
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'outside',
              formatter: '{b} {d}%'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 15,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: this.rightCarInfo.dataList.map(row => {
              return {
                value: row.num,
                name: row.name,
                label: {
                  formatter: [
                    '{name|{b}} {per|{d}%}',
                  ].join('\n'),
                  rich: {
                    name: {
                      fontSize: 12,
                    },
                    per: {
                      color: '#fff',
                      fontSize: 12,
                    }
                  }
                }
              }
            })
          }
        ]
      })
    },
    async initMap() {
      const res = await this.$request(listVehicle({
        areaType: this.areaCode.level,
        areaCode: this.areaCode.code
      }))
      const location = res.filter(row => {
        return !isBlank(row.lastLocationLat) && !isBlank(row.lastLocationLng)
      })
      let latCenter = this.areaCode.lat
      let lngCenter = this.areaCode.lng

      if (location.length !== 0) {
        latCenter = location.reduce((total, row) => total + Number(row.lastLocationLat),0) / location.length
        lngCenter = location.reduce((total, row) => total + Number(row.lastLocationLng),0) / location.length
      }

      const center = new this.$TMap.LatLng(latCenter, lngCenter);
      if (map) {
        map.destroy()
      }

      map = new this.$TMap.Map(this.$refs.map, {
        zoom: 10,
        center,
        mapStyleId: 'style1',
      });

      // 匹配这里的字段carTypeName
      for (let carTypeRow of this.leftCarInfo.dataList) {
        const filters = res.filter(row => row.carTypeName === carTypeRow.name)
        if (filters.length !== 0) {
          new this.$TMap.MultiMarker({
            map,
            //样式定义
            styles: {
              //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
              "myStyle": new this.$TMap.MarkerStyle({
                "width": 35,  // 点标记样式宽度（像素）
                "height": 35, // 点标记样式高度（像素）
                "src": require('./img/'+ carTypeRow.colorName +'.png'),  //图片路径
                //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                "anchor": { x: 16, y: 32 }
              })
            },
            //点标记数据数组
            geometries: filters.map(row => {
              return {
                "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                "styleId": 'myStyle',  //指定样式id
                "position": new this.$TMap.LatLng(row.lastLocationLat, row.lastLocationLng),  //点标记坐标位置
                "properties": { //自定义属性
                  "title": "marker1"
                }
              }
            })
          })
        }
      }
    },

    // 获取左上角的，【车辆总数据】
    async getVehicleTypeTotal() {
      const res = await this.$request(getVehicleTypeTotal({
        areaType: this.areaCode.level,
        areaCode: this.areaCode.code
      }))
      const colors = ['#5ECF90', '#F3A539', '#C153E7', '#45A3F8', '#F56C6C', '#2db7f5']
      let getIndex = 0;
      res.dataList = res.dataList.map(row => {
        row.color = colors[getIndex]
        row.colorName = colors[getIndex].replace('#', '')
        getIndex++
        return row
      })
      Object.assign(this.leftCarInfo, res)
    },
    // 获取右上角 【本区域车辆分布】
    async getAreaVehicleTypeTotal() {
      const res = await this.$request(getVehicleTypeTotal({
        areaType: this.areaCode.level,
        areaCode: this.areaCode.code
      }))
      Object.assign(this.rightCarInfo, res)
    },
    // 品牌接入情况
    async countCompany() {
      const res = await this.$request(countCompany())
      res.brands = res.brands.filter(row => row.logo)
      Object.assign(this.companyInfo, res)
    },
    // 获取中间的，顶部数据
    async getVehicleTotalData() {
      const res = await this.$request(getVehicleTotalData({
        areaType: this.areaCode.level,
        areaCode: this.areaCode.code
      }))
      Object.assign(this.vehicleTotalDataInfo, res)
    },
    // 右侧，按照车辆进行查询
    async getVehicle() {
      const res = await this.$request(getVehicle({
        plateNumber: this.searchCarVal
      }))
      if (!res) {
        this.$message.success("未查询到相关数据")
      } else {
        Object.assign(this.vehicleInfo, res || {})
      }
    },
    defaultVehicleInfo() {
      return {
        brandId: null,
        brandName: null,
        carType: null,
        co2Num: null,
        driveDistance: null,
        idCode: null,
        lastLocationAddress: null,
        lastLocationArea: null,
        lastLocationCity: null,
        lastLocationLat: null,
        lastLocationLng: null,
        lastLocationProvince: null,
        lastLocationTime: null,
        plateNumber: null,
        todayMileage: null,
        totalHydrogenWeight: null,
        vehicleCarCode: null,
        vehicleCarId: null,
        vehicleCarName: null,
        vehicleStatus: null,
        vehicleStatusName: null
      }
    },
    async listArea(name) {
      const res = await this.$request(listArea({
        name,
        limit: 1
      }))
      this.areaCodeList = res.map(row => {
        return Object.assign({}, row, {
          label: row.fullname,
          value: row.code
        })
      })
      if (res.length !== 0) {
        this.areaCode = res[0]
      } else {
        this.$message.warning("未查询到相关区域")
        throw new Error('未查询到相关区域')
      }
    },

    // 获取车牌列表
    async listPlateNumber(plateNumber) {
      const res = await this.$request(listPlateNumber({
        limit: 20,
        plateNumber
      }))
      this.plateNumberList = res.map(row => {
        return {
          label: row,
          value: row
        }
      })
    },
    // 选择后的回调
    selectRegionCb(arr) {
      let array = this.$refs.regionRef.regionTree
      let res = null
      arr.forEach(id => {
        res = array.find(row => row.id === id)
        array = res.children
      })

      if (res === null) {
        this.areaCode.name = null
        this.areaCode.code = null
        this.areaCode.fullname = null
      } else {
        Object.assign(this.areaCode, res);
      }
      this.init();
    }
  }
}
</script>

<style lang="stylus" scoped>
.padding_box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: white;
}
.content_box {
  width: 100%;
  display: flex;
  height: calc(100% - 100px);
  align-items: flex-start;
}
.input_search {
  height: 100%;width: 100%;border: none;background-color: transparent;
  color: white;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 40px;
}
.input_search:focus {
  border: none !important;
  outline: none;
}
.search_icon {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}
.horn {
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 2;
  background-color: #282c34;
}
</style>
