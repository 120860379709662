<template>
  <div style="width: 100%;display: flex;align-items: center;justify-content: flex-end;">
    <select-date-type v-model="dateType_"/>
    <!-- 按日期查询 -->
    <el-date-picker
        v-if="dateType_ === '3'"
        v-model="date_"
        :clearable="false"
        type="date"
        @change="$nextTick(() => { $emit('date-change') })"
        style="margin-left: 10px;width: 60px;"
        placeholder="选择日期">
    </el-date-picker>
    <!-- 按月区间查询 -->
    <el-date-picker
        :clearable="false"
        v-if="dateType_ === '2'"
        v-model="date_"
        @change="$nextTick(() => { $emit('date-change') })"
        type="monthrange"
        style="width: 140px !important;margin-left: 10px"
        range-separator="-"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
    />
    <!-- 按年 -->
    <date-picker-year-range
        :left="left"
        :right="right"
        :top="top"
        :bottom="bottom"
        style="margin-left: 10px"
        v-if="dateType_ === '1'"
        @change="$nextTick(() => { $emit('date-change') })"
        v-model="date_" />
  </div>
</template>

<script>
import DatePickerYearRange from './date-picker-year-range.vue'
import dayjs from 'dayjs'

export default {
  name: "SelectDate",
  props: {
    right: {
      type: Number,
      default: () => 0
    },
    left: {
      type: Number,
      default: () => -50
    },
    top: {
      type: Number,
      default: () => 40
    },
    bottom: {
      type: Number,
      default: () => 0
    },
    /**
     * 日期类型
     */
    dateType: {
      type: String,
      default: () => '1'
    },
    /**
     * 日期值
     */
    date: {
      type: [Date, Array],
      default: () => new Date()
    },
  },
  components: {
    DatePickerYearRange,
  },
  computed: {
    dateType_: {
      get() {
        return this.dateType
      },
      set(val) {
        this.$emit('update:dateType', val)
      }
    },
    date_: {
      get() {
        return this.date
      },
      set(val) {
        this.$emit('update:date', val)
      }
    }
  },
  watch: {
    dateType_(val) {
      const endTime = new Date()
      let startTime = null
      if (Number(val) === 1) { // 按年区间查询
        startTime = dayjs(endTime).subtract(5, 'year').toDate()
        this.$emit('update:date', [dayjs(startTime).format('YYYY'), dayjs(endTime).format('YYYY')])
      } else if (Number(val) === 2) { // 按月区间查询
        startTime = dayjs(endTime).subtract(5, 'month').toDate()
        this.$emit('update:date', [startTime, endTime])
      } else if (Number(val) === 3) { // 按日期查询
        startTime = dayjs(endTime).subtract(30, 'day').toDate()
        this.$emit('update:date', startTime)
      }
      this.$nextTick(() => {
        this.$emit('date-change')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__prefix {
  display: none;
}
::v-deep .el-input__inner {
  height: 15px !important;
  line-height: 15px !important;
  background-color: transparent !important;
  box-sizing: border-box;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 8px !important;
  color: white !important;
  border: 1px solid #69E5B7 !important;
}
::v-deep .el-range-input {
  font-size: 8px !important;
  color: white !important;
  background-color: transparent !important;
  height: 15px !important;
  line-height: 15px !important;
}
::v-deep .el-range__icon {
  display: none;
}
::v-deep .el-range-separator {
  color: white !important;
  font-size: 8px !important;
  line-height: 8px !important;
}
::v-deep .el-range__close-icon {
  display: none !important;
}
</style>
