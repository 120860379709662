<template>
  <div ref="yearPicker" class="yearPicker">
    <input
        ref="inputLeft"
        v-model="startShowYear"
        class="_inner"
        type="text"
        name="yearInput"
        placeholder=""
        @focus="onFocus"
        @blur="onBlur"
        @keyup="checkStartInput($event)"
    >
    <span style="color: white">{{ sp }}</span>
    <input
        ref="inputRight"
        v-model="endShowYear"
        class="_inner"
        type="text"
        name="yearInput"
        placeholder=""
        @focus="onFocus"
        @blur="onBlur"
        @keyup="checkEndInput($event)"
    >

    <!-- 下拉面板 -->
    <div v-if="showPanel" :style="{
      left: left === 0 ? 'unset' : (left + 'px'),
      right: right === 0 ? 'unset' : (right + 'px'),
      top: top === 0 ? 'unset' : (top + 'px'),
      bottom: bottom === 0 ? 'unset' : (bottom + 'px'),
    }" class="_inner floatPanel">
      <!-- 左边 -->
      <div class="_inner leftPanel">
        <div class="_inner panelHead">
          <i
              class="_inner el-icon-d-arrow-left"
              @click="onClickLeft"
          />
          {{ leftYearList[0] + "-" + leftYearList[9] }}
        </div>
        <div class="_inner panelContent">
          <div
              v-for="item in leftYearList"
              :key="item"
              :class="{
              oneSelected: item === startYear && oneSelected,
              startSelected: item === startYear,
              endSelected: item === endYear,
              betweenSelected: item > startYear && item < endYear,
            }"
          >
            <a
                :class="{
                cell: true,
                _inner: true,
                selected: item === startYear || item === endYear,
                disabled:Number(item) > Number(newTime) || Number(item) < (Number(curSelectedYear) - 9) || Number(item) > (Number(curSelectedYear) + 9) && curSelectedYear
              }"
                @click="onClickItem(item)"
                @mouseover="onHoverItem(item)"
            >
              {{ item }}
            </a>
          </div>
        </div>
      </div>
      <!-- 右边 -->
      <div class="_inner rightPanel">
        <div class="_inner panelHead">
          <i
              class="_inner el-icon-d-arrow-right"
              @click="onClickRight"
          />
          {{ rightYearList[0] + "-" + rightYearList[9] }}
        </div>
        <div class="_inner panelContent">
          <div
              v-for="item in rightYearList"
              :key="item"
              :class="{
              oneSelected: item === startYear && oneSelected,
              startSelected: item === startYear,
              endSelected: item === endYear,
              betweenSelected: item > startYear && item < endYear,
            }"
          >
            <a
                :class="{
                cell: true,
                _inner: true,
                selected: item === endYear || item === startYear,
                disabled:Number(item) > Number(newTime) || Number(item) < (Number(curSelectedYear) - 9) || Number(item) > (Number(curSelectedYear) + 9) && curSelectedYear
              }"
                @click="onClickItem(item)"
                @mouseover="onHoverItem(item)"
            >
              {{ item }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
const SELECT_STATE = {
  unselect: 0,
  selecting: 1,
  selected: 2
}
export default {
  name: 'DatePickerYearRange',
  model: {
    prop: 'date',
    event: 'change'
  },
  props: {
    right: {
      type: Number,
      default: () => 0
    },
    left: {
      type: Number,
      default: () => -50
    },
    top: {
      type: Number,
      default: () => 40
    },
    bottom: {
      type: Number,
      default: () => 0
    },
    sp: {
      default: '-'
    },
    startYearTime: {
      default: 0
    },
    endTYearTime: {
      default: 0
    },
    date: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      itemBg: {},
      startShowYear: null,
      endShowYear: null,
      yearList: [],
      showPanel: false,
      startYear: null,
      endYear: null,
      curYear: 0,
      curSelectedYear: 0,
      curState: SELECT_STATE.unselect,
      isdisabled: true,
      newTime: 0// 获取当年年份
    }
  },
  computed: {
    oneSelected() {
      return (
          this.curState === SELECT_STATE.selecting &&
          (this.startYear === this.endYear || this.endYear == null)
      )
    },
    startDate() {
      return this.startYear
    },
    leftYearList() {
      return this.yearList.slice(0, 10)
    },
    rightYearList() {
      return this.yearList.slice(10, 20)
    },
    startYearFormat() {
      if (this._.isNil(this.startYear)) {
        return null
      }
      return moment(this.startYear).startOf('year').format('yyyy')
    },
    endYearFormat() {
      if (this._.isNil(this.endYear)) {
        return null
      }
      return moment(this.endYear).endOf('year').format('yyyy')
    }
  },

  created() {
    this.curYear = moment().format('yyyy')
    this.updateYearList()
    const timestamp = Date.parse(new Date())
    const date = new Date(timestamp)
    this.newTime = date.getFullYear()

    if (this.date && this.date.length !== 0) {
      this.startShowYear = this.date[0]
      this.endShowYear = this.date[1]

      this.startYear = this.date[0]
      this.endYear = this.date[1]
    } else {
      this.startShowYear = null
      this.endShowYear = null

      this.startYear = null
      this.endYear = null
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closePanel.bind(this))
  },
  mounted() {
    document.addEventListener('click', this.closePanel.bind(this))
  },
  methods: {
    checkStartInput() {
      if (isNaN(this.startShowYear)) {
        this.startShowYear = this.startYear
      } else {
        this.startYear = this.startShowYear * 1
        this.changeYear()
      }
    },
    checkEndInput() {
      if (isNaN(this.endShowYear)) {
        this.endShowYear = this.endYear
      } else {
        this.endYear = this.endShowYear * 1
        this.changeYear()
      }
    },
    changeYear() {
      if (this.startYear > this.endYear) {
        const tmp = this.endYear
        this.endYear = this.startYear
        this.startYear = tmp
        this.startShowYear = this.startYear
        this.endShowYear = this.endYear
      }
      if (this.startYear && this.endYear) {
        this.$emit('change', [
          moment(this.startYear + '').startOf('year').valueOf(),
          moment(this.endYear + '').endOf('year').valueOf() + 1
        ])
      } else {
        console.warn('WARN:年份不合法', this.startYear, this.endYear)
      }
    },

    // 鼠标移入
    onHoverItem(iYear) {
      if (this.curState === SELECT_STATE.selecting && Number(iYear) < Number(this.newTime) + 1 && Number(iYear) > (Number(this.curSelectedYear) - 10) && Number(iYear) < (Number(this.curSelectedYear) + 10)) {
        const tmpStart = this.curSelectedYear
        this.endYear = Math.max(tmpStart, iYear)
        this.startYear = Math.min(tmpStart, iYear)
      }
    },
    // 点击选中
    onClickItem(iYear) {
      if (
          (this.curState === SELECT_STATE.unselect ||
              this.curState === SELECT_STATE.selected) &&
          Number(iYear) < Number(this.newTime) + 1
      ) {
        this.startYear = iYear
        this.curSelectedYear = iYear
        this.endYear = null
        this.curState = SELECT_STATE.selecting
      } else if (this.curState === SELECT_STATE.selecting && this.endYear && Number(iYear) < Number(this.newTime) + 1 && Number(iYear) > (Number(this.curSelectedYear) - 10) && Number(iYear) < (Number(this.curSelectedYear) + 10)) {
        this.endShowYear = this.endYear
        this.startShowYear = this.startYear
        this.curState = SELECT_STATE.selected
        this.curSelectedYear = 0

        this.$emit('change', [
          moment(this.startYear + '').startOf('year').valueOf(),
          moment(this.endYear + '').endOf('year').valueOf() + 1
        ])
        setTimeout(() => {
          // 为动画留的时间，可优化
          this.showPanel = false
        }, 0)
      }
    },
    onFocus() {
      this.$nextTick(() => {
        this.showPanel = true
      })
    },
    onBlur() {
      //   this.showPanel = false;
    },
    updateYearList() {
      let iStart = Math.floor(this.curYear / 10) * 10 - 10
      iStart = iStart < 0 ? 0 : iStart
      this.yearList = []
      for (let index = 0; index < 20; index++) {
        this.yearList.push(iStart + index)
      }
    },
    closePanel(e) {
      if (!this.showPanel) {
        return
      }
      if (typeof e.target.className !== 'string') {
        this.$nextTick(() => {
          this.showPanel = false
        })
        return
      }
      if (
          e.target.className.indexOf('_inner') === -1 ||
          (e.target.name === 'yearInput' &&
              e.target !== this.$refs.inputLeft &&
              e.target !== this.$refs.inputRight)
      ) {
        this.$nextTick(() => {
          this.showPanel = false
        })
      }

      e.stopPropagation()
      return false
    },
    onClickLeft() {
      this.curYear = this.curYear * 1 - 10
      this.updateYearList()
    },
    onClickRight() {
      this.curYear = this.curYear * 1 + 10
      this.updateYearList()
    },

    /**
     * 清空日期
     */
    clearYearDate() {
      this.startShowYear = null
      this.endShowYear = null
    }

  }
}
</script>
<style lang="scss" scoped>
.yearPicker {
  pointer-events: visible;
  min-width: 90px;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s;
  color: white;
  border: 1px solid #69E5B7;
  height: 15px;
  line-height: 28px;
  border-radius: 4px;
  box-sizing: border-box;
  &:hover {
    border-color: #99bb50;
  }
  input {
    text-align: center;
    width: 40px;
    border: none;
    height: 100%;
    font-size: 8px;
    color: white;
    box-sizing: border-box;
    background-color: transparent;
  }
  input:focus {
    outline: none;
    background-color: transparent;
  }
  .floatPanel {
    padding: 0 16px;
    position: absolute;
    display: flex;
    background-color: #fff;
    z-index: 2000000000;
    border-radius: 4px;
    width: 650px;
    height: 250px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    > div {
      width: 50%;
    }
    &::before {
      content: "";
      height: 100%;
      position: absolute;
      left: 50%;
      width: 1px;
      border-left: 1px solid #e4e4e4;
    }
    .panelContent {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100% - 70px);

      .oneSelected {
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
      }
      .startSelected {
        background-color: #f6f6f7;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }
      .endSelected {
        background-color: #f6f6f7;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
      }
      .betweenSelected {
        background-color: #f6f6f7;
      }
      .cell {
        color: black;
        &:hover{
          color: #99bb50;
        }
      }
      .disabled{
        background: #f5f7fa;
        cursor:no-drop!important;
        color: #C0C4CC!important;
      }
      > div {
        width: 75px;
        height: 48px;
        line-height: 48px;
        margin: 3px 0;
        text-align: center;
        a {
          display: inline-block;
          width: 60px;
          height: 36px;
          cursor: pointer;
          line-height: 36px;
          border-radius: 18px;
        }
        .selected {
          background-color: #99bb50;
          color: #fff;
          &:hover {
            color: white !important;
          }
        }
      }
    }
    .panelHead {
      position: relative;
      height: 46px;
      line-height: 46px;
      text-align: center;
      i {
        position: absolute;
        cursor: pointer;
        &:hover {
          color: white;
        }
      }
      .el-icon-d-arrow-left,.el-icon-d-arrow-right{
        position: absolute;
        top: 17px;
      }
    }
    .rightPanel {
      padding-left: 8px;
    }
    .leftPanel .panelHead i {
      left: 20px;
    }
    .rightPanel .panelHead i {
      right: 20px;
    }
  }
}
</style>
