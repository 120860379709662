<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
html,body {
  padding: 0;
  margin: 0;
  background-color: #16272A;
}
</style>
