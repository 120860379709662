<template>
  <div style="overflow-y: hidden;width: 100%;height: 100%;">
    <div id="safety_map" style="width: 100%;height: 100%;position: fixed;left: 0;top: 0;z-index: 0"></div>
    <div class="padding_box">
      <top-header @click.native="fullScan" style="color: #70EFBD" title="车辆安全监管" />
      <div style="position: fixed;left: 400px;top: 60px;color: #6DE9B9;">车辆已跨区运营预警：{{ vehicleActualInfo.vehicleIsSpanned ? '是' : '否' }}</div>
      <div style="position: fixed;right: 380px;top: 60px;color: #6DE9B9;">{{ nowDate }}</div>
      <div class="content_box">
        <!-- 左侧 -->
        <div style="height: 100%;width: calc(25% - 20px);margin-right: 20px;box-sizing: border-box;">
          <qc-select
              popper-append-to-body
              filterable
              style="width: calc(100% - 30px);margin-left: 20px;margin-bottom: 10px;"
              @change="(val) => {
                 if (val) {
                   searchVal = plateNumberList.find(row => row.label === val).label
                   init();
                 }
              }"
              remote
              :remote-method="listPlateNumber"
              :options="plateNumberList"
              placeholder="请输入车牌号"
              v-model="searchVal2"/>
          <div ref="leftBoxRef" style="box-sizing: border-box;padding: 10px;position: relative;margin-top: 10px;margin-left: 20px;width: calc(100% - 21px);height: calc(100% - 50px);box-sizing: border-box;">
            <canvas ref="hornRef" class="horn"/>
            <div style="position: absolute;left: 0;top: 0;width: 100%;z-index: 3;">
              <div style="box-sizing: border-box;padding: 15px;width: 100%;display: flex;align-items: center;flex-flow: row wrap;">
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">车牌</div>
                  <div style="color: #F2F2F3;font-size: 12px;">{{ vehicleInfo.plateNumber || '--' }}</div>
                </div>
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">类型</div>
                  <div style="color: #F2F2F3;font-size: 14px;">{{ vehicleInfo.carTypeName || '--' }}</div>
                </div>
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">品牌型号</div>
                  <div style="color: #F2F2F3;font-size: 14px;">{{ vehicleInfo.brandName }}{{ vehicleInfo.vehicleCarName }}</div>
                </div>

                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">当前行驶里程</div>
                  <div style="color: #F2F2F3;font-size: 14px;">{{ vehicleActualInfo.carMileage || '--' }}km</div>
                </div>
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">状态</div>
<!--                  1启动，2熄火，3其他，4异常，5无效-->
                  <div v-if="vehicleActualInfo?.carInfo?.carState === 1" style="color: #F2F2F3;font-size: 14px;">
                    启动
                  </div>
                  <div v-if="vehicleActualInfo?.carInfo?.carState === 2" style="color: #F2F2F3;font-size: 14px;">
                    熄火
                  </div>
                  <div v-if="vehicleActualInfo?.carInfo?.carState === 3" style="color: #F2F2F3;font-size: 14px;">
                    其他
                  </div>
                  <div v-if="vehicleActualInfo?.carInfo?.carState === 4" style="color: #F2F2F3;font-size: 14px;">
                    异常
                  </div>
                  <div v-if="vehicleActualInfo?.carInfo?.carState === 5" style="color: #F2F2F3;font-size: 14px;">
                    无效
                  </div>
                </div>
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">加氢预约次数</div>
                  <div style="color: #F2F2F3;font-size: 14px;">{{ vehicleInfo.appointmentNum || '--' }}</div>
                </div>
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">加氢消耗金额</div>
                  <div style="color: #F2F2F3;font-size: 14px;">¥{{ (vehicleInfo.hydrogenTotalPrice / 100).toFixed(2) }}</div>
                </div>
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">加氢量</div>
                  <div style="color: #F2F2F3;font-size: 14px;">{{ vehicleInfo.hydrogenTotalWeight || '--' }}kg</div>
                </div>
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">实时氢耗</div>
                  <div style="color: #F2F2F3;font-size: 14px;">{{ vehicleActualInfo.hydrogenConsume || '--' }}kg/100km</div>
                </div>
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">高压DC输出电压</div>
                  <div style="color: #F2F2F3;font-size: 14px;">{{ vehicleActualInfo.dcV || '--' }}v</div>
                </div>
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">高压DC/DC状态</div>
                  <div v-if="vehicleActualInfo.dcStatus === 1" style="color: #F2F2F3;font-size: 14px;">
                  工作
                  </div>
                  <div v-else-if="vehicleActualInfo.dcStatus === 2" style="color: #F2F2F3;font-size: 14px;">
                  断开
                  </div>
                  <div v-else style="color: #F2F2F3;font-size: 14px;">无效</div>
                </div>
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">高压DC输出电流</div>
                  <div style="color: #F2F2F3;font-size: 14px;">{{ vehicleActualInfo.dcA || '--' }}A</div>
                </div>
                <div style="width: 33.333%;font-size: 10px;margin-bottom: 10px;">
                  <div style="color: #62CC92;line-height: 14px;">高压DC温度</div>
                  <div style="color: #F2F2F3;font-size: 14px;">{{ vehicleActualInfo.dcTemp || '--' }}℃</div>
                </div>
              </div>
              <div style="width: 100%;display: flex;align-items: center;justify-content: center;">
                <div style="width: calc(100% - 40px);background-color: #1C3A31;height: 1px;"/>
              </div>
              <div style="margin-top: 20px;width: calc(100% - 30px);margin-left: 15px;height: 16px;overflow-y: hidden;font-size: 12px;display: flex;align-items: center;margin-bottom: 10px;">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>车辆业务预警
              </div>
              <div style="margin-top: 20px;width: calc(100% - 30px);margin-left: 15px;font-size: 12px;display: flex;align-items: center;margin-bottom: 10px;flex-flow: row wrap">
                <div style="margin-bottom: 20px;font-size: 10px;width: 50%;display: flex;align-items: center">
                  <div style="background-color: #173031;border-radius: 50%;border: 1px solid #5CBD88;width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;">
                    <img :src="require('./img/Days.png')" style="width: 20px;height: auto;">
                  </div>
                  <div style="margin-left: 5px;">
                    <div style="color: #6CB498">距离年检到期天数</div>
                    <div style="color: #FCFCFC;font-size: 14px;">{{ earlyWarningInfo.yearCheckDay }}</div>
                  </div>
                </div>
                <div style="margin-bottom: 20px;font-size: 10px;width: 50%;display: flex;align-items: center">
                  <div style="background-color: #173031;border-radius: 50%;border: 1px solid #5CBD88;width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;">
                    <img :src="require('./img/_保险订单.png')" style="width: 20px;height: auto;">
                  </div>
                  <div style="margin-left: 5px;">
                    <div style="color: #6CB498">距离保险到期天数</div>
                    <div style="color: #FCFCFC;font-size: 14px;">{{ earlyWarningInfo.insuranceDay }}</div>
                  </div>
                </div>
                <div style="margin-bottom: 20px;font-size: 10px;width: 50%;display: flex;align-items: center">
                  <div style="background-color: #173031;border-radius: 50%;border: 1px solid #5CBD88;width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;">
                    <img :src="require('./img/保养.png')" style="width: 20px;height: auto;">
                  </div>
                  <div style="margin-left: 5px;">
                    <div style="color: #6CB498">距离保养到期天数</div>
                    <div style="color: #FCFCFC;font-size: 14px;">{{ earlyWarningInfo.maintainDay }}</div>
                  </div>
                </div>
                <div style="margin-bottom: 20px;font-size: 10px;width: 50%;display: flex;align-items: center">
                  <div style="background-color: #173031;border-radius: 50%;border: 1px solid #5CBD88;width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;">
                    <img :src="require('./img/警告.png')" style="width: 20px;height: auto;">
                  </div>
                  <div style="margin-left: 5px;">
                    <div style="color: #6CB498">长期停运预警</div>
                    <div style="color: #FCFCFC;font-size: 14px;">{{ earlyWarningInfo.noDriveDay }}</div>
                  </div>
                </div>
              </div>
              <div style="width: 100%;display: flex;align-items: center;justify-content: center;">
                <div style="width: calc(100% - 40px);background-color: #1C3A31;height: 1px;"/>
              </div>
              <div style="margin-top: 20px;width: calc(100% - 30px);margin-left: 15px;height: 16px;overflow-y: hidden;font-size: 12px;display: flex;align-items: center;margin-bottom: 10px;">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>事故、故障与违章
              </div>
              <div style="margin-top: 20px;width: calc(100% - 30px);margin-left: 15px;font-size: 12px;display: flex;align-items: center;margin-bottom: 10px;flex-flow: row wrap">
                <div style="margin-bottom: 20px;font-size: 10px;width: 50%;display: flex;align-items: center">
                  <div style="background-color: #173031;border-radius: 50%;border: 1px solid #5CBD88;width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;">
                    <img :src="require('./img/事故管理.png')" style="width: 20px;height: auto;">
                  </div>
                  <div style="margin-left: 5px;">
                    <div style="color: #6CB498">事故次数</div>
                    <div style="color: #FCFCFC;font-size: 14px;">{{ repairInfo.accidentNum }}</div>
                  </div>
                </div>
                <div style="margin-bottom: 20px;font-size: 10px;width: 50%;display: flex;align-items: center">
                  <div style="background-color: #173031;border-radius: 50%;border: 1px solid #5CBD88;width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;">
                    <img :src="require('./img/sim卡故障.png')" style="width: 20px;height: auto;">
                  </div>
                  <div style="margin-left: 5px;">
                    <div style="color: #6CB498">故障次数</div>
                    <div style="color: #FCFCFC;font-size: 14px;">{{ repairInfo.repairNum }}</div>
                  </div>
                </div>
                <div style="margin-bottom: 20px;font-size: 10px;width: 50%;display: flex;align-items: center">
                  <div style="background-color: #173031;border-radius: 50%;border: 1px solid #5CBD88;width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;">
                    <img :src="require('./img/3.1-违章快查.png')" style="width: 20px;height: auto;">
                  </div>
                  <div style="margin-left: 5px;">
                    <div style="color: #6CB498">违章次数</div>
                    <div style="color: #FCFCFC;font-size: 14px;">{{ repairInfo.breakRulesNum }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 中间 -->
        <div style="pointer-events: none;height: calc(100% - 12px);position: relative;box-sizing: border-box;padding-right: 15px;width: 50%;">
          <div style="font-size: 10px;position: absolute;top: 100px;left: 30%;color: #65DCB6;box-sizing: border-box;padding: 10px;background-color: #081418;">
            <div style="display: flex;align-items: center;">
              <img :src="require('./temp/头像 (2).png')" style="width: 50px;height: 50px;" />
              <div>
                <div>当前驾驶员：{{ vehicleInfo.driveName || '--' }}</div>
                <div>驾驶证类型：{{ vehicleInfo.allowDriverTypeName }}</div>
                <div>手机号：{{ vehicleInfo.driveMobile || '--' }}</div>
              </div>
            </div>
            <div>经纬度：{{ vehicleActualInfo?.location?.latitude || '--' }},{{ vehicleActualInfo?.location?.latitude || '--' }}</div>
            <div>轨迹点时间：{{ isBlank(vehicleActualInfo?.location?.updateTime) ? '--' : dayjs(vehicleActualInfo?.location?.updateTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
            <div>氢气含量：--%</div>

            <img :src="require('./img/边框.png')" style="width: 10px;height: 10px;position: absolute;left: 0;top:0" />
            <img :src="require('./img/边框 (1).png')" style="width: 10px;height: 10px;position: absolute;right: 0;top:0" />
            <img :src="require('./img/边框 (2).png')" style="width: 10px;height: 10px;position: absolute;left: 0;bottom:0" />
            <img :src="require('./img/边框 (3).png')" style="width: 10px;height: 10px;position: absolute;right: 0;bottom:0" />
          </div>
          <search-bar
              @init="(params) => {
                initMapParam.startTime = dayjs(params[0]).format('YYYY-MM-DD HH:mm:ss');
                initMapParam.endTime = dayjs(params[1]).format('YYYY-MM-DD HH:mm:ss');
              }"
              @search="(params) => {
                initMapParam.startTime = dayjs(params[0]).format('YYYY-MM-DD HH:mm:ss');
                initMapParam.endTime = dayjs(params[1]).format('YYYY-MM-DD HH:mm:ss');
                this.initMap();
              }"
          />
          <div style="width: 100%;margin-top: 15px;">
            <div style="width: 100%;display: flex;align-items: center;">
              <div style="width: 2px;height: 40px;background-color: #87DEBB"/>
              <div style="margin-left: 10px;">
                <div style="font-size: 14px;color: #66E0B9">{{ vehicleActualInfo.vehiclePower || '--' }}kw</div>
                <div style="font-size: 14px;">整车功率</div>
              </div>
            </div>
            <div style="width: 100%;display: flex;align-items: center;margin-top: 15px;">
              <div style="width: 2px;height: 40px;background-color: #87DEBB"/>
              <div style="margin-left: 10px;">
                <div style="font-size: 14px;color: #66E0B9">{{ vehicleActualInfo.fuelPower || '--' }}kw</div>
                <div style="font-size: 14px;">燃料功率</div>
              </div>
            </div>
            <div style="width: 100%;display: flex;align-items: center;margin-top: 15px;">
              <div style="width: 2px;height: 40px;background-color: #87DEBB"/>
              <div style="margin-left: 10px;">
                <div style="font-size: 14px;color: #66E0B9">{{ vehicleActualInfo?.warnings?.map(row => row.alarmCode).join('，') }}</div>
                <div style="font-size: 14px;">系统故障代码</div>
              </div>
            </div>
          </div>
          <car-mileage ref="carMileageRef" />
        </div>
        <!-- 右侧 -->
        <div ref="rightBoxRef" style="position: relative;height: 100%;width: calc(25% - 20px);margin-right: 20px;">
          <canvas ref="horn2Ref" class="horn"/>
          <div style="height: 100%;position: absolute;left: 0;top: 0;width: 100%;z-index: 3;box-sizing: border-box;padding: 15px;">

            <div style="z-index: 10000;margin-top: 15px;height: 16px;justify-content: space-between;font-size: 12px;width: 100%;display: flex;align-items: center;">
              <div style="display: flex;align-items: center;">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                氢耗情况
                <span style="font-size: 8px">(kg/100km)</span>
              </div>
              <select-date style="width: calc(100% - 100px)"
                           :left="0"
                           :right="-10"
                           @date-change="$nextTick(initHydrogenConsumeEcharts)"
                           :date-type.sync="hydrogenConsumeEchartsParam.type"
                           :date.sync="hydrogenConsumeEchartsParam.date" />
            </div>
            <div style="width: 100%;height: calc(30% - 16px);">
              <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;" ref="hydrogenConsumeEcharts"/>
            </div>

            <div style="margin-top: 10px;height: 16px;z-index: 1000;justify-content: space-between;font-size: 12px;width: 100%;display: flex;align-items: center;">
              <div style="display: flex;align-items: center;">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                减碳总数
                <span style="font-size: 8px">(kg)</span>
              </div>
              <select-date style="width: calc(100% - 100px)"
                           :left="0"
                           :right="-10"
                           @date-change="$nextTick(initReduceCarbonEcharts)"
                           :date-type.sync="reduceCarbonEchartsParam.type"
                           :date.sync="reduceCarbonEchartsParam.date" />
            </div>
            <div style="width: 100%;height: calc(30% - 16px);overflow-y: hidden;">
              <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;" ref="reduceCarbonEcharts"/>
            </div>

            <div style="margin-top: 10px;height: 16px;z-index: 1000;justify-content: space-between;font-size: 12px;width: 100%;display: flex;align-items: center;">
              <div style="display: flex;align-items: center;">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                数据监管
              </div>
              <select-date style="width: calc(100% - 100px)"
                           :left="0"
                           :right="-10"
                           @date-change="$nextTick(initDataSuperviseEcharts)"
                           :date-type.sync="dataSuperviseEchartsParam.type"
                           :date.sync="dataSuperviseEchartsParam.date" />
            </div>
            <div style="height: calc(33% - 16px);overflow-y: hidden;width: 100%;display: flex;flex-flow: row wrap;justify-content: space-between">
              <div style="width: 50%;height: 50%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="width: 100%;height: 100%;box-sizing: border-box;padding-top: 10px;">
                  <div style="width: 100%;height: 100%;" ref="safetyInspectionRateEcharts"/>
                </div>
                <div style="font-size: 12px;color: #FFFFFF;position: absolute;left: 0;bottom: 0;width: 100%;display: flex;align-items: center;justify-content: center;">
                  安全检查率
                </div>
              </div>
              <div style="width: 50%;height: 50%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="width: 100%;height: 100%;box-sizing: border-box;padding-top: 10px;">
                  <div style="width: 100%;height: 100%;" ref="floorAreaRateEcharts"/>
                </div>
                <div style="font-size: 12px;color: #FFFFFF;position: absolute;left: 0;bottom: 0;width: 100%;display: flex;align-items: center;justify-content: center;">
                  车容检查达成率
                </div>
              </div>
              <div style="width: 50%;height: 50%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="width: 100%;height: 100%;box-sizing: border-box;padding-top: 10px;">
                  <div style="width: 100%;height: 100%;" ref="maintenanceRateEcharts"/>
                </div>
                <div style="font-size: 12px;color: #FFFFFF;position: absolute;left: 0;bottom: 0;width: 100%;display: flex;align-items: center;justify-content: center;">
                  及时保养达成率
                </div>
              </div>
              <div style="width: 50%;height: 50%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="width: 100%;height: 100%;box-sizing: border-box;padding-top: 10px;">
                  <div style="width: 100%;height: 100%;" ref="serviceRateEcharts"/>
                </div>
                <div style="font-size: 12px;color: #FFFFFF;position: absolute;left: 0;bottom: 0;width: 100%;display: flex;align-items: center;justify-content: center;">
                  维修达成率
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import dayjs from 'dayjs'
import SearchBar from './search-bar.vue'
import CarMileage from './car-mileage/index.vue'
import {
  getEarlyWarning,
  getRepairInfo,
  getVehicleActualInfo,
  getVehicleInfo, listCo2NumData,
  listHydrogenConsume,
  listSuperviseData,
  listVehicleLocation,
  listPlateNumber
} from '@/api/vehicle/safe'
import isBlank from '@/utils/isBlank'

const endTime = dayjs(new Date()).format('YYYY')
const startTime = dayjs(new Date()).subtract(5, 'year').format('YYYY')

function getFormatTypeDate(type, val) {
  switch (type) {
    case '3': // day
      return {
        startTime: isBlank(val) ? null : dayjs(val).format('YYYYMMDD'),
        endTime: null
      }
    case '2': // moon
      return {
        startTime: dayjs(val[0]).format('YYYYMM'),
        endTime: dayjs(val[1]).format('YYYYMM'),
      }
    case '1': // year
      return {
        startTime: dayjs(val[0]).format('YYYY'),
        endTime: dayjs(val[1]).format('YYYY'),
      }
  }
}

let intervalDate
let intervalRes
let map
export default {
  name: "safety-supervision",
  data() {
    const vehicleActualInfo = this.defaultVehicleActualInfo()
    return {
      nowDate: dayjs().format('YYYY年MM月DD日 HH:mm:ss'),
      searchVal: null,
      searchVal2: null,

      hydrogenConsumeEchartsParam: {
        type: '1',
        date: [startTime, endTime]
      },
      reduceCarbonEchartsParam: {
        type: '1',
        date: [startTime, endTime]
      },
      dataSuperviseEchartsParam: {
        type: '1',
        date: [startTime, endTime]
      },

      hydrogenConsumeEcharts: null,
      reduceCarbonEcharts: null,
      safetyInspectionRateEcharts: null,
      floorAreaRateEcharts: null,
      maintenanceRateEcharts: null,
      serviceRateEcharts: null,

      vehicleActualInfo,
      vehicleInfo: {
        hydrogenTotalPrice: 0,
        plateNumber: null,
        brandName: null,
        vehicleCarName: null,
        carType: null,
        carTypeName: null
      },
      earlyWarningInfo: {
        insuranceDay: 0,
        maintainDay: 0,
        noDriveDay: 0,
        yearCheckDay: 0
      },
      repairInfo: {
        accidentNum: 0,
        breakRulesNum: 0,
        repairNum: 0
      },
      superviseData: {
        data1: 0,
        data2: 0,
        data3: 0,
        data4: 0
      },
      initMapParam: {
        startTime: null,
        endTime: null
      },
      plateNumberList: [],
      plateNumberInfo: {
        plateNumber: null
      }
    }
  },
  created() {
    document.title = '车辆安全监管'
    intervalDate = setInterval(() => {
      this.nowDate = dayjs().format('YYYY年MM月DD日 HH:mm:ss')
    }, 1000)
    intervalRes = setInterval(this.init, 60_000)

    window.addEventListener('resize', this.reLoad)
  },
  beforeDestroy() {
    this.clearEcharts()
    if (intervalDate) {
      clearInterval(intervalDate)
    }
    if (intervalRes) {
      clearInterval(intervalRes)
    }
    window.removeEventListener('resize', this.reLoad)
  },
  async mounted() {
    if (this.$route?.query?.plateNumber) {
      this.searchVal = this.$route?.query?.plateNumber
    } else {
      await this.listPlateNumber()
    }
    this.init()
  },
  components: {
    SearchBar,
    CarMileage
  },
  methods: {
    isBlank,
    dayjs,
    fullScan() {
      if (screenfull.isFullscreen) {
        screenfull.exit()
      } else {
        screenfull.request()
      }
    },
    clearEcharts() {
      if (this.pressureEcharts) {
        this.pressureEcharts.dispose()
      }
    },
    reLoad() {
      this.clearEcharts()
      this.$nextTick(() => {
        setTimeout(this.init, 100)
      })
    },
    async init() {
      this.drawLeftBoxBorder()
      this.drawRightBoxBorder()
      this.initHydrogenConsumeEcharts()
      this.initReduceCarbonEcharts()
      this.initDataSuperviseEcharts()

      this.getVehicleActualInfo()
      this.getEarlyWarning()
      this.getRepairInfo()
      this.initMap()
      this.listSuperviseData()
    },
    drawLeftBoxBorder() {
      const height = this.$refs.leftBoxRef.offsetHeight
      const width = this.$refs.leftBoxRef.offsetWidth
      // hornRef
      let canvas = this.$refs.hornRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      context.moveTo(0,0);
      context.lineTo(0, height);

      context.moveTo(0, height);
      context.lineTo(width, height);

      // 右边线段
      context.moveTo(width, height);
      context.lineTo(width, 30);

      // 顶部线段
      context.moveTo(0, 0);
      context.lineTo(width - 30, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(width - 30, 0);
      context.lineTo(width, 30);
      context.stroke();
    },
    drawRightBoxBorder() {
      const height = this.$refs.rightBoxRef.offsetHeight - 10
      const width = this.$refs.rightBoxRef.offsetWidth
      // hornRef
      let canvas = this.$refs.horn2Ref
      canvas.width = width
      canvas.height = height

      console.log(canvas.height, canvas.width)
      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3

      context.moveTo(0, height);
      context.lineTo(width, height);

      context.moveTo(width, height);
      context.lineTo(width, 0);

      // 左边
      context.moveTo(0,30);
      context.lineTo(0, height);

      // 顶部线段
      context.moveTo(30, 0);
      context.lineTo(width, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(0, 30);
      context.lineTo(30, 0);
      context.stroke();
    },
    search() {
      this.init()
    },

    async initHydrogenConsumeEcharts() {
      if (isBlank(this.searchVal)) {
        return
      }
      const res = await this.$request(listHydrogenConsume(
          Object.assign({}, this.hydrogenConsumeEchartsParam, {
            ...getFormatTypeDate(this.hydrogenConsumeEchartsParam.type, this.hydrogenConsumeEchartsParam.date),
          }),
          {
            plateNumber: this.searchVal
          }
      ))
      if (!this.hydrogenConsumeEcharts) {
        this.hydrogenConsumeEcharts = this.$echarts.init(this.$refs.hydrogenConsumeEcharts);
      }
      this.hydrogenConsumeEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '0%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#F99E00'
            },
            lineStyle: {
              color: '#F99E00'
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#D28B08'
                },
                {
                  offset: 1,
                  color: 'transparent'
                }
              ])
            },
          }
        ]
      })
    },
    async initReduceCarbonEcharts() {
      if (isBlank(this.searchVal)) {
        return
      }
      const res = await this.$request(listCo2NumData(
          Object.assign({}, this.reduceCarbonEchartsParam, {
            ...getFormatTypeDate(this.reduceCarbonEchartsParam.type, this.reduceCarbonEchartsParam.date),
          }),
          {
            plateNumber: this.searchVal
          }
      ))
      if (!this.reduceCarbonEcharts) {
        this.reduceCarbonEcharts = this.$echarts.init(this.$refs.reduceCarbonEcharts);
      }
      this.reduceCarbonEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '0%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#F99E00'
            },
            lineStyle: {
              color: '#F99E00'
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#D28B08'
                },
                {
                  offset: 1,
                  color: 'transparent'
                }
              ])
            },
          }
        ]
      })
    },

    async initDataSuperviseEcharts() {
      this.initSafetyInspectionRateEcharts()
      this.initFloorAreaRateEcharts()
      this.initMaintenanceRateEcharts()
      this.initServiceRateEcharts()
    },
    initSafetyInspectionRateEcharts() {
      if (!this.safetyInspectionRateEcharts) {
        this.safetyInspectionRateEcharts = this.$echarts.init(this.$refs.safetyInspectionRateEcharts);
      }
      this.safetyInspectionRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 10
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: this.superviseData.data1,
                name:  this.superviseData.data1 + '%',
                title: {
                  offsetCenter: ['0%', '0%'],
                  color: '#1FF457',
                  fontSize: 20
                },
                detail: {
                  show: false
                },
                itemStyle: {
                  color: '#1FF457'
                }
              }
            ],
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              color: 'inherit',
              borderColor: 'inherit',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            },
            center: ['50%', '40%']
          }
        ],
        title: {
          show: false,
          text: '安全检查率',
          textStyle: {
            color: '#FFFFFF',
            fontSize: 14,
          },
          bottom: '5%',
          left: '18%'
        }
      })
    },
    initFloorAreaRateEcharts() {
      if (!this.floorAreaRateEcharts) {
        this.floorAreaRateEcharts = this.$echarts.init(this.$refs.floorAreaRateEcharts);
      }
      this.floorAreaRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 10
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: this.superviseData.data2,
                name: this.superviseData.data2 + '%',
                title: {
                  offsetCenter: ['0%', '0%'],
                  color: '#A8F30A',
                  fontSize: 20
                },
                detail: {
                  show: false
                },
                itemStyle: {
                  color: '#A8F30A'
                }
              }
            ],
            title: {
              fontSize: 14
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              color: 'inherit',
              borderColor: 'inherit',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            },
            center: ['50%', '40%']
          }
        ],
        title: {
          show: false,
          text: '车容检查达成率',
          textStyle: {
            color: '#FFFFFF',
            fontSize: 14,
          },
          bottom: '5%',
          left: '12%'
        }
      })
    },
    initMaintenanceRateEcharts() {
      if (!this.maintenanceRateEcharts) {
        this.maintenanceRateEcharts = this.$echarts.init(this.$refs.maintenanceRateEcharts);
      }
      this.maintenanceRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 10
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: this.superviseData.data3,
                name: this.superviseData.data3 + '%',
                title: {
                  offsetCenter: ['0%', '0%'],
                  color: '#0EF7FA',
                  fontSize: 20
                },
                detail: {
                  show: false
                },
                itemStyle: {
                  color: '#0EF7FA'
                }
              }
            ],
            title: {
              fontSize: 14
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              color: 'inherit',
              borderColor: 'inherit',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            },
            center: ['50%', '40%']
          }
        ],
        title: {
          show: false,
          text: '及时保养达成率',
          textStyle: {
            color: '#FFFFFF',
            fontSize: 14,
          },
          bottom: '5%',
          left: '10%'
        }
      })
    },
    initServiceRateEcharts() {
      if (!this.serviceRateEcharts) {
        this.serviceRateEcharts = this.$echarts.init(this.$refs.serviceRateEcharts);
      }
      this.serviceRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 10
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: this.superviseData.data4,
                name: this.superviseData.data4 + '%',
                title: {
                  offsetCenter: ['0%', '0%'],
                  color: '#0796FA',
                  fontSize: 20
                },
                detail: {
                  show: false
                },
                itemStyle: {
                  color: '#0796FA'
                }
              }
            ],
            title: {
              fontSize: 14
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              color: 'inherit',
              borderColor: 'inherit',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            },
            center: ['50%', '40%']
          }
        ],
        title: {
          show: false,
          text: '维修完成率',
          textStyle: {
            color: '#FFFFFF',
            fontSize: 14,
          },
          bottom: '5%',
          left: '10%'
        }
      })
    },
    defaultVehicleActualInfo() {
      return {
        carInfo: {},
        dateTime: null,
        dcA: null,
        dcStatus: null,
        dcTemp: null,
        dcV: null,
        fuelPower: null,
        hydrogenConsume: null,
        location: {},
        vehicleIsSpanned: null,
        vehiclePower: null,
        warnings: []
      }
    },

    // 初始化地图
    async initMap() {
      if (isBlank(this.searchVal)) {
        return
      }
      const res = await this.$request(listVehicleLocation(Object.assign({}, {
        startTime: this.initMapParam.startTime,
        endTime: this.initMapParam.endTime,
        plateNumber: this.searchVal
      })))
      if (map) {
        map.destroy()
      }
      let defaultLat = 23.130061
      let defaultLng = 113.264499

      if (res.length !== 0) {
        defaultLat = res.reduce((total, row) => total + row.latitude, 0) / res.length
        defaultLng = res.reduce((total, row) => total + row.defaultLng, 0) / res.length
      }

      const center = new this.$TMap.LatLng(defaultLat, defaultLng);
      map = new this.$TMap.Map("safety_map", {
        zoom: 10,
        center,
        mapStyleId: 'style1'
      });

      const path = res.map(row => {
        return new this.$TMap.LatLng(row.latitude, row.longitude)
      })

      if (path.length !== 0) {
        const polylineLayer = new this.$TMap.MultiPolyline({
          map, // 绘制到目标地图
          // 折线样式定义
          styles: {
            style_blue: new this.$TMap.PolylineStyle({
              color: '#3777FF', // 线填充色
              width: 4, // 折线宽度
              borderWidth: 2, // 边线宽度
              borderColor: '#FFF', // 边线颜色
              lineCap: 'round', // 线端头方式
              eraseColor: 'rgba(190,188,188,1)',
            }),
          },
          geometries: [
            {
              id: 'erasePath',
              styleId: 'style_blue',
              paths: path,
            },
          ],
        });

        const marker = new this.$TMap.MultiMarker({
          map,
          styles: {
            'car-down': new this.$TMap.MarkerStyle({
              width: 40,
              height: 40,
              anchor: {
                x: 20,
                y: 20,
              },
              faceTo: 'map',
              rotate: 180,
              src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/car.png',
            }),
            start: new this.$TMap.MarkerStyle({
              width: 25,
              height: 35,
              anchor: { x: 16, y: 32 },
              src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png',
            }),
            end: new this.$TMap.MarkerStyle({
              width: 25,
              height: 35,
              anchor: { x: 16, y: 32 },
              src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png',
            }),
          },
          geometries: [
            {
              id: 'car',
              styleId: 'car-down',
              position: new this.$TMap.LatLng(39.98481500648338, 116.30571126937866),
            },
            {
              id: 'start',
              styleId: 'start',
              position: new this.$TMap.LatLng(39.98481500648338, 116.30571126937866),
            },
            {
              id: 'end',
              styleId: 'end',
              position: new this.$TMap.LatLng(39.978813710266024, 116.31699800491333),
            },
          ],
        });
        // 使用marker 移动接口， https://lbs.qq.com/webApi/javascriptGL/glDoc/glDocMarker
        marker.moveAlong(
            {
              car: {
                path,
                speed: 250,
              },
            },
            {
              autoRotation: true,
            }
        );
        marker.on('moving', (e) => {
          var passedLatLngs = e.car && e.car.passedLatLngs;
          if (passedLatLngs) {
            // 使用路线擦除接口 eraseTo, https://lbs.qq.com/webApi/javascriptGL/glDoc/glDocVector
            polylineLayer.eraseTo(
                'erasePath',
                passedLatLngs.length - 1,
                passedLatLngs[passedLatLngs.length - 1]
            );
          }
        });
      }
    },
    // 获取左上角数据
    async getVehicleActualInfo() {
      if (isBlank(this.searchVal)) {
        return
      }
      const res = await this.$request(getVehicleActualInfo({
        plateNumber: this.searchVal
      }))
      Object.assign(this.vehicleActualInfo, res)
      const res2 = await this.$request(getVehicleInfo({
        plateNumber: this.searchVal
      }))
      Object.assign(this.vehicleInfo, res2)
      this.$refs.carMileageRef.targetMileage = res2.targetMileage || 0
      this.$refs.carMileageRef.driveMileage = res2.driveMileage || 0
      this.$refs.carMileageRef.totalTargetMileage = res2.totalTargetMileage || 0
    },
    // 车辆业务预警
    async getEarlyWarning() {
      if (isBlank(this.searchVal)) {
        return
      }
      const res = await this.$request(getEarlyWarning({
        plateNumber: this.searchVal
      }))
      Object.assign(this.earlyWarningInfo, res)
    },
    // 事故、故障与违章
    async getRepairInfo() {
      if (isBlank(this.searchVal)) {
        return
      }
      const res = await this.$request(getRepairInfo({
        plateNumber: this.searchVal
      }))
      Object.assign(this.repairInfo, res)
    },
    // 数据监管
    async listSuperviseData() {
      if (isBlank(this.searchVal)) {
        return
      }
      const res = await this.$request(listSuperviseData(
          Object.assign({}, this.dataSuperviseEchartsParam, {
            ...getFormatTypeDate(this.dataSuperviseEchartsParam.type, this.dataSuperviseEchartsParam.date),
          }),
          {
            plateNumber: this.searchVal
          }
      ))
      this.superviseData.data1 = res.find(row => row.name === '安全检查率').num
      this.superviseData.data2 = res.find(row => row.name === '车容检查率').num
      this.superviseData.data3 = res.find(row => row.name === '及时保养达成率').num
      this.superviseData.data4 = res.find(row => row.name === '维修完成率').num
    },
    // 查询车辆列表
    async listPlateNumber(plateNumber) {
      const res = await this.$request(listPlateNumber({
        limit: 20,
        plateNumber
      }))
      this.plateNumberList = res.map(row => {
        return {
          label: row,
          value: row
        }
      })
      if (res) {
        this.searchVal = res[0]
      } else {
        this.searchVal = null
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.padding_box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: white;
}
.content_box {
  width: 100%;
  display: flex;
  height: calc(100% - 100px);
  align-items: flex-start;
}
.input_search {
  height: 100%;width: 100%;border: none;background-color: transparent;
  color: white;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 40px;
}
.input_search:focus {
  border: none !important;
  outline: none;
}
.search_icon {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}
.horn {
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 2;
  background-color: #282c34;
}
</style>
