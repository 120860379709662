<template>
  <div style="width: calc(100% - 15px);position: absolute;bottom: 0;left: 0;">
    <div style="width: 100%;display: flex;align-items: center;position: relative">
      <car :mileage="targetMileage"
           :style="{
            left: 'calc('+ targetMileagePer +'% - 30px)'
           }"
           style="position: absolute;bottom:0;"
           car-type="target" />
      <car :mileage="driveMileage"
           :style="{
            left: 'calc('+ driveMileagePer +'% - 30px)'
           }"
           style="z-index: 100;position: absolute;bottom:0;left: calc(40% - 30px)"
           car-type="default" />
    </div>
    <div class="line_box">
      <div :style="{
             width: driveMileagePer + '%'
           }"
           class="inner_box"/>
    </div>
    <div style="margin-top: 10px;font-size: 12px;color: #EEEEEE;width: 100%;display: flex;align-items: center;justify-content: center">
      总目标里程{{ totalTargetMileage }}km
    </div>
  </div>
</template>

<script>
import Car from './car.vue'

export default {
  name: "CarMileage",
  data() {
    return {
      targetMileage: 0, // 目标里程
      driveMileage: 0, // 已达标里程
      totalTargetMileage: 0, // 总目标里程
    }
  },
  components: {
    Car
  },
  computed: {
    targetMileagePer() {
      if (this.totalTargetMileage === 0) {
        return 100
      }
      return (this.targetMileage / this.totalTargetMileage) * 100
    },
    driveMileagePer() {
      if (this.totalTargetMileage === 0) {
        return 100
      }
      return (this.driveMileage / this.totalTargetMileage) * 100
    }
  }
}
</script>

<style lang="stylus" scoped>
.line_box {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: #32383E;
  .inner_box {
    height: 100%;
    background-color: #65D498;
    border-radius: 5px;
  }
}
</style>
