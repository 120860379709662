<template>
  <div style="overflow-y: hidden;width: 100%;height: 100%;">
    <div class="padding_box">
      <top-header @click.native="fullScan" style="color: #70EFBD" title="加氢站运营实时监控" />
      <div style="position: fixed;left: 400px;top: 60px;color: #6DE9B9;">当前区域：{{ areaCode.fullname || '全国' }}</div>
      <div style="position: fixed;right: 380px;top: 60px;color: #6DE9B9;">{{ nowDate }}</div>
      <div class="content_box">
        <!-- 左侧 -->
        <div style="height: 100%;width: calc(25% - 20px);margin-right: 20px;box-sizing: border-box;">
<!--          <qc-select-->
<!--              popper-append-to-body-->
<!--              filterable-->
<!--              style="width: calc(100% - 20px);margin-left: 20px;margin-bottom: 10px;"-->
<!--              @change="(val) => {-->
<!--                 if (val) {-->
<!--                   Object.assign(areaCode, areaCodeList.find(row => row.code === val));-->
<!--                   init();-->
<!--                 }-->
<!--              }"-->
<!--              remote-->
<!--              :remote-method="listArea"-->
<!--              :options="areaCodeList"-->
<!--              placeholder="请输入加氢站所在区域名称"-->
<!--              v-model="searchVal"/>-->
          <select-region
              ref="regionRef"
              @change="selectRegionCb"
              style="width: calc(100% - 20px);margin-left: 20px;margin-bottom: 10px;"
              v-model="searchVal"
              filterable
              placeholder="请输入加氢站所在区域名称"
          />
          <div style="box-sizing: border-box;margin-top: 10px;margin-left: 20px;width: calc(100% - 21px);height: calc(100% - 50px);">
            <!-- 左边 top -->
            <div ref="leftTopRef" style="position: relative;width: 100%;height: calc(35% - 10px);">
              <canvas ref="leftTopCanvasRef" class="horn"/>
              <div style="box-sizing: border-box;padding: 10px;z-index: 2;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">
                <div style="height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;justify-content: space-between">
                  <div style="display: flex;align-items: center">
                    <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                    加氢站数量
                  </div>
                  <span style="font-size: 10px;color: #919797">统计日期：{{ nowDateStr }}</span>
                </div>
                <div style="height: calc(100% - 16px);width: 100%;overflow: hidden;position: relative;">
                  <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;"
                       :style="{
                        opacity: hydrogenStationEchartData.length === 0 ? 0 : 1
                       }"
                       ref="hydrogenStationEcharts"/>
                  <div v-if="hydrogenStationEchartData.length === 0"
                       style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: 100000000;color: #808695;display: flex;align-items: center;justify-content: center;">
                    暂无数据
                  </div>
                </div>
              </div>
            </div>
            <!-- 左边 middle -->
            <div ref="leftMiddleRef" style="position: relative;width: 100%;height: 30%;margin-top: 10px;">
              <canvas ref="leftMiddleCanvasRef" class="horn"/>
              <div style="box-sizing: border-box;padding: 10px;z-index: 2;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">
                <div style="height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;justify-content: space-between">
                  <div style="display: flex;align-items: center">
                    <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                    加氢站类型
                  </div>
                  <span style="font-size: 10px;color: #919797">统计日期：{{ nowDateStr }}</span>
                </div>
                <div style="width: 100%;height: calc(100% - 16px);" ref="carNumEcharts"/>
              </div>
            </div>
            <!-- 左边 bottom -->
            <div ref="leftBottomRef" style="position: relative;width: 100%;height: calc(35% - 10px);margin-top: 10px;">
              <canvas ref="leftBottomCanvasRef" class="horn"/>
              <div style="box-sizing: border-box;padding: 10px;z-index: 2;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">
                <div style="height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;justify-content: space-between">
                  <div style="display: flex;align-items: center">
                    <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                    加氢压强类型
                  </div>
                  <span style="font-size: 10px;color: #919797">统计日期：{{ nowDateStr }}</span>
                </div>
                <div style="width: 100%;height: calc(100% - 16px);" ref="areaEcharts"/>
              </div>
            </div>
          </div>
        </div>
        <!-- 中间 -->
        <div style="height: calc(100% - 12px);position: relative;box-sizing: border-box;padding-right: 15px;width: 50%;">
          <div style="width: 100%;display: flex;align-items: center;justify-content: space-around;font-size: 14px;color: #F1F1F2;">
            <div>当前监控设备</div>
            <div style="display: flex;align-items: center;">
              所有站场
              <i class="el-icon-refresh" />
            </div>
            <div>状态：正常</div>
          </div>
          <div style="display: flex;align-items: center;width: 100%;height: calc(36% - 10px);;justify-content: space-between;margin-top: 10px">
            <div ref="middleLeftRef" style="width: calc(50% - 10px);position: relative;height: 100%">
              <canvas ref="middleLeftCanvasRef" />
              <div style="z-index: 2;box-sizing: border-box;padding: 10px;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">
                <div style="font-size: 10px;color: #5ECFAB;margin-top: 20px;margin-bottom: 10px;">安全管理</div>
                <div style="display: flex;align-items: center;justify-content: space-between;">
                  <div style="box-sizing: border-box;padding: 10px;background-color: #E15349;width: 30%;font-size: 10px;">
                    <div>{{ safeManageCountInfo.expireNum || 0 }}个</div>
                    <div>过期</div>
                  </div>
                  <div style="box-sizing: border-box;padding: 10px;background-color: #F5C543;width: 30%;font-size: 10px;">
                    <div>{{ safeManageCountInfo.thirtyDayNum || 0 }}个</div>
                    <div>30天内</div>
                  </div>
                  <div style="box-sizing: border-box;padding: 10px;background-color: #5FCFF9;width: 30%;font-size: 10px;">
                    <div>{{ safeManageCountInfo.sevenDayNum || 0 }}个</div>
                    <div>7天内</div>
                  </div>
                </div>
                <div style="font-size: 10px;color: #5ECFAB;margin-top: 20px;margin-bottom: 10px;">特种设备保养到期预警</div>
                <div style="display: flex;align-items: center;justify-content: space-between;">
                  <div v-for="(item, index) of maintainEarlyWarningList"
                       :key="index + 'maintain'"
                       style="width: 32%;box-sizing: border-box;padding: 10px;background-color: #152730;border: 1px solid #3C8173">
                    <div style="display: flex;align-items: center;justify-content: space-between;font-size: 8px;color: #63D8B2;">
                      <span>{{ item.stationName }}</span>
                      <span>#{{ item.code }}</span>
                    </div>
                    <div style="margin-top: 4px;display: flex;align-items: flex-start;justify-content: space-between;font-size: 8px;">
                      <span>{{ item.deviceName }}</span>
                      <div style="background-color: #68E3B9;box-sizing: border-box;padding: 3px;color: white;">{{ item.maintainDay }}天</div>
                    </div>
                    <div style="font-size: 8px;margin-top: 8px;">保养时间</div>
                    <div style="font-size: 8px;margin-top: 3px;">{{ isBlank(item.maintainTime) ? dayjs(item.maintainTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div ref="middleRightRef" style="width: calc(50% - 10px);height: 100%;position: relative;">
              <canvas ref="middleRightCanvasRef" />
              <div style="z-index: 2;box-sizing: border-box;padding: 10px;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">
                <div style="font-size: 10px;color: #5ECFAB;margin-top: 20px;margin-bottom: 10px;">安全巡检</div>
                <div style="display: flex;align-items: center;justify-content: space-between;">
                  <div style="box-sizing: border-box;padding: 10px;background-color: #E15349;width: 30%;font-size: 10px;">
                    <div>{{ hydrogenConsumeInfo.normalNum || 0 }}次</div>
                    <div>正常巡检</div>
                  </div>
                  <div style="box-sizing: border-box;padding: 10px;background-color: #F5C543;width: 30%;font-size: 10px;">
                    <div>{{ hydrogenConsumeInfo.errorNum || 0 }}次</div>
                    <div>发现异常</div>
                  </div>
                  <div style="box-sizing: border-box;padding: 10px;background-color: #5FCFF9;width: 30%;font-size: 10px;">
                    <div>{{ hydrogenConsumeInfo.warningNum || 0 }}个</div>
                    <div>未及时巡检</div>
                  </div>
                </div>
                <div style="font-size: 10px;color: #5ECFAB;margin-top: 20px;margin-bottom: 10px;">本周设备巡检预警</div>
                <div style="display: flex;align-items: center;justify-content: space-between;">
                  <div v-for="(item, index) of inspectionList" :key="index + 'inspection'" style="width: calc(50% - 5px);box-sizing: border-box;padding: 10px;background-color: #152730;border: 1px solid #3C8173">
                    <div style="display: flex;align-items: center;justify-content: space-between;font-size: 8px;color: #63D8B2;">
                      <span>{{ item.stationName }}</span>
<!--                      <span>#123232</span>-->
                    </div>
                    <div style="display: flex;align-items: center;justify-content: space-between;">
                      <div style="font-size: 8px;margin-top: 3px;">
                        <div>计划：{{ isBlank(item.planTime) ? '--' : dayjs(item.planTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
                        <div>执行：{{ isBlank(item.execTime) ? '--' : dayjs(item.execTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
                      </div>
                      <img :src="require('./img/用户.png')" style="width: 20px;height: auto;"/>
                    </div>
                    <div style="font-size: 8px;margin-top: 3px;">{{ item.status }}</div>
                    <div style="font-size: 8px;margin-top: 3px;">{{ item.username }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref="map" style="width: 100%;height: calc(61% - 10px);border: 0.5px solid #69E5B7;margin-top: 10px;"></div>
        </div>
        <!-- 右侧 -->
        <div style="height: 100%;width: calc(25% - 20px);margin-right: 20px;">
          <!-- 左边 top -->
          <div ref="rightTopRef" style="position: relative;width: 100%;height: calc(30% - 10px);">
            <canvas ref="rightTopCanvasRef" class="horn"/>
            <div style="box-sizing: border-box;padding: 10px;z-index: 2;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">
              <div style="height: 16px;overflow-y: hidden;justify-content: space-between;font-size: 12px;width: 100%;display: flex;align-items: center;">
                <div style="display: flex;align-items: flex-end;justify-content: space-between;">
                  <div style="display: flex;align-items: center;">
                    <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                    区域平均价格
                  </div>
                  <span style="font-size: 8px;">元/kg</span>
                </div>
                <span style="font-size: 10px;color: #919797">统计日期：{{ nowDateStr }}</span>
              </div>
              <div style="height: calc(100% - 16px);width: 100%;overflow: hidden;position: relative;">
                <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;"
                     :style="{
                        opacity: averagePriceEchartData.length === 0 ? 0 : 1
                       }"
                     ref="averagePriceEcharts"/>
                <div v-if="averagePriceEchartData.length === 0"
                     style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: 100000000;color: #808695;display: flex;align-items: center;justify-content: center;">
                  暂无数据
                </div>
              </div>
            </div>
          </div>
          <!-- 左边 top -->
          <div ref="rightMiddleRef" style="position: relative;width: 100%;height: calc(70% - 10px);margin-top: 10px;">
            <canvas ref="rightMiddleCanvasRef" class="horn"/>
            <div style="box-sizing: border-box;padding: 10px;z-index: 2;width: 100%;height: 100%;position: absolute;left: 0;top: 0;">
              <div style="height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                合作伙伴
              </div>
              <div style="font-size: 14px;color: #999F9F;line-height: 18px;margin-top: 10px;">
                氢驰出行新能源凭借整合优势资源，向行业内优秀企业借鉴的宗旨，积极拓展对外合作，在 制氢储能、制氢加氢站、车辆营运、化工销售 等多个领域寻求合作机会，目前已与国内外，多家知名企业建立合作关系
              </div>
              <div style="margin-top: 10px;display: flex;align-items: center;flex-flow: row wrap;">
                <img v-for="(img, index) of brands" :key="index + 'brand'"
                     :src="img.logo"
                     style="border-radius: 4px;width: 40px;height: 40px;margin-right: 10px;margin-bottom: 10px;" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import dayjs from 'dayjs'
import { listArea } from '@/api/common'
import {
  listStationData,
  listStationNatureData,
  listStationTypeData,
  listStationPriceData,
  listHydrogenConsume,
  getSafeManageCount,
  listInspectionData,
  listMaintainEarlyWarning,
  listStation
} from '@/api/hydrogen/station'
import {countCompany} from '@/api/vehicle'
import isBlank from "@/utils/isBlank";

const endTime = dayjs(new Date()).format('YYYY')
const startTime = dayjs(new Date()).subtract(5, 'year').format('YYYY')

let intervalRes
let intervalDate
let map
export default {
  name: "hydrogen-refueling-station-operation",
  data() {
    return {
      searchVal: null,
      show: false,
      nowDate: dayjs().format('YYYY年MM月DD日 HH:mm:ss'),
      leftTopStyle: {
        width: 100,
        height: 100
      },

      hydrogenStationParam: {
        type: '1',
        date: [startTime, endTime]
      },
      averagePriceParam: {
        type: '1',
        date: [startTime, endTime]
      },

      areaEcharts: null,
      areaCode: {
        code: null,
        name: null
      },
      brands: [],
      hydrogenConsumeInfo: {
        errorNum: 0,
        normalNum: 0,
        warningNum: 0
      },
      safeManageCountInfo: {
        expireNum: 0,
        sevenDayNum: 0,
        thirtyDayNum: 0
      },
      inspectionList: [],
      maintainEarlyWarningList: [],
      hydrogenStationEchartData: [],
      averagePriceEchartData: [],
      areaCodeList: []
    }
  },
  created() {
    document.title = '加氢站运营实时监控'
    intervalDate = setInterval(() => {
      this.nowDate = dayjs().format('YYYY年MM月DD日 HH:mm:ss')
    }, 1000)
    window.addEventListener('resize', this.reLoad)
  },
  computed: {
    nowDateStr() {
      return dayjs().format('YYYY-MM-DD')
    }
  },
  async mounted() {
    // await this.listArea()
    this.init()
    this.leftTopStyle.width = this.$refs.leftTopRef.offsetWidth
    this.leftTopStyle.height = this.$refs.leftTopRef.offsetHeight
    this.$nextTick(() => {
      this.show = true
    })
  },
  beforeDestroy() {
    this.clearEcharts()
    if (intervalRes) {
      clearInterval(intervalRes)
    }
    if (intervalDate) {
      clearInterval(intervalDate)
    }
    window.removeEventListener('resize', this.reLoad)
  },
  methods: {
    isBlank,
    dayjs,
    fullScan() {
      if (screenfull.isFullscreen) {
        screenfull.exit()
      } else {
        screenfull.request()
      }
    },
    clearEcharts() {

    },
    reLoad() {
      this.clearEcharts()
      this.$nextTick(() => {
        setTimeout(this.init, 100)
      })
    },
    async init() {
      this.initHydrogenStationEcharts()
      this.drawLeftTopBoxBorder()
      this.drawLeftMiddleBoxBorder()
      this.drawLeftBottomBoxBorder()
      this.drawRightTopBoxBorder()
      this.drawRightMiddleBoxBorder()
      this.drawMiddleLeftBoxBorder()
      this.drawMiddleRightBoxBorder()

      this.initMap()
      this.initAreaEcharts()
      this.initcarNumEcharts()
      this.initAveragePriceEcharts()
      this.countCompany()
      this.listHydrogenConsume()
      this.getSafeManageCount()
      this.listInspectionData()
      this.listMaintainEarlyWarning()
    },
    drawLeftTopBoxBorder() {
      const height = this.$refs.leftTopRef.offsetHeight
      const width = this.$refs.leftTopRef.offsetWidth
      // hornRef
      let canvas = this.$refs.leftTopCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      context.moveTo(0,0);
      context.lineTo(0, height);

      context.moveTo(0, height);
      context.lineTo(width, height);

      // 右边线段
      context.moveTo(width, height);
      context.lineTo(width, 10);

      // 顶部线段
      context.moveTo(0, 0);
      context.lineTo(width - 10, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(width - 10, 0);
      context.lineTo(width, 10);
      context.stroke();
    },
    drawLeftMiddleBoxBorder() {
      const height = this.$refs.leftMiddleRef.offsetHeight
      const width = this.$refs.leftMiddleRef.offsetWidth
      let canvas = this.$refs.leftMiddleCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      context.moveTo(0,0);
      context.lineTo(0, height);

      context.moveTo(0, height);
      context.lineTo(width, height);

      // 右边线段
      context.moveTo(width, height);
      context.lineTo(width, 0);

      // 顶部线段
      context.moveTo(0, 0);
      context.lineTo(width , 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(width, 0);
      context.lineTo(width, 0);
      context.stroke();
    },
    drawLeftBottomBoxBorder() {
      const height = this.$refs.leftBottomRef.offsetHeight
      const width = this.$refs.leftBottomRef.offsetWidth
      // hornRef
      let canvas = this.$refs.leftBottomCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      // left
      context.moveTo(0,0);
      context.lineTo(0, height);

      // bottom
      context.moveTo(0, height);
      context.lineTo(width - 30, height);

      // right
      context.moveTo(width, 0);
      context.lineTo(width, height - 30);

      // top
      context.moveTo(0, 0);
      context.lineTo(width , 0);
      context.stroke();

      context.lineWidth = 0.8
      context.moveTo(width - 30, height);
      context.lineTo(width, height - 30);
      context.stroke();
    },
    drawRightTopBoxBorder() {
      const height = this.$refs.rightTopRef.offsetHeight
      const width = this.$refs.rightTopRef.offsetWidth
      // hornRef
      let canvas = this.$refs.rightTopCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      // left
      context.moveTo(0,height);
      context.lineTo(0, 10);

      // bottom
      context.moveTo(0, height);
      context.lineTo(width, height);

      // right
      context.moveTo(width, height);
      context.lineTo(width, 0);

      // top
      context.moveTo(width, 0);
      context.lineTo(10, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(10, 0);
      context.lineTo(0, 10);
      context.stroke();
    },
    drawRightMiddleBoxBorder() {
      const height = this.$refs.rightMiddleRef.offsetHeight
      const width = this.$refs.rightMiddleRef.offsetWidth
      // hornRef
      let canvas = this.$refs.rightMiddleCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      // left
      context.moveTo(0,0);
      context.lineTo(0, height - 30);

      // bottom
      context.moveTo(width, height);
      context.lineTo(30, height);

      // right
      context.moveTo(width, height);
      context.lineTo(width, 0);

      // top
      context.moveTo(width, 0);
      context.lineTo(0, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(0, height - 30);
      context.lineTo(30, height);
      context.stroke();
    },
    drawMiddleLeftBoxBorder() {
      const height = this.$refs.middleLeftRef.offsetHeight
      const width = this.$refs.middleLeftRef.offsetWidth
      // hornRef
      let canvas = this.$refs.middleLeftCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      // left
      context.moveTo(0,height);
      context.lineTo(0, 20);

      // bottom
      context.moveTo(width, height);
      context.lineTo(0, height);

      // right
      context.moveTo(width, height);
      context.lineTo(width, 0);

      // top
      context.moveTo(width, 0);
      context.lineTo(20, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(20, 0);
      context.lineTo(0, 20);
      context.stroke();
    },
    drawMiddleRightBoxBorder() {
      const height = this.$refs.middleRightRef.offsetHeight
      const width = this.$refs.middleRightRef.offsetWidth
      // hornRef
      let canvas = this.$refs.middleRightCanvasRef
      canvas.width = width
      canvas.height = height

      let context = canvas.getContext('2d');
      //用绝对路标来创建一条路径
      context.strokeStyle = '#69E5B7'
      context.beginPath();
      context.lineWidth = 1.3
      // left
      context.moveTo(0,0);
      context.lineTo(0, height);

      // bottom
      context.moveTo(width, height);
      context.lineTo(0, height);

      // right
      context.moveTo(width, height);
      context.lineTo(width, 20);

      // top
      context.moveTo(0, 0);
      context.lineTo(width - 20, 0);
      context.stroke();

      // 画斜边
      context.lineWidth = 0.8
      context.moveTo(width - 20, 0);
      context.lineTo(width, 20);
      context.stroke();
    },

    // 加氢站数量
    async initHydrogenStationEcharts() {
      const res = await this.$request(listStationData({
        areaCode: this.areaCode.code,
        areaType: this.areaCode.level
      }))
      this.hydrogenStationEchartData = res
      if (!this.hydrogenStationEcharts) {
        this.hydrogenStationEcharts = this.$echarts.init(this.$refs.hydrogenStationEcharts);
      }
      this.hydrogenStationEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '3%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'bar'
          }
        ]
      })
    },
    // 区域平均价格
    async initAveragePriceEcharts() {
      const res = await this.$request(listStationPriceData({
        areaCode: this.areaCode.code,
        areaType: this.areaCode.level
      }))
      this.averagePriceEchartData = res
      if (!this.averagePriceEcharts) {
        this.averagePriceEcharts = this.$echarts.init(this.$refs.averagePriceEcharts);
      }
      this.averagePriceEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '3%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'bar'
          }
        ]
      })
    },
    // 加氢类型分布
    async initAreaEcharts() {
      const res = await this.$request(listStationTypeData({
        areaCode: this.areaCode.code,
        areaType: this.areaCode.level
      }))
      if (!this.areaEcharts) {
        this.areaEcharts = this.$echarts.init(this.$refs.areaEcharts);
      }
      let totalNum = res.reduce((total, row) => total + Number(row.num), 0)
      this.areaEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        title: {
          show: true,
          textVerticalAlign: 'center',
          textAlign: 'center',
          text:  totalNum + '\n加氢压强',
          left: '49%',
          textStyle: {
            color: '#fff',
            fontSize: 14,
          },
          top: '48%',
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'outside',
              formatter: '{b} {d}%'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 15,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: res.map(row => {
              return {
                value: row.num,
                name: row.name,
                label: {
                  formatter: [
                    '{name|{b}} {per|{d}%}',
                  ].join('\n'),
                  rich: {
                    name: {
                      fontSize: 8,
                    },
                    per: {
                      color: '#fff',
                      fontSize: 8,
                    }
                  }
                }
              }
            })
          }
        ]
      })
    },
    // 加氢站分布
    async initcarNumEcharts() {
      const res = await this.$request(listStationNatureData({
        areaCode: this.areaCode.code,
        areaType: this.areaCode.level
      }))
      if (!this.carNumEcharts) {
        this.carNumEcharts = this.$echarts.init(this.$refs.carNumEcharts);
      }
      let totalNum = res.reduce((total, row) => total + Number(row.num), 0)
      this.carNumEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        title: {
          show: true,
          textVerticalAlign: 'center',
          textAlign: 'center',
          text:  totalNum + '\n加氢站',
          left: '49%',
          textStyle: {
            color: '#fff',
            fontSize: 14,
          },
          top: '48%',
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'outside',
              formatter: '{b} {d}%'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 15,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: res.map(row => {
              return {
                value: row.num,
                name: row.name,
                label: {
                  formatter: [
                    '{name|{b}} {per|{d}%}',
                  ].join('\n'),
                  rich: {
                    name: {
                      fontSize: 8,
                    },
                    per: {
                      color: '#fff',
                      fontSize: 8,
                    }
                  }
                }
              }
            })
          }
        ]
      })
    },
    // 初始化地图
    async initMap() {
      const res = await this.$request(listStation({
        areaType: this.areaCode.level,
        areaCode: this.areaCode.code
      }))
      const location = res.filter(row => {
        return !isBlank(row.lat) && !isBlank(row.lng)
      })
      let latCenter = this.areaCode?.lat || this.areaCode?.location?.lat || 23.130061
      let lngCenter = this.areaCode?.lng || this.areaCode?.location?.lng || 113.264499

      if (location.length !== 0) {
        latCenter = location.reduce((total, row) => total + Number(row.lat),0) / location.length
        lngCenter = location.reduce((total, row) => total + Number(row.lng),0) / location.length
      }

      const center = new this.$TMap.LatLng(latCenter, lngCenter);
      if (map) {
        map.destroy()
      }

      map = new this.$TMap.Map(this.$refs.map, {
        zoom: 10,
        center,
        mapStyleId: 'style1',
      });
      new this.$TMap.MultiMarker({
        map,
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          "myStyle": new this.$TMap.MarkerStyle({
            "width": 35,  // 点标记样式宽度（像素）
            "height": 35, // 点标记样式高度（像素）
            "src": require('./img/车辆定位.png'),  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            "anchor": { x: 16, y: 32 }
          })
        },
        //点标记数据数组
        geometries: location.map(row => {
          return {
            "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            "styleId": 'myStyle',  //指定样式id
            "position": new this.$TMap.LatLng(row.lat, row.lng),  //点标记坐标位置
            "properties": { //自定义属性
              "title": "marker1"
            }
          }
        })
      })
    },
    // 安全巡检
    async listHydrogenConsume() {
      const res = await this.$request(listHydrogenConsume({
        areaCode: this.areaCode.code,
        areaType: this.areaCode.level
      }))
      Object.assign(this.hydrogenConsumeInfo, res)
    },
    // 安全管理
    async getSafeManageCount() {
      const res = await this.$request(getSafeManageCount({
        areaCode: this.areaCode.code,
        areaType: this.areaCode.level
      }))
      Object.assign(this.safeManageCountInfo, res)
    },
    // 本周巡检预警
    async listInspectionData() {
      const res = await this.$request(listInspectionData({
        areaCode: this.areaCode.code,
        areaType: this.areaCode.level
      }, {
        limit: 2
      }))
      this.inspectionList = res
    },
    // 特种设备保养到期预警
    async listMaintainEarlyWarning() {
      const res = await this.$request(listMaintainEarlyWarning({
        areaCode: this.areaCode.code,
        areaType: this.areaCode.level
      }, {
        limit: 3
      }))
      this.maintainEarlyWarningList = res
    },

    // 获取区域列表
    async listArea(keyword) {
      const res = await this.$request(listArea({
        name: keyword,
        limit: 1
      }))
      this.areaCodeList = res.map(row => {
        return Object.assign({}, row, {
          label: row.fullname,
          value: row.code
        })
      })
      if (res.length !== 0) {
        this.areaCode = res[0]
      } else {
        this.$message.warning("未查询到相关区域")
      }
    },
    // 合作伙伴
    async countCompany() {
      const { brands } = await this.$request(countCompany({}))
      this.brands = brands.filter(row => row.logo)
    },
    // 选择后的回调
    selectRegionCb(arr) {
      let array = this.$refs.regionRef.regionTree
      let res = null
      arr.forEach(id => {
        res = array.find(row => row.id === id)
        array = res.children
      })

      if (res == null) {
        this.areaCode.name = null
        this.areaCode.code = null
      } else {
        Object.assign(this.areaCode, res);
      }
      this.init();
    }
  }
}
</script>

<style lang="stylus" scoped>
.padding_box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: white;
}
.content_box {
  width: 100%;
  display: flex;
  height: calc(100% - 100px);
  align-items: flex-start;
}
.input_search {
  height: 100%;width: 100%;border: none;background-color: transparent;
  color: white;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 40px;
}
.input_search:focus {
  border: none !important;
  outline: none;
}
.search_icon {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}
.horn {
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 2;
  background-color: #282c34;
}
</style>
