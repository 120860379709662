import Demonstration from './pages/demonstration/index.vue'
import Emergency from './pages/emergency/index.vue'
import VehicleData from './pages/vehicle-data/index.vue'
import SafetySupervision from './pages/safety-supervision/index.vue'
import HydrogenRefuelingStationOperation from './pages/hydrogen-refueling-station-operation/index.vue'
import HydrogenRefuelingStationDynamics from './pages/hydrogen-refueling-station-dynamics/index.vue'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  { path: '/', redirect: '/demonstration' },
  { path: '/demonstration', component: Demonstration },
  { path: '/emergency', component: Emergency },
  { path: '/vehicle-data', component: VehicleData },
  { path: '/safety-supervision', component: SafetySupervision },
  { path: '/hydrogen-refueling-station-operation', component: HydrogenRefuelingStationOperation },
  { path: '/hydrogen-refueling-station-dynamics', component: HydrogenRefuelingStationDynamics },
]


export default new Router({
  mode: 'history',
  routes: routes
})
