import Vue from 'vue'
import App from './App.vue'
import dataV from '@jiaminghi/data-view'
import router from './router'
import TopHeader from './components/top-header/index.vue'
import SelectDateType from './components/select-date-type/index.vue'
import SelectDate from './components/select-date/index.vue'
import QcSelect from './components/qc-select/index.vue'
import * as echarts from 'echarts'
import Element from 'element-ui'
import './assets/styles/element-variables.scss'
import './assets/styles/index.scss'
import request from './utils/request'
import SelectRegion from './components/select-region/index.vue'

Vue.use(Element, {
  size: 'mini' // set element-ui default size
})

Vue.config.productionTip = false
Vue.use(dataV)
Vue.component(TopHeader.name, TopHeader)
Vue.component(SelectDateType.name, SelectDateType)
Vue.component(SelectDate.name, SelectDate)
Vue.component(QcSelect.name, QcSelect)
Vue.component(SelectRegion.name, SelectRegion)
Vue.prototype.$echarts = echarts
Vue.prototype.$request = request
// eslint-disable-next-line no-undef
Vue.prototype.$TMap = TMap

new Vue({
  render: h => h(App),
  router
}).$mount('#app')

// const element = document.getElementsByTagName('body')[0]
//
// if (element.requestFullscreen) {
//   element.requestFullscreen();
// } else if (element.mozRequestFullScreen) { // Firefox
//   element.mozRequestFullScreen();
// } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
//   element.webkitRequestFullscreen();
// } else if (element.msRequestFullscreen) { // Internet Explorer and Edge
//   element.msRequestFullscreen();
// }



