export function listHydrogenConsume(data) {
  return {
    url: `/hydrogen/station/getInspectionCount`,
    method: 'post',
    data
  }
}

export function getSafeManageCount(data) {
  return {
    url: `/hydrogen/station/getSafeManageCount`,
    method: 'post',
    data
  }
}

export function listInspectionData(data, params) {
  return {
    url: `/hydrogen/station/listInspectionData`,
    method: 'post',
    data,
    params
  }
}

export function listMaintainEarlyWarning(data, params) {
  return {
    url: `/hydrogen/station/listMaintainEarlyWarning`,
    method: 'post',
    data,
    params
  }
}

export function listStation(data, params) {
  return {
    url: `/hydrogen/station/listStation`,
    method: 'post',
    data,
    params
  }
}

export function listStationData(data) {
  return {
    url: `/hydrogen/station/listStationData`,
    method: 'post',
    data
  }
}

export function listStationNatureData(data) {
  return {
    url: `/hydrogen/station/listStationNatureData`,
    method: 'post',
    data
  }
}

export function listStationPriceData(data) {
  return {
    url: `/hydrogen/station/listStationPriceData`,
    method: 'post',
    data
  }
}

export function listStationTypeData(data) {
  return {
    url: `/hydrogen/station/listStationTypeData`,
    method: 'post',
    data
  }
}
