export function getBatchInfo(params) {
  return {
    url: `/common/getBatchInfo`,
    method: 'get',
    params
  }
}

export function listArea(params) {
  return {
    url: `/common/listArea`,
    method: 'get',
    params
  }
}

export function listPlateNumber(params) {
  return {
    url: `/common/listPlateNumber`,
    method: 'get',
    params
  }
}
