<template>
  <div style="width: 100%;height: 100%">
    <div v-if="show" class="padding_box">
      <top-header @click.native="fullScan" style="color: #70EFBD" title="示范运营智慧平台" />
      <div style="color: #6DE9B9;position: fixed;left: 400px;top: 60px;">当前批次：{{ batchInfo.batchNo }}</div>
      <div style="color: #6DE9B9;position: fixed;right: 350px;top: 60px;">{{ nowDate }}</div>
      <div class="content_box">
        <!-- 左侧 -->
        <div style="width: 25%;height: 100%;box-sizing: border-box;">
          <qc-select
              popper-append-to-body
              style="width: calc(100% - 30px);margin-left: 20px;margin-bottom: 10px;"
              @change="(val) => {
                 if (val) {
                   Object.assign(batchInfo, batchList.find(row => row.batchNo === val));
                   init();
                 }
              }"
              filterable
              remote
              :remote-method="getBatchInfo"
              :options="batchList"
              placeholder="请输入车牌号"
              v-model="batchInfo.batchNo"/>
          <div style="height: 60%;overflow-y: hidden;padding: 15px;box-sizing: border-box;width: calc(100% - 30px);margin-left: 20px;border-radius: 5px;border: 1px solid #69E5B7">
            <div style="height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;margin-bottom: 10px;">
              <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>运营数据
            </div>
            <div style="height: 40%;overflow-y: hidden;width: 100%;display: flex;flex-flow: row wrap;justify-content: space-between">
              <div style="font-size: 25px;margin-bottom: 2%;box-sizing: border-box;box-shadow: 0 0 10px #ff6200 inset;border-radius: 10px;width: calc(50% - 5px);display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                <div style="font-size: 1em;line-height: 1em;font-weight: bolder;color: #FFA100;">
                  {{ operationsDataInfo.vehicleNum }}
                </div>
                <span style="font-size: 0.3em;line-height: 0.3em;margin-top: 6px;">当前批次车辆总数</span>
              </div>
              <div style="font-size: 25px;margin-bottom: 2%;box-sizing: border-box;box-shadow: 0 0 10px #ff6200 inset;border-radius: 10px;width: calc(50% - 5px);display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                <div style="font-size: 1em;line-height: 1em;font-weight: bolder;color: #FFA100;">
                  {{ operationsDataInfo.driveMileage }}
                  <span style="font-size: 10px;">km</span>
                </div>
                <span style="font-size: 0.3em;line-height: 0.3em;margin-top: 6px;">今日行驶里程总数</span>
              </div>
              <div style="font-size: 25px;margin-bottom: 2%;box-sizing: border-box;box-shadow: 0 0 10px #ff6200 inset;border-radius: 10px;width: calc(50% - 5px);display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                <div style="font-size: 20px;font-weight: bolder;color: #FFA100;">
                  {{ operationsDataInfo.totalMileage }}
                  <span style="font-size: 10px;">km</span>
                </div>
                <span style="font-size: 0.3em;line-height: 0.3em;margin-top: 6px;">总里程</span>
              </div>
              <div style="font-size: 25px;margin-bottom: 2%;box-sizing: border-box;box-shadow: 0 0 10px #ff6200 inset;border-radius: 10px;width: calc(50% - 5px);display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                <div style="font-size: 20px;font-weight: bolder;color: #FFA100;">
                  {{ operationsDataInfo.totalCo2 }}
                  <span style="font-size: 10px;">kg</span>
                </div>
                <span style="font-size: 0.3em;line-height: 0.3em;margin-top: 6px;">减碳</span>
              </div>
              <div style="font-size: 25px;margin-bottom: 2%;box-sizing: border-box;box-shadow: 0 0 10px #ff6200 inset;border-radius: 10px;width: calc(50% - 5px);display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                <div style="font-size: 20px;font-weight: bolder;color: #FFA100;">
                  {{ operationsDataInfo.adviceNum }}
                </div>
                <span style="font-size: 0.3em;line-height: 0.3em;margin-top: 6px;">技改建议条数</span>
              </div>
              <div style="font-size: 25px;margin-bottom: 2%;box-sizing: border-box;box-shadow: 0 0 10px #ff6200 inset;border-radius: 10px;width: calc(50% - 5px);display: flex;align-items: center;justify-content: center;flex-flow: column nowrap;">
                <div style="font-size: 20px;font-weight: bolder;color: #FFA100;">
                  {{ operationsDataInfo.reportPercent }}
                  <span style="font-size: 12px;">%</span>
                </div>
                <span style="font-size: 0.3em;line-height: 0.3em;margin-top: 6px;">分析报告出具及时达成率</span>
              </div>
            </div>
            <div style="height: calc(60% - 16px);overflow-y: hidden;width: 100%;display: flex;flex-flow: row wrap;justify-content: space-between">
              <div style="width: 50%;height: 50%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="width: 100%;height: 100%;box-sizing: border-box;padding-top: 10px;">
                  <div style="width: 100%;height: 100%;" ref="safetyInspectionRateEcharts"/>
                </div>
                <div style="font-size: 12px;color: #FFFFFF;position: absolute;left: 0;bottom: 0;width: 100%;display: flex;align-items: center;justify-content: center;">
                  安全检查率
                </div>
              </div>
              <div style="width: 50%;height: 50%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="width: 100%;height: 100%;box-sizing: border-box;padding-top: 10px;">
                  <div style="width: 100%;height: 100%;" ref="floorAreaRateEcharts"/>
                </div>
                <div style="font-size: 12px;color: #FFFFFF;position: absolute;left: 0;bottom: 0;width: 100%;display: flex;align-items: center;justify-content: center;">
                  车容检查达成率
                </div>
              </div>
              <div style="width: 50%;height: 50%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="width: 100%;height: 100%;box-sizing: border-box;padding-top: 10px;">
                  <div style="width: 100%;height: 100%;" ref="maintenanceRateEcharts"/>
                </div>
                <div style="font-size: 12px;color: #FFFFFF;position: absolute;left: 0;bottom: 0;width: 100%;display: flex;align-items: center;justify-content: center;">
                  及时保养达成率
                </div>
              </div>
              <div style="width: 50%;height: 50%;position: relative;display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="width: 100%;height: 100%;box-sizing: border-box;padding-top: 10px;">
                  <div style="width: 100%;height: 100%;" ref="serviceRateEcharts"/>
                </div>
                <div style="font-size: 12px;color: #FFFFFF;position: absolute;left: 0;bottom: 0;width: 100%;display: flex;align-items: center;justify-content: center;">
                  维修达成率
                </div>
              </div>
            </div>
          </div>
          <div style="height: calc(40% - 60px);overflow-y: hidden;position: relative;margin-top: 10px;padding: 15px;box-sizing: border-box;width: calc(100% - 30px);margin-left: 20px;border-radius: 5px;border: 1px solid #69E5B7">
            <div style="height: 16px;overflow-y: hidden;z-index: 1000;width: 100%;display: flex;align-items: center;margin-bottom: 10px;justify-content: space-between;">
              <div style="font-size: 12px;display: flex;align-items: center">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                行驶里程
                <div style="margin-left: 4px;display: flex;align-items: flex-end;height: 15px;font-size: 8px;color: #89A9D3">(km)</div>
              </div>
              <select-date style="width: calc(100% - 100px)"
                           @date-change="$nextTick(initMileageDrivenEcharts)"
                           :date-type.sync="listDriverMileageParam.type"
                           :date.sync="listDriverMileageParam.date" />
            </div>
            <div style="height: calc(100% - 16px);width: 100%;overflow: hidden">
              <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;" ref="mileageDrivenEcharts"/>
            </div>
          </div>
        </div>
        <!-- 中间 -->
        <div style="width: 50%;height: 100%;box-sizing: border-box;">
          <div style="height: 215px;overflow-y: hidden;padding: 15px;box-sizing: border-box;width: 100%;border-radius: 5px;border: 1px solid #69E5B7;">
            <div style="font-size: 12px;width: 100%;display: flex;align-items: center;margin-bottom: 10px;">
              <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>预警
            </div>
            <div style="width: 100%;display: flex;flex-flow: row wrap;justify-content: space-between">
              <div style="height: 74px;margin-bottom: 10px;padding: 10px;box-sizing: border-box;width: calc(50% - 5px);border-radius: 5px;border: 1px solid #5DCC8F">
                <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
                  <span style="font-size: 12px;color: white;font-weight: bold;">车辆跨区域预警</span>
                  <span style="font-size: 12px;color:#5DCC8F;">{{ listAreaEarlyData.num }}辆</span>
                </div>
                <div v-for="(item, index) of listAreaEarlyData.dataList"
                     :key="index + 'listAreaEarlyData'"
                     style="display: flex;align-items: center;font-size: 10px;margin-top: 5px;">
                  <span style="color: #5DCC8F;margin-right: 2px;">{{ item.plateNumber }}</span>{{ item.desc }}
                </div>
                <div v-if="listAreaEarlyData.dataList.length === 0" style="font-size: 10px;color: #C0C4CC;width: 100%;height: calc(100% - 16.88px);display: flex;align-items: center;justify-content: center">
                  无数据
                </div>
              </div>
              <div style="height: 74px;margin-bottom: 10px;padding: 10px;box-sizing: border-box;width: calc(50% - 5px);border-radius: 5px;border: 1px solid #5DCC8F">
                <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
                  <span style="font-size: 12px;color: white;font-weight: bold;">超期年审预警</span>
                  <span style="font-size: 12px;color:#5DCC8F;">{{ listAnnualReviewEarlyData.num }}辆</span>
                </div>
                <div v-for="(item, index) of listAnnualReviewEarlyData.dataList"
                     :key="index + 'listAnnualReviewEarlyData'"
                     style="display: flex;align-items: center;font-size: 10px;margin-top: 5px;">
                  <span style="color: #5DCC8F;margin-right: 2px;">{{ item.plateNumber }}</span>{{ item.desc }}
                </div>
                <div v-if="listAnnualReviewEarlyData.dataList.length === 0" style="font-size: 10px;color: #C0C4CC;width: 100%;height: calc(100% - 16.88px);display: flex;align-items: center;justify-content: center">
                  无数据
                </div>
              </div>
              <div style="height: 74px;padding: 10px;box-sizing: border-box;width: calc(50% - 5px);border-radius: 5px;border: 1px solid #5DCC8F">
                <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
                  <span style="font-size: 12px;color: white;font-weight: bold;">超期保险预警</span>
                  <span style="font-size: 12px;color:#5DCC8F;">{{ listInsuranceEarlyData.num }}辆</span>
                </div>
                <div v-for="(item, index) of listInsuranceEarlyData.dataList"
                     :key="index + 'listInsuranceEarlyData'"
                     style="display: flex;align-items: center;font-size: 10px;margin-top: 5px;">
                  <span style="color: #5DCC8F;margin-right: 2px;">{{ item.plateNumber }}</span>{{ item.desc }}
                </div>
                <div v-if="listInsuranceEarlyData.dataList.length === 0" style="font-size: 10px;color: #C0C4CC;width: 100%;height: calc(100% - 16.88px);display: flex;align-items: center;justify-content: center">
                  无数据
                </div>
              </div>
              <div style="height: 74px;padding: 10px;box-sizing: border-box;width: calc(50% - 5px);border-radius: 5px;border: 1px solid #5DCC8F">
                <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
                  <span style="font-size: 12px;color: white;font-weight: bold;">供氢系统泄漏预警</span>
                  <span style="font-size: 12px;color:#5DCC8F;">{{ listHydrogenSysEarlyData.num }}辆</span>
                </div>
                <div v-for="(item, index) of listHydrogenSysEarlyData.dataList"
                     :key="index + 'listHydrogenSysEarlyData'"
                     style="display: flex;align-items: center;font-size: 10px;margin-top: 5px;">
                  <span style="color: #5DCC8F;margin-right: 2px;">{{ item.plateNumber }}</span>{{ item.desc }}
                </div>
                <div v-if="listHydrogenSysEarlyData.dataList.length === 0" style="font-size: 10px;color: #C0C4CC;width: 100%;height: calc(100% - 16.88px);display: flex;align-items: center;justify-content: center">
                  无数据
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%;height: calc(100% - 215px);display: flex;align-items: flex-start;justify-content: space-between;">
            <div style="width: calc(45% - 5px);height: calc(100% - 10px);">
              <div style="height: calc(33.333% - 10px);margin-top: 10px;position: relative;font-size: 12px;padding: 15px;box-sizing: border-box;width: 100%;border-radius: 5px;border: 1px solid #69E5B7">
                <div style="height: 16px;z-index: 1000;width: 100%;display: flex;align-items: center;justify-content: space-between;">
                  <div style="display: flex;align-items: center">
                    <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                    里程达成率
                  </div>
                  <select-date style="width: calc(100% - 100px)"
                               @date-change="$nextTick(initMileageAchievementRateEcharts)"
                               :date-type.sync="listTargetMileagePercentParam.type"
                               :date.sync="listTargetMileagePercentParam.date" />
                </div>
                <div style="width: 100%;height: calc(100% - 16px);overflow-y: hidden;">
                  <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;" ref="mileageAchievementRateEcharts"/>
                </div>
              </div>
              <div style="height: calc(33.333% - 10px);margin-top: 10px;position:relative;;padding: 15px;box-sizing: border-box;width: 100%;border-radius: 5px;border: 1px solid #69E5B7">
                <div style="height: 16px;z-index: 1000;justify-content: space-between;font-size: 12px;width: 100%;display: flex;align-items: center;">
                  <div style="display: flex;align-items: center;">
                    <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                    轨迹达成率
                  </div>
                  <select-date style="width: calc(100% - 100px)"
                               @date-change="$nextTick(initTrajectoryAchievementRateEcharts)"
                               :date-type.sync="listTrajectoryParam.type"
                               :date.sync="listTrajectoryParam.date" />
                </div>
                <div style="z-index: 0;width: 100%;height: calc(100% - 16px);">
                  <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;" ref="trajectoryAchievementRateEcharts"/>
                </div>
              </div>
              <div style="height: calc(33.333% - 10px);margin-top: 10px;position: relative;padding: 15px;box-sizing: border-box;width: 100%;border-radius: 5px;border: 1px solid #69E5B7">
                <div style="height: 16px;z-index: 1000;justify-content: space-between;;font-size: 12px;width: 100%;display: flex;align-items: center;">
                  <div style="display: flex;align-items: center;">
                    <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                    里程数据丢失率
                  </div>
                  <select-date style="width: calc(100% - 100px)"
                               :top="0"
                               :bottom="10"
                               @date-change="$nextTick(initMileageDataLossRateEchartsEcharts)"
                               :date-type.sync="listMileageLossParam.type"
                               :date.sync="listMileageLossParam.date" />
                </div>
                <div style="z-index: 0;width: 100%;height: calc(100% - 16px)">
                  <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;" ref="mileageDataLossRateEcharts"/>
                </div>
              </div>
            </div>
            <div style="width: calc(55% - 5px);height: calc(100% - 10px);">
              <div style="height: calc(50% - 10px);position: relative;margin-top: 10px;padding: 15px;box-sizing: border-box;width: 100%;border-radius: 5px;border: 1px solid #69E5B7">
                <div style="height: 16px;z-index: 1000;justify-content: space-between;font-size: 12px;width: 100%;display: flex;align-items: center;">
                  <div style="display: flex;align-items: center;">
                    <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                    氢耗情况
                  </div>
                  <select-date style="width: calc(100% - 100px)"
                               @date-change="$nextTick(initHydrogenConsumptionSituationEchartsEcharts)"
                               :date-type.sync="listHydrogenDataParam.type"
                               :date.sync="listHydrogenDataParam.date" />
                </div>
                <div style="width: 100%;height: calc(100% - 16px);overflow-y: hidden;">
                  <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;" ref="hydrogenConsumptionSituationEcharts"/>
                </div>
              </div>
              <div style="height: calc(50% - 10px);overflow-y: hidden;margin-top: 10px;padding: 15px;box-sizing: border-box;width: 100%;border-radius: 5px;border: 1px solid #69E5B7">
                <div style="height: 16px;overflow-y: hidden;font-size: 12px;width: 100%;display: flex;align-items: center;">
                  <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                  平均氢耗占比情况
                </div>
                <div style="width: 100%;height: calc(100% - 16px);" ref="averageHydrogenConsumptionEcharts"/>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div style="width: 25%;height: 100%;box-sizing: border-box;">
          <div style="height: calc(33.333% - 10px);margin-left: 10px;position: relative;padding: 15px;box-sizing: border-box;width: calc(100% - 20px);border-radius: 5px;border: 1px solid #69E5B7">
            <div style="height: 16px;justify-content: space-between;font-size: 12px;width: 100%;display: flex;align-items: center;">
              <div style="display: flex;align-items: center;">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                新增违章情况
              </div>
              <select-date style="width: calc(100% - 100px)"
                           :right="10"
                           :left="0"
                           @date-change="$nextTick(initViolationOfRegulationsEcharts)"
                           :date-type.sync="listBreakRulesParam.type"
                           :date.sync="listBreakRulesParam.date" />
            </div>
            <div style="height: 40px;margin-top: 10px;width: 100%;display: flex;align-items: center;justify-content: space-around">
              <div style="display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="font-weight: bolder;color: #F3A539;font-size: 22px;">{{ monthBreakRulesData.num }}</div>
                <div style="color: white;font-size: 8px;">当前违章车辆</div>
              </div>
              <div style="display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="font-weight: bolder;color: #F3A539;font-size: 22px;">{{ monthBreakRulesData.noHandleNum }}</div>
                <div style="color: white;font-size: 8px;">当前违章未处理车辆数</div>
              </div>
            </div>
            <div style="width: 100%;height: calc(100% - 66px);overflow-y: hidden;">
              <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;" ref="violationOfRegulationsEcharts"/>
            </div>
          </div>
          <div style="height: calc(33.333% - 10px);margin-top: 10px;position: relative;padding: 15px;box-sizing: border-box;width: calc(100% - 20px);margin-left: 10px;border-radius: 5px;border: 1px solid #69E5B7">
            <div style="height: 16px;justify-content: space-between;font-size: 12px;width: 100%;display: flex;align-items: center;">
              <div style="display: flex;align-items: center;">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                故障率
              </div>
              <select-date style="width: calc(100% - 100px)"
                           :right="10"
                           :left="0"
                           @date-change="$nextTick(initFailureRateEchartsEcharts)"
                           :date-type.sync="listRepairPercentParam.type"
                           :date.sync="listRepairPercentParam.date" />
            </div>
            <div style="height: 40px;margin-top: 10px;width: 100%;display: flex;align-items: center;justify-content: space-around">
              <div style="display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="font-weight: bolder;color: #F3A539;font-size: 22px;">{{ todayRepairData.todayNum }}</div>
                <div style="color: white;font-size: 8px;">今日故障次数</div>
              </div>
              <div style="display: flex;flex-flow: column nowrap;align-items: center;justify-content: center">
                <div style="font-weight: bolder;color: #F3A539;font-size: 22px;">{{ todayRepairData.todayVehicleNum }}</div>
                <div style="color: white;font-size: 8px;">今日故障车辆</div>
              </div>
            </div>
            <div style="width: 100%;height: calc(100% - 66px);">
              <div style="width: 100%;height: calc(100% - 10px);margin-top: 10px;" ref="failureRateEcharts"/>
            </div>
          </div>
          <div style="height: calc(33.333% - 10px);overflow-y: hidden;margin-top: 10px;padding: 15px;box-sizing: border-box;width: calc(100% - 20px);margin-left: 10px;border-radius: 5px;border: 1px solid #69E5B7">
            <div style="width: 100%;height: 50%;display: flex;align-items: center;">
              <div style="width: 100px;height: 16px;overflow-y: hidden;font-size: 12px;display: flex;align-items: center;margin-bottom: 10px;">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                故障部件分布图
              </div>
              <div style="width: calc(100% - 100px);height: 100%;" ref="faultyComponentEcharts"/>
            </div>
            <div style="width: 100%;height: 50%;display: flex;align-items: center;">
              <div style="width: 100px;height: 16px;overflow-y: hidden;font-size: 12px;display: flex;align-items: center;margin-bottom: 10px;">
                <div style="width: 3px;height: 12px;background-color: #5ECF90;margin-right: 4px;"/>
                故障处理分布图
              </div>
              <div style="width: calc(100% - 100px);height: 100%" ref="faultDistributionEcharts"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import dayjs from 'dayjs'
import { getBatchInfo } from '@/api/common'
import {
  getOperatorData, listDriverMileage,
  listAreaEarly, listAnnualReviewEarly,
  listInsuranceEarly, listHydrogenSysEarly,
  listTargetMileagePercent, listHydrogenData,
  listTrajectory, listAvgHydrogen,
  listMileageLoss, listBreakRules,
  getMonthBreakRules, listRepairPercent,
  getTodayRepair, listRepairPart,
  listRepairHandle
} from '@/api/operator'
import isBlank from "@/utils/isBlank";

const endTime = dayjs(new Date()).format('YYYY')
const startTime = dayjs(new Date()).subtract(5, 'year').format('YYYY')

function getFormatTypeDate(type, val) {
  switch (type) {
    case '3': // day
      return {
        startTime: isBlank(val) ? null : dayjs(val).format('YYYYMMDD'),
        endTime: null
      }
    case '2': // moon
      return {
        startTime: dayjs(val[0]).format('YYYYMM'),
        endTime: dayjs(val[1]).format('YYYYMM'),
      }
    case '1': // year
      return {
        startTime: dayjs(val[0]).format('YYYY'),
        endTime: dayjs(val[1]).format('YYYY'),
      }
  }
}

let intervalRes = null
let intervalDate = null
export default {
  name: "demonstration",
  data() {
    return {
      show: true,
      safetyInspectionRateEcharts: null,
      floorAreaRateEcharts: null,
      maintenanceRateEcharts: null,
      serviceRateEcharts: null,
      mileageDrivenEcharts: null,
      violationOfRegulationsEcharts: null,
      failureRateEcharts: null,
      faultyComponentEcharts: null,
      faultDistributionEcharts: null,
      mileageAchievementRateEcharts: null,
      trajectoryAchievementRateEcharts: null,
      mileageDataLossRateEcharts: null,
      hydrogenConsumptionSituationEcharts: null,
      averageHydrogenConsumptionEcharts: null,

      listDriverMileageParam: {
        type: '1',
        date: [startTime, endTime]
      },
      listTargetMileagePercentParam: {
        type: '1',
        date: [startTime, endTime]
      },
      listHydrogenDataParam: {
        type: '1',
        date: [startTime, endTime]
      },
      listTrajectoryParam: {
        type: '1',
        date: [startTime, endTime]
      },
      listAvgHydrogenParam: {
        type: '1',
        date: [startTime, endTime]
      },
      listMileageLossParam: {
        type: '1',
        date: [startTime, endTime]
      },
      listBreakRulesParam: {
        type: '1',
        date: [startTime, endTime]
      },
      listRepairPercentParam: {
        type: '1',
        date: [startTime, endTime]
      },
      listRepairPartParam: {
        type: '1',
        date: [startTime, endTime]
      },

      batchInfo: {
        batchNo: null
      },
      operationsDataInfo: {
        adviceNum: 0, // 技改建议条数
        appearancePercent: 0, // 车容检查达成率
        driveMileage: 0, // 今日行驶里程总数，km
        maintenancePercent: 0, // 及时保养达成率
        repairPercent: 0, // 维修达成率
        reportPercent: 0, // 分析报告出具及时达成率
        safePercent: 0, // 安全检查率
        totalCo2: 0, // 减碳
        totalMileage: 0, // 总里程
        vehicleNum: 0, // 当前批次车辆总数
      },
      listAreaEarlyData: {
        num: null,
        dataList: []
      },
      // 超期年审预警
      listAnnualReviewEarlyData: {
        num: null,
        dataList: []
      },
      listInsuranceEarlyData: {
        num: null,
        dataList: []
      },
      listHydrogenSysEarlyData: {
        num: null,
        dataList: []
      },
      monthBreakRulesData: {
        noHandleNum: 0,
        num: 0
      },
      todayRepairData: {
        todayNum: 0,
        todayVehicleNum: 0
      },
      searchVal: null,
      nowDate: dayjs().format('YYYY年MM月DD日 HH:mm:ss'),
      batchList: []
    }
  },
  beforeDestroy() {
    this.clearEcharts()
    if (intervalRes) {
      clearInterval(intervalRes)
    }
    if (intervalDate) {
      clearInterval(intervalDate)
    }
    window.removeEventListener('resize', this.reLoad)
  },
  async mounted() {
    document.title = '示范运营数据分析'
    await this.getBatchInfo()
    intervalRes = setInterval(this.init, 60_000)
    intervalDate = setInterval(() => {
      this.nowDate = dayjs().format('YYYY年MM月DD日 HH:mm:ss')
    }, 1000)
    window.addEventListener('resize', this.reLoad)
  },
  methods: {
    fullScan() {
      if (screenfull.isFullscreen) {
        screenfull.exit()
      } else {
        screenfull.request()
      }
    },
    reLoad() {
      this.clearEcharts()
      this.show = false
      this.$nextTick(() => {
        this.show = true
        setTimeout(this.init, 100)
      })
    },
    clearEcharts() {
      if (this.safetyInspectionRateEcharts) {
        this.safetyInspectionRateEcharts.dispose()
      }
      if (this.floorAreaRateEcharts) {
        this.floorAreaRateEcharts.dispose()
      }
      if (this.maintenanceRateEcharts) {
        this.maintenanceRateEcharts.dispose()
      }
      if (this.serviceRateEcharts) {
        this.serviceRateEcharts.dispose()
      }
      if (this.mileageDrivenEcharts) {
        this.mileageDrivenEcharts.dispose()
      }
      if (this.violationOfRegulationsEcharts) {
        this.violationOfRegulationsEcharts.dispose()
      }
      if (this.failureRateEcharts) {
        this.failureRateEcharts.dispose()
      }
      if (this.faultyComponentEcharts) {
        this.faultyComponentEcharts.dispose()
      }
      if (this.faultDistributionEcharts) {
        this.faultDistributionEcharts.dispose()
      }
      if (this.mileageAchievementRateEcharts) {
        this.mileageAchievementRateEcharts.dispose()
      }
      if (this.trajectoryAchievementRateEcharts) {
        this.trajectoryAchievementRateEcharts.dispose()
      }
      if (this.mileageDataLossRateEcharts) {
        this.mileageDataLossRateEcharts.dispose()
      }
      if (this.hydrogenConsumptionSituationEcharts) {
        this.hydrogenConsumptionSituationEcharts.dispose()
      }
      if (this.averageHydrogenConsumptionEcharts) {
        this.averageHydrogenConsumptionEcharts.dispose()
      }

      this.safetyInspectionRateEcharts = null
      this.floorAreaRateEcharts = null
      this.maintenanceRateEcharts = null
      this.serviceRateEcharts = null
      this.mileageDrivenEcharts = null
      this.violationOfRegulationsEcharts = null
      this.failureRateEcharts = null
      this.faultyComponentEcharts = null
      this.faultDistributionEcharts = null
      this.mileageAchievementRateEcharts = null
      this.trajectoryAchievementRateEcharts = null
      this.mileageDataLossRateEcharts = null
      this.hydrogenConsumptionSituationEcharts = null
      this.averageHydrogenConsumptionEcharts = null
    },

    /**
     * 安全检查率 图表
     */
    initSafetyInspectionRateEcharts() {
      if (!this.safetyInspectionRateEcharts) {
        this.safetyInspectionRateEcharts = this.$echarts.init(this.$refs.safetyInspectionRateEcharts);
      }
      this.safetyInspectionRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 10
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: Number(this.operationsDataInfo.safePercent),
                name: this.operationsDataInfo.safePercent + '%',
                title: {
                  offsetCenter: ['0%', '0%'],
                  color: '#1FF457',
                  fontSize: 20
                },
                detail: {
                  show: false
                },
                itemStyle: {
                  color: '#1FF457'
                }
              }
            ],
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              color: 'inherit',
              borderColor: 'inherit',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            },
            center: ['50%', '40%']
          }
        ],
        title: {
          show: false,
          text: '安全检查率',
          textStyle: {
            color: '#FFFFFF',
            fontSize: 14,
          },
          bottom: '5%',
          left: '18%'
        }
      })
    },
    /**
     * 车容检查达成率
     */
    initFloorAreaRateEcharts() {
      if (!this.floorAreaRateEcharts) {
        this.floorAreaRateEcharts = this.$echarts.init(this.$refs.floorAreaRateEcharts);
      }
      this.floorAreaRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 10
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: Number(this.operationsDataInfo.appearancePercent),
                name: this.operationsDataInfo.appearancePercent + '%',
                title: {
                  offsetCenter: ['0%', '0%'],
                  color: '#A8F30A',
                  fontSize: 20
                },
                detail: {
                  show: false
                },
                itemStyle: {
                  color: '#A8F30A'
                }
              }
            ],
            title: {
              fontSize: 14
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              color: 'inherit',
              borderColor: 'inherit',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            },
            center: ['50%', '40%']
          }
        ],
        title: {
          show: false,
          text: '车容检查达成率',
          textStyle: {
            color: '#FFFFFF',
            fontSize: 14,
          },
          bottom: '5%',
          left: '12%'
        }
      })
    },
    /**
     * 及时保养达成率
     */
    initMaintenanceRateEcharts() {
      if (!this.maintenanceRateEcharts) {
        this.maintenanceRateEcharts = this.$echarts.init(this.$refs.maintenanceRateEcharts);
      }
      this.maintenanceRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 10
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: Number(this.operationsDataInfo.maintenancePercent),
                name: this.operationsDataInfo.maintenancePercent + '%',
                title: {
                  offsetCenter: ['0%', '0%'],
                  color: '#0EF7FA',
                  fontSize: 20
                },
                detail: {
                  show: false
                },
                itemStyle: {
                  color: '#0EF7FA'
                }
              }
            ],
            title: {
              fontSize: 14
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              color: 'inherit',
              borderColor: 'inherit',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            },
            center: ['50%', '40%']
          }
        ],
        title: {
          show: false,
          text: '及时保养达成率',
          textStyle: {
            color: '#FFFFFF',
            fontSize: 14,
          },
          bottom: '5%',
          left: '10%'
        }
      })
    },
    /**
     * 维修达成率
     */
    initServiceRateEcharts() {
      if (!this.serviceRateEcharts) {
        this.serviceRateEcharts = this.$echarts.init(this.$refs.serviceRateEcharts);
      }
      this.serviceRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 10
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: Number(this.operationsDataInfo.repairPercent),
                name: this.operationsDataInfo.repairPercent + '%',
                title: {
                  offsetCenter: ['0%', '0%'],
                  color: '#0796FA',
                  fontSize: 20
                },
                detail: {
                  show: false
                },
                itemStyle: {
                  color: '#0796FA'
                }
              }
            ],
            title: {
              fontSize: 14
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              color: 'inherit',
              borderColor: 'inherit',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            },
            center: ['50%', '40%']
          }
        ],
        title: {
          show: false,
          text: '维修达成率',
          textStyle: {
            color: '#FFFFFF',
            fontSize: 14,
          },
          bottom: '5%',
          left: '10%'
        }
      })
    },

    /**
     * 行驶里程
     */
    async initMileageDrivenEcharts() {
      if (!this.mileageDrivenEcharts) {
        this.mileageDrivenEcharts = this.$echarts.init(this.$refs.mileageDrivenEcharts);
      }
      const res = await this.$request(listDriverMileage(Object.assign({}, this.listDriverMileageParam, {
        ...getFormatTypeDate(this.listDriverMileageParam.type, this.listDriverMileageParam.date),
        batchId: this.batchInfo.id
      })))
      this.mileageDrivenEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '3%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'line',
            smooth: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#019CF5'
                },
                {
                  offset: 1,
                  color: 'transparent'
                }
              ])
            },
          }
        ]
      })
    },
    /**
     * 新增违章情况
     */
    async initViolationOfRegulationsEcharts() {
      if (!this.violationOfRegulationsEcharts) {
        this.violationOfRegulationsEcharts = this.$echarts.init(this.$refs.violationOfRegulationsEcharts);
      }
      const res = await this.$request(listBreakRules(Object.assign({}, this.listBreakRulesParam, {
        ...getFormatTypeDate(this.listBreakRulesParam.type, this.listBreakRulesParam.date),
        batchId: this.batchInfo.id
      })))
      this.violationOfRegulationsEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }

        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }

        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '0%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#F99E00'
            },
            lineStyle: {
              color: '#F99E00'
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#D28B08'
                },
                {
                  offset: 1,
                  color: 'transparent'
                }
              ])
            },
          }
        ]
      })

      const moonRes = await this.$request(getMonthBreakRules({
        batchId: this.batchInfo.id,
        month: dayjs().format('YYYYMM')
      }))
      Object.assign(this.monthBreakRulesData, moonRes)
    },
    /**
     * 故障率
     */
    async initFailureRateEchartsEcharts() {
      if (!this.failureRateEcharts) {
        this.failureRateEcharts = this.$echarts.init(this.$refs.failureRateEcharts);
      }

      const res = await this.$request(listRepairPercent(Object.assign({}, this.listRepairPercentParam, {
        ...getFormatTypeDate(this.listRepairPercentParam.type, this.listRepairPercentParam.date),
        batchId: this.batchInfo.id
      })))
      this.failureRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} %'
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '0%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#F3A539'
            },
            areaStyle: {
              color: '#F3A539'
            }
          }
        ]
      })

      const todayRes = await this.$request(getTodayRepair({
        batchId: this.batchInfo.id
      }))
      Object.assign(this.todayRepairData, todayRes)
    },
    /**
     * 故障部件分布图
     */
    async initFaultyComponentEcharts() {
      if (!this.faultyComponentEcharts) {
        this.faultyComponentEcharts = this.$echarts.init(this.$refs.faultyComponentEcharts);
      }
      const res = await this.$request(listRepairPart({
        batchId: this.batchInfo.id
      }))
      this.faultyComponentEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        title: {
          show: true,
          textVerticalAlign: 'center',
          textAlign: 'center',
          text: '故障次数\n' + res.reduce((total, row) => total + Number(row.num), 0),
          left: '48%',
          textStyle: {
            color: '#fff',
            fontSize: 8,
          },
          top: '48%',
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['30%', '50%'],
            data: res.length === 0 ? [{name: '无数据', value: 0}] : res.map(row => {
              return {
                value: row.num,
                name: row.name
              }
            }),
            label: {
              show: true,
              position: 'outside',
              formatter: '{b} {d}%',
              fontSize: 8
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            boundaryGap: ['0%', '0%'],
          }
        ]
      })
    },
    /**
     * 故障处理分布图
     */
    async initFaultDistributionEchartsEcharts() {
      if (!this.faultDistributionEcharts) {
        this.faultDistributionEcharts = this.$echarts.init(this.$refs.faultDistributionEcharts);
      }

      const res = await this.$request(listRepairHandle({
        batchId: this.batchInfo.id
      }))
      this.faultDistributionEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: res.length === 0 ? [{name: '无数据', value: 0}] : res.map(row => {
              return {
                value: row.num,
                name: row.name
              }
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              position: 'outside',
              formatter: '{b} {d}%',
              fontSize: 8
            },
            boundaryGap: ['0%', '0%'],
          }
        ]
      })
    },
    /**
     * 里程达成率
     */
    async initMileageAchievementRateEcharts() {
      if (!this.mileageAchievementRateEcharts) {
        this.mileageAchievementRateEcharts = this.$echarts.init(this.$refs.mileageAchievementRateEcharts);
      }
      const res = await this.$request(listTargetMileagePercent(Object.assign({}, this.listTargetMileagePercentParam, {
        batchId: this.batchInfo.id,
        ...getFormatTypeDate(this.listTargetMileagePercentParam.type, this.listTargetMileagePercentParam.date),
      })))
      this.mileageAchievementRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          boundaryGap: ['0%', '0%'],
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }

        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '0%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} %'
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }

        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'line',
            smooth: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#019CF5'
                },
                {
                  offset: 1,
                  color: 'transparent'
                }
              ])
            },
          }
        ]
      })
    },
    /**
     * 轨迹达成率
     */
    async initTrajectoryAchievementRateEcharts() {
      if (!this.trajectoryAchievementRateEcharts) {
        this.trajectoryAchievementRateEcharts = this.$echarts.init(this.$refs.trajectoryAchievementRateEcharts);
      }
      const res = await this.$request(listTrajectory(Object.assign({}, this.listTrajectoryParam, {
        batchId: this.batchInfo.id,
        ...getFormatTypeDate(this.listTrajectoryParam.type, this.listTrajectoryParam.date),
      })))
      this.trajectoryAchievementRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} %'
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }

        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '0%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#38E7E9'
            },
            lineStyle: {
              color: '#38E7E9'
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#36EDED'
                },
                {
                  offset: 1,
                  color: 'transparent'
                }
              ])
            },
          }
        ]
      })
    },
    /**
     * 里程数据丢失率
     */
    async initMileageDataLossRateEchartsEcharts() {
      if (!this.mileageDataLossRateEcharts) {
        this.mileageDataLossRateEcharts = this.$echarts.init(this.$refs.mileageDataLossRateEcharts);
      }
      const res = await this.$request(listMileageLoss(Object.assign({}, this.listMileageLossParam, {
        batchId: this.batchInfo.id,
        ...getFormatTypeDate(this.listMileageLossParam.type, this.listMileageLossParam.date),
      })))
      this.mileageDataLossRateEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }

        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} %'
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }

        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '0%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#ED2C2B'
            },
            lineStyle: {
              color: '#ED2C2B'
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#D82A2B'
                },
                {
                  offset: 1,
                  color: 'transparent'
                }
              ])
            },
          }
        ]
      })
    },
    /**
     * 氢耗情况
     */
    async initHydrogenConsumptionSituationEchartsEcharts() {
      if (!this.hydrogenConsumptionSituationEcharts) {
        this.hydrogenConsumptionSituationEcharts = this.$echarts.init(this.$refs.hydrogenConsumptionSituationEcharts);
      }
      const res = await this.$request(listHydrogenData(Object.assign({}, this.listHydrogenDataParam, {
        batchId: this.batchInfo.id,
        ...getFormatTypeDate(this.listHydrogenDataParam.type, this.listHydrogenDataParam.date),
      })))
      this.hydrogenConsumptionSituationEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.map(row => row.name),
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }

        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              // 网格线的颜色
              color: '#425052',
              // 网格线的类型
              type: 'solid',
              cup: 'round',
              join: 'miter',
              miterLimit: 3,
              shadowBlur: 10,
              shadowColor: '#425052',
              opacity: 1
            }
          }

        },
        grid: {
          left: '0%', //默认10%
          right: '10%', //默认10%
          bottom: '0%', //默认60
          top: '4%', //默认60
          containLabel: true,
          backgroundColor: '#16272A',
          borderColor: 'transparent',
          show: true,       //是否显示坐标系网格
        },
        series: [
          {
            data: res.map(row => row.num),
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#5BC78C'
            },
            lineStyle: {
              color: '#5BC78C'
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#499A71'
                },
                {
                  offset: 1,
                  color: 'transparent'
                }
              ])
            },
          }
        ]
      })
    },
    /**
     * 平均氢耗占比情况
     */
    async initAverageHydrogenConsumptionEchartsEcharts() {
      if (!this.averageHydrogenConsumptionEcharts) {
        this.averageHydrogenConsumptionEcharts = this.$echarts.init(this.$refs.averageHydrogenConsumptionEcharts);
      }
      const res = await this.$request(listAvgHydrogen(Object.assign({}, this.listAvgHydrogenParam, {
        batchId: this.batchInfo.id,
        ...getFormatTypeDate(this.listAvgHydrogenParam.type, this.listAvgHydrogenParam.date),
      })))
      this.averageHydrogenConsumptionEcharts.setOption({
        darkMode: true,
        tooltip: {
          show: true,
          trigger: 'item'
        },
        title: {
          show: false
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: res.length === 0 ? [{name: '无数据', value: 0}] : res.map(row => {
              return {
                value: row.num,
                name: row.name
              }
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              position: 'outside',
              formatter: '{b} {d}%',
              fontSize: 10
            },
            boundaryGap: ['0%', '0%'],
          }
        ]
      })
    },
    /**
     * 获取运营批次信息
     * @return {Promise<void>}
     */
    async getBatchInfo(keyword) {
      const res = await this.$request(getBatchInfo({
        batchNo: keyword,
        limit: 50
      }))
      this.batchList = res.map(row => {
        return Object.assign({}, row, {
          label: row.batchNo,
          value: row.batchNo
        })
      })
      if (res.length !== 0) {
        Object.assign(this.batchInfo, res[0])
      } else {
        this.$message.warning('未查询到数据')
        return
      }
      this.searchVal = null
      this.init()
    },
    /**
     * 运营数据
     */
    async operationsData() {
      const res = await this.$request(getOperatorData({
        batchId: this.batchInfo.id
      }))
      Object.assign(this.operationsDataInfo, res)
    },
    /**
     * 车辆跨区域预警
     * @return {Promise<void>}
     */
    async listAreaEarly() {
      const res = await this.$request(listAreaEarly({
        batchId: this.batchInfo.id,
        limit: 2
      }))
      Object.assign(this.listAreaEarlyData, res)
    },
    /**
     * 超期年审预警
     * @return {Promise<void>}
     */
    async listAnnualReviewEarly() {
      const res = await this.$request(listAnnualReviewEarly({
        batchId: this.batchInfo.id,
        limit: 2
      }))
      Object.assign(this.listAnnualReviewEarlyData, res)
    },
    /**
     * 超期保险预警
     * @return {Promise<void>}
     */
    async listInsuranceEarly() {
      const res = await this.$request(listInsuranceEarly({
        batchId: this.batchInfo.id,
        limit: 2
      }))
      Object.assign(this.listInsuranceEarlyData, res)
    },
    /**
     * 供氢系统泄漏预警
     * @return {Promise<void>}
     */
    async listHydrogenSysEarly() {
      const res = await this.$request(listHydrogenSysEarly({
        batchId: this.batchInfo.id,
        limit: 2
      }))
      Object.assign(this.listHydrogenSysEarlyData, res)
    },
    async init() {
      await this.operationsData()

      this.listAreaEarly()
      this.listAnnualReviewEarly()
      this.listInsuranceEarly()
      this.listHydrogenSysEarly()

      this.initServiceRateEcharts()
      this.initSafetyInspectionRateEcharts()
      this.initFloorAreaRateEcharts()
      this.initMaintenanceRateEcharts()

      this.initMileageDrivenEcharts()
      this.initViolationOfRegulationsEcharts()
      this.initFailureRateEchartsEcharts()
      this.initFaultyComponentEcharts()
      this.initFaultDistributionEchartsEcharts()
      this.initMileageAchievementRateEcharts()
      this.initTrajectoryAchievementRateEcharts()
      this.initMileageDataLossRateEchartsEcharts()
      this.initHydrogenConsumptionSituationEchartsEcharts()
      this.initAverageHydrogenConsumptionEchartsEcharts()
    }
  }
}
</script>

<style lang="stylus" scoped>
.padding_box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: white;
}
#dv-full-screen-container {
  height: 100vh !important;
}
.content_box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: calc(100% - 100px);
}
.input_search {
  height: 100%;width: 100%;border: none;background-color: transparent;
  color: white;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 40px;
}
.input_search:focus {
  border: none !important;
  outline: none;
}
</style>
